import React from 'react';
import { AUTH_ADMIN } from 'api';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styles from './styles.module.scss';
import Button from '../Button';

class AdminLogin extends React.Component {
  form = { ...this.initialForm };

  constructor(props) {
      super(props);
      this.store = props.store;
      this.state = {
          adminLogin: '',
          adminPass: '',
          buttonLoading: '',
          buttonDisabled: '',
      };

      this.handleSubmitAdmin = this.handleSubmitAdmin.bind(this);
      this.handleChangeLogin = this.handleChangeLogin.bind(this);
      this.handleChangePassword = this.handleChangePassword.bind(this);
  }


  handleChangeLogin(e) {
      this.setState({
          adminLogin: e.target.value,
      });
  }

  handleChangePassword(e) {
      this.setState({
          adminPass: e.target.value,
      });
  }

  handleSubmitAdmin() {
      this.setState({
          buttonLoading: true,
          buttonDisabled: true,
      });

      return new Promise((res) => {
          AUTH_ADMIN(this.state.adminLogin, this.state.adminPass)
              .then((data) => {
                  res(data);
                  this.setState({
                      buttonLoading: false,
                      buttonDisabled: false,
                  });
                  this.store.login.setIsAdmin(true);
                  this.props.history.push('/admin/users');
              }).catch((error) => {
                  const { message } = error.data;
                  document.getElementById('error').innerHTML = message || 'Ошибка авторизации';
                  console.log(message);
                  this.setState({
                      buttonLoading: false,
                      buttonDisabled: false,
                  });
              });
      });
  }

  componentDidMount() {
      document.addEventListener('keydown', (event) => {
          if (event.code === 'Enter') {
              this.handleSubmitAdmin();
          }
      });
  }

  componentWillUnmount() {
      document.removeEventListener('keydown', (event) => {
          if (event.code === 'Enter') {
              this.handleSubmitAdmin();
          }
      });
  }

  render() {
      return (
          <form className="form" onSubmit={(e) => { e.preventDefault(); this.handleSubmitAdmin(); }}>
              <label className={styles.InputFieldLabelWrapper}>Логин</label>
              <input id="formLogin" type="text" name="formLogin" className={styles.InputType} onChange={this.handleChangeLogin} required /><br/>
              <label className={cn(styles.InputFieldLabelWrapper, styles.InputFieldLabelWrapperPassword)}>Пароль</label>
              <input id="formPass" type="password" name="formPass" className={styles.InputType} onChange={this.handleChangePassword} required /><br/>
              <div id="error" className={styles.Error}></div>
              <Button
                  style={{ width: '100%', marginTop: 30 }}
                  variant="primary"
                  id="btn-submit"
                  size="lg"
                  type="submit"
                  className={styles.button}
                  disabled={this.state.buttonDisabled}
                  loading={this.state.buttonLoading}>{ 'Войти' }</Button>
          </form>
      );
  }
}

export default withRouter(inject('store')(observer(AdminLogin)));
