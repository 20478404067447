import React from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import styles from './styles.module.scss';

const TheIsDisplayContactsPanel = ({
    companyCode,
    onAccept,
    onCancel,
}) => {
    const iconLikeCompanyCode = () => {
        if (companyCode === 'Life') {
            // return styles.TheIsDisplayContactsPanelTitleSK;
            return styles.TheIsDisplayContactsPanelTitleLife;
        }

        return styles.TheIsDisplayContactsPanelTitleLife;
    };

    return (
        <div className={styles.TheIsDisplayContactsPanel}>
            <div className={cn(styles.TheIsDisplayContactsPanelTitle, iconLikeCompanyCode())}>
                { companyCode === 'Life'
                    ? 'Отображать полисы ООО «Инлайф страхование»?'
                    : 'Отображать полисы АО «Инлайф страхование жизни»?'
                }
            </div>
            <div className={styles.TheIsDisplayContactsPanelButtons}>
                <Button
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={onAccept}>{ 'Да' }</Button>

                <Button
                    className={styles.TheIsDisplayContactsPanelButtonCancel}
                    buttonType="text"
                    variant="white"
                    size="sm"
                    type="button"
                    onClick={onCancel}>{ 'Нет' }</Button>
            </div>
        </div>
    );
};

export default TheIsDisplayContactsPanel;
