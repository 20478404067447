import React from 'react';

class GetDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: true,
            response: false,
        };
        this.simulateClick = this.simulateClick.bind(this);
    }

    simulateClick(e) {
        e.click();
    }

    render() {
        return <a ref={this.simulateClick} href={`/api?action=getFileByLink&method=Additional&guid=${this.props.match.params.guid}`}> </a>;
    }
}

export default GetDocuments;
