import { observable, action, decorate } from 'mobx';
import moment from 'moment';
import {
    AUTH_REGISTRATION_FULL,
    AUTH_REGISTRATION_NOT_POLICY_HOLDER,
    AUTH_REGISTRATION_CLIENT_NOT_UNIQUE,
    SUPPORT_REGISTER_USER,
} from 'api';

class RegisterModule {
    constructor(props, appStore, feedbackStore, profileStore) {
        this.appStore = appStore;
        this.feedbackStore = feedbackStore;
        this.profileStore = profileStore;
    }

    isShowSuccessModal = false;

    isShowFailModal = false;

    isSuccessRegisterNotPolicyHolder = false;

    isFailRegisterNotPolicyHolder = false;

    failRegisterMessage = '';

    form = {
        fields: {
            phone: {
                value: '',
            },
            surname: {
                value: '',
            },
            name: {
                value: '',
            },
            patronymic: {
                value: '',
            },
            birthDate: {
                value: '',
            },
            documentNumber: {
                value: '',
            },
            documentSeries: {
                value: '',
            },
            email: {
                value: '',
            },
            policyNumber: {
                value: '',
            },
        },
        meta: {
            loading: false,
            fullSubmitDisabled: () => {
                const isDisabled = (
                    !this.form.fields.surname.value
                    || !this.form.fields.name.value
                    || !this.form.fields.birthDate.value
                    || (this.form.fields.documentSeries.value.length !== 0 && this.form.fields.documentSeries.value.length !== 4)
                    || (this.form.fields.documentNumber.value.length !== 0 && this.form.fields.documentNumber.value.length !== 6)
                    || (this.form.fields.documentSeries.value.length === 4 && this.form.fields.documentNumber.value.length !== 6)
                    || (this.form.fields.documentSeries.value.length !== 4 && this.form.fields.documentNumber.value.length === 6)
                    || !this.form.fields.email.value
                    || !this.form.fields.policyNumber.value
                ) || this.form.meta.loading;

                return isDisabled;
            },
            notUniqueSubmitDisabled: () => (!this.form.fields.surname.value || !this.form.fields.name.value || !this.form.fields.birthDate.value) || this.form.meta.loading,
        },
    }

    onFieldChange = (field, value) => {
        this.form.fields[field].value = value;

        if (this.form.fields[field].error) {
            this.form.fields[field].error = null;
        }
    }

    setIsShowFailModal(value) {
        this.isShowFailModal = value;
    }

    setIsFailRegisterNotPolicyHolder(value) {
        this.isFailRegisterNotPolicyHolder = value;
    }

    registrationFull() {
        const birthDate = moment(this.form.fields.birthDate.value).format('YYYY-MM-DD');

        this.form.meta.loading = true;

        AUTH_REGISTRATION_FULL(this.form.fields.surname.value, this.form.fields.name.value, this.form.fields.patronymic.value, birthDate, this.form.fields.email.value, this.form.fields.documentNumber.value, this.form.fields.documentSeries.value, this.form.fields.policyNumber.value)
            .then(async () => {
                await this.profileStore.getClientInfo();

                this.form.meta.loading = false;
                this.isShowSuccessModal = true;
            }).catch(() => {
                this.form.meta.loading = false;
                this.isShowFailModal = true;
                this.feedbackStore.setRequestTypeId(1);
            });
    }

    registrationNotPolicyHolder() {
        const birthDate = moment(this.form.fields.birthDate.value).format('YYYY-MM-DD');

        this.form.meta.loading = true;

        AUTH_REGISTRATION_NOT_POLICY_HOLDER(this.form.fields.surname.value, this.form.fields.name.value, this.form.fields.patronymic.value, birthDate, this.form.fields.email.value, this.form.fields.documentNumber.value, this.form.fields.documentSeries.value, this.form.fields.policyNumber.value)
            .then(async () => {
                await this.profileStore.getClientInfo();
                this.form.meta.loading = false;

                this.isShowFailModal = false;
                this.isSuccessRegisterNotPolicyHolder = true;
            }).catch((error) => {
                this.form.meta.loading = false;

                const { message } = error.data;
                this.failRegisterMessage = message;
                this.isShowFailModal = false;
                this.isFailRegisterNotPolicyHolder = true;
            });
    }

    supportRegisterUser() {
        const birthDate = moment(this.form.fields.birthDate.value).format('YYYY-MM-DD');

        this.form.meta.loading = true;

        SUPPORT_REGISTER_USER(
            this.form.fields.phone.value,
            this.form.fields.surname.value,
            this.form.fields.name.value,
            this.form.fields.patronymic.value,
            birthDate,
            this.form.fields.email.value,
            this.form.fields.documentNumber.value,
            this.form.fields.documentSeries.value,
            this.form.fields.policyNumber.value,
        )
            .then(async () => {
                await this.profileStore.getClientInfo();
                this.form.meta.loading = false;
                this.isShowSuccessModal = true;
            }).catch((error) => {
                this.form.meta.loading = false;
                const { message } = error.data;
                this.failRegisterMessage = message;
                this.isShowFailModal = true;
                this.profileStore.getClientInfo();
            });
    }

    registrationClientNotUnique() {
        const birthDate = moment(this.form.fields.birthDate.value).format('YYYY-MM-DD');

        this.loading = true;

        AUTH_REGISTRATION_CLIENT_NOT_UNIQUE(this.form.fields.surname.value, this.form.fields.name.value, this.form.fields.patronymic.value, birthDate)
            .then(async () => {
                await this.profileStore.getClientInfo();

                this.form.meta.loading = false;
                this.isShowSuccessModal = true;
            }).catch(() => {
                this.form.meta.loading = false;
                this.isShowFailModal = true;
                this.feedbackStore.setRequestTypeId(1);
            });
    }
}

decorate(RegisterModule, {
    isShowSuccessModal: observable,
    isShowFailModal: observable,
    isSuccessRegisterNotPolicyHolder: observable,
    isFailRegisterNotPolicyHolder: observable,
    form: observable,
    onFieldChange: action.bound,
    setIsShowSuccessModal: action.bound,
    setIsShowFailModal: action.bound,
    setIsFailRegisterNotPolicyHolder: action.bound,
    registrationFull: action.bound,
    registrationNotPolicyHolder: action.bound,
    registrationClientNotUnique: action.bound,
});

export default RegisterModule;
