import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'components/Container';
import styles from './styles.module.scss';

const HeaderFirstNavBar = ({
    companyCode,
}) => {
    const phoneByCompanyCode = () => {
        if (companyCode === 'Life') {
            return '8 800 234-92-02';
        }
        return '8 800 234-77-55';
    };

    const sosLinkByCompanyCode = () => <Link
        to="/claim"
        className={styles.HeaderFirstNavBarSos}
    >{'SOS'}</Link>;

    const feedbackLinkByCompanyCode = () => {
        if (companyCode === 'Life') {
            return <a
                href='https://pyrus.sovcombank.ru/form/387411'
                target='_blank'
                rel="noopener noreferrer"
                className={styles.HeaderFirstNavBarItem}>
                Связаться с нами
            </a>;
        }
        return <Link
            to='/feedback'
            className={styles.HeaderFirstNavBarItem}>
            {'Обратная связь'}
        </Link>;
    };

    return (
        <Container fluid>
            <div className={styles.HeaderFirstNavBar}>
                {/* <div className={styles.HeaderFirstNavBarMenu}></div> */}
                {/* <div className={styles.HeaderFirstNavBarMenuToggler}>{ 'Меню сайта' }</div> */}
                <div className={styles.HeaderFirstNavBarItems}>
                    {feedbackLinkByCompanyCode()}
                </div>

                <a
                    href={`tel:${phoneByCompanyCode()}`}
                    className={styles.HeaderFirstNavBarPhone}>
                    {phoneByCompanyCode()}
                </a>
                {sosLinkByCompanyCode()}
            </div>
        </Container>
    );
};

export default HeaderFirstNavBar;
