import React from 'react';
import styles from './styles.module.scss';

const Switch = ({
    offText,
    onText,
    onTextAlter,
    offTextWidth,
    checked,
    ...rest
}) => (
    <div className={styles.Switch}>
        {offText && (
            <div
                className={styles.SwitchText}
                style={{ width: `${offTextWidth + 10}px` }}
                data-text={offText}
                data-text-role="off"
                data-active={!checked}
            />
        )}

        <label className={styles.SwitchWrapper}>
            <input
                type="checkbox"
                className={styles.SwitchInput}
                checked={checked}
                {...rest}
            />

            <div className={styles.SwitchToggle} />
        </label>

        {(onText || onTextAlter) && (
            <div
                className={styles.SwitchText}
                data-text={onText}
                data-text-alter={onTextAlter}
                data-text-role="on"
                data-active={checked}
            />
        )}
    </div>
);

export default Switch;
