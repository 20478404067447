import React from 'react';
import styles from './styles.module.scss';

const SafetyStatusShield = ({
    percentage,
}) => (
    <div className={styles.SafetyStatusShield}>
        <svg width="34px" height="42px" viewBox="0 0 34 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <symbol id="shield">
                    <path d="M14.6037406,5.54277245 C12.0084101,7.22585254 8.83095762,8.22396513 5.1,8.22396513 L3.4,8.22396513 C3.20201819,8.22396513 3,8.43207236 3,8.72644464 L3,13.9801639 C3,14.9325338 3.00838494,16.5067485 3.01856932,17.4739061 C3.01912916,17.5338846 3.03081975,17.7848872 3.05728598,18.1194553 C3.10310313,18.6986445 3.17921829,19.3581632 3.2924519,20.082012 C3.61586898,22.149464 4.16910849,24.3013548 5.00158956,26.4154324 C7.26702281,32.1684781 11.1187134,36.5499153 17,38.9014796 C22.8812866,36.5499153 26.7329772,32.1684781 28.9984104,26.4154324 C29.8308915,24.3013548 30.384131,22.149464 30.7075481,20.082012 C30.8207817,19.3581632 30.8968969,18.6986445 30.942714,18.1194553 C30.9691803,17.7848872 30.9808708,17.5338846 30.9814664,17.4703115 C30.9916151,16.5067485 31,14.9325338 31,13.9801639 L31,8.72644464 C31,8.43207236 30.7979818,8.22396513 30.6,8.22396513 L28.9,8.22396513 C25.1696611,8.22396513 21.9925988,7.22622643 19.3976719,5.54423164 C18.4682162,4.94177169 17.6706337,4.28657338 16.9995935,3.61515406 C16.3286118,4.2863231 15.5318161,4.94091251 14.6037406,5.54277245 Z M30.6,5.22396513 C32.4751,5.22396513 34,6.79482719 34,8.72644464 L34,13.9801639 C34,14.9485995 33.9915,16.5334715 33.9813,17.5019071 C33.9796,17.684036 33.6056,35.8899245 17.5814,41.8931744 C17.5695,41.8984281 17.5576,41.8966769 17.5457,41.9001793 C17.3706,41.9614727 17.1887,42 17,42 C16.8113,42 16.6294,41.9614727 16.4543,41.9001793 C16.4424,41.8966769 16.4305,41.8984281 16.4186,41.8931744 C0.3944,35.8899245 0.0204,17.684036 0.0187,17.5019071 C0.0085,16.5334715 0,14.9485995 0,13.9801639 L0,8.72644464 C0,6.79482719 1.5249,5.22396513 3.4,5.22396513 L5.1,5.22396513 C12.24,5.22396513 15.4547,0.873885577 15.5873,0.688254163 C15.9086,0.243439265 16.3965,0.0262855355 16.898,0.0070218982 C17.4726,-0.0437640547 18.0574,0.180394634 18.4195,0.697010362 C18.5453,0.873885577 21.76,5.22396513 28.9,5.22396513 L30.6,5.22396513 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                </symbol>

                <symbol id="filler">
                    <path d="M14.6037406,5.54277245 C12.0084101,7.22585254 8.83095762,8.22396513 5.1,8.22396513 L3.4,8.22396513 C3.20201819,8.22396513 3,8.43207236 3,8.72644464 L3,13.9801639 C3,14.9325338 3.00838494,16.5067485 3.01856932,17.4739061 C3.01912916,17.5338846 3.03081975,17.7848872 3.05728598,18.1194553 C3.10310313,18.6986445 3.17921829,19.3581632 3.2924519,20.082012 C3.61586898,22.149464 4.16910849,24.3013548 5.00158956,26.4154324 C7.26702281,32.1684781 11.1187134,36.5499153 17,38.9014796 C22.8812866,36.5499153 26.7329772,32.1684781 28.9984104,26.4154324 C29.8308915,24.3013548 30.384131,22.149464 30.7075481,20.082012 C30.8207817,19.3581632 30.8968969,18.6986445 30.942714,18.1194553 C30.9691803,17.7848872 30.9808708,17.5338846 30.9814664,17.4703115 C30.9916151,16.5067485 31,14.9325338 31,13.9801639 L31,8.72644464 C31,8.43207236 30.7979818,8.22396513 30.6,8.22396513 L28.9,8.22396513 C25.1696611,8.22396513 21.9925988,7.22622643 19.3976719,5.54423164 C18.4682162,4.94177169 17.6706337,4.28657338 16.9995935,3.61515406 C16.3286118,4.2863231 15.5318161,4.94091251 14.6037406,5.54277245 Z" id="Combined-Shape"></path>
                </symbol>

                <linearGradient id="color" x1="0" x2="0" y1="0" y2="1">
                    <stop stopColor="#95C11F" offset={1 - (percentage / 100)} stopOpacity="0" />
                    <stop stopColor="#95C11F" offset={1 - (percentage / 100)} />
                </linearGradient>
            </defs>

            <use xlinkHref="#shield" />
            <use xlinkHref="#filler" fill="url(#color)" />
        </svg>
    </div>
);

export default SafetyStatusShield;
