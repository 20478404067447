import React from 'react';
import styles from './styles.module.scss';

const Label = ({
    required = false,
    children,
}) => (
    <label
        className={styles.Label}
        data-required={required}
    >{ children }</label>
);

export default Label;
