import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import FadeTransition from 'components/FadeTransition';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import SelectField from 'components/SelectField';
import MaskedInputField from 'components/MaskedInputField';
import DateInputField from 'components/DateInputField';
import InputField from 'components/InputField';
import TextareaField from 'components/TextareaField';
import FileDropzone from 'components/FileDropzone';
import Button from 'components/Button';
import SuccessFeedbackModal from 'components/SuccessFeedbackModal';
import { INSURANCE_GET_REQUEST_TYPES, FEEDBACK_REGISTRATION_FEEDBACK_FORM } from 'api';
import styles from './styles.module.scss';

class FeedbackPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            pageLoading: true,
            title: 'Форма обратной связи',
            requestTypeList: [],

            surname: this.store.register.form.fields.surname.value || '',
            name: this.store.register.form.fields.name.value || '',
            patronymic: this.store.register.form.fields.patronymic.value || '',
            documentNumber: this.store.register.form.fields.documentNumber.value || '',
            documentSeries: this.store.register.form.fields.documentSeries.value || '',
            email: this.store.register.form.fields.email.value || '',
            birthDate: this.store.register.form.fields.birthDate.value || '',
            policyNumber: this.store.register.form.fields.policyNumber.value || '',
            description: '',
            policyFile: '',

            loading: false,
            submitDisabled: () => {
                const isDisabled = (
                    !this.state.surname
                    || !this.state.name
                    || (!this.state.documentNumber || this.state.documentNumber.length < 6)
                    || (!this.state.documentSeries || this.state.documentSeries.length < 4)
                    || !this.state.email
                    || !this.state.policyNumber
                    || !this.state.birthDate
                    || !this.state.policyFile
                )
                || this.state.loading
                || parseInt(this.store.feedback.requestTypeId, 10) !== 1;

                return isDisabled;
            },
            isShowSuccessModal: false,
        };

        this.onChangeRequestTypeId = this.onChangeRequestTypeId.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.handleCloseSuccessModal = this.handleCloseSuccessModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCloseSuccessModal() {
        this.setState({
            ...this.state,
            surname: '',
            name: '',
            patronymic: '',
            documentNumber: '',
            documentSeries: '',
            email: '',
            birthDate: '',
            policyNumber: '',
            description: '',
            policyFile: '',
            isShowSuccessModal: false,
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            loading: true,
        });

        FEEDBACK_REGISTRATION_FEEDBACK_FORM(this.store.feedback.requestTypeId, this.state.surname, this.state.name, this.state.patronymic, this.state.email, this.state.description, this.state.birthDate, this.state.documentNumber, this.state.documentSeries, this.state.policyNumber, this.store.app.companyCode, this.state.policyFile)
            .then(() => {
                this.setState({
                    ...this.state,
                    isShowSuccessModal: true,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });

                const { error: errorMessage } = error.data;
                alert(errorMessage);
            });
    }

    onChangeRequestTypeId(e) {
        const { value: requestTypeId } = e.target;

        this.store.feedback.setRequestTypeId(requestTypeId);

        if (parseInt(requestTypeId, 10) !== 1) {
            this.props.history.push('/feedback');
        }
    }

    onChangeFile(file) {
        const fileError = [];
        const maxFileSize = 20;
        const allowedExtensions = /(\.pdf|\.jpeg|\.jpg|\.bmp|\.gif|\.png|\.tif|\.tiff|\.sig)$/i;
        const { size: fileSize, name: fileName } = file;
        const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);

        const checkAllowedExtensions = allowedExtensions.exec(fileName);

        if (!checkAllowedExtensions) {
            fileError.push(`Недопустимое расширение у файла ${fileName}`);
        } else if (fileSizeMB > maxFileSize) {
            fileError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
        } else {
            this.setState({ ...this.state, policyFile: file });
        }

        if (fileError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: fileError.join('\n'),
            });
        }
    }

    componentDidMount() {
        document.title = this.state.title;

        this.store.feedback.setRequestTypeId(1);

        INSURANCE_GET_REQUEST_TYPES()
            .then((data) => {
                const { results } = data;
                const items = results.map((item) => ({ id: item.typeId, name: item.typeName }));

                this.setState({
                    ...this.state,
                    requestTypeList: items,
                    pageLoading: false,
                });
            });
    }

    render() {
        const { user } = this.store.profile;

        const getPhoneValue = () => {
            if (this.store.login.form.fields.phone.value) {
                return this.store.login.form.fields.phone.value;
            }

            if (user.phone) {
                return user.phone;
            }

            return '';
        };

        return (
            <FadeTransition loading={this.state.pageLoading}>
                <SuccessFeedbackModal
                    isShow={this.state.isShowSuccessModal}
                    handleClose={this.handleCloseSuccessModal} />

                <AppLayout
                    userName={this.store.profile.userNameNavbar}
                    companyCode={this.store.app.companyCode}
                    hasBackground={false}
                >
                    <div className={styles.FeedbackRegisterPageWrapper}>
                        <Wrapper>
                            <TopWrapper>
                                <Container fluid>
                                    <Heading description={ 'Чтобы связаться со службой поддержки, заполните форму ниже и приложите необходимые сопроводительные документы.' }>{ 'Форма обратной связи' }</Heading>

                                    <form
                                        className={styles.FeedbackRegisterPageForm}
                                        onSubmit={this.handleSubmit}>
                                        <SelectField
                                            label="Тип обращения"
                                            size="lg"
                                            options={this.state.requestTypeList}
                                            value={this.store.feedback.requestTypeId}
                                            onChange={this.onChangeRequestTypeId} />

                                        <MaskedInputField
                                            mask={['+', /[7-7]/, ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                            label={'Телефон'}
                                            size="lg"
                                            type="tel"
                                            placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                            name="phone"
                                            value={getPhoneValue()}
                                            disabled>
                                            <Button
                                                to="/login"
                                                buttonType="text"
                                                variant="primary"
                                                size="sm">{ 'Ввести другой номер телефона' }</Button>
                                        </MaskedInputField>

                                        <InputField
                                            label="Фамилия"
                                            size="lg"
                                            type="text"
                                            placeholder="Фамилия"
                                            name="surname"
                                            value={this.state.surname}
                                            onChange={(e) => this.setState({ ...this.state, surname: e.target.value })} />

                                        <InputField
                                            label="Имя"
                                            size="lg"
                                            type="text"
                                            placeholder="Имя"
                                            name="name"
                                            value={this.state.name}
                                            onChange={(e) => this.setState({ ...this.state, name: e.target.value })} />

                                        <InputField
                                            label="Отчество"
                                            size="lg"
                                            type="text"
                                            placeholder="Отчество"
                                            name="patronymic"
                                            value={this.state.patronymic}
                                            onChange={(e) => this.setState({ ...this.state, patronymic: e.target.value })} />

                                        <DateInputField
                                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                            label="Дата рождения"
                                            size="lg"
                                            icon="calendar"
                                            value={this.state.birthDate}
                                            format="dd.MM.yyyy"
                                            placeholder="дд.мм.гггг"
                                            onChange={(birthDate) => this.setState({ ...this.state, birthDate })} />

                                        <MaskedInputField
                                            mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                                            label={'Паспорт'}
                                            size="lg"
                                            type="text"
                                            placeholder="Серия"
                                            name="documentSeries"
                                            value={this.state.documentSeries}
                                            onChange={(e) => this.setState({ ...this.state, documentSeries: e.target.value })} />

                                        <MaskedInputField
                                            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                            size="lg"
                                            type="text"
                                            placeholder="Номер"
                                            name="documentNumber"
                                            value={this.state.documentNumber}
                                            onChange={(e) => this.setState({ ...this.state, documentNumber: e.target.value })} />

                                        <InputField
                                            label="E-mail"
                                            size="lg"
                                            type="text"
                                            placeholder="E-mail"
                                            name="email"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ ...this.state, email: e.target.value })} />

                                        <InputField
                                            label="Серия и номер полиса (как в договоре)"
                                            size="lg"
                                            type="text"
                                            placeholder="Серия и номер полиса (как в договоре)"
                                            name="policyNumber"
                                            value={this.state.policyNumber}
                                            onChange={(e) => this.setState({ ...this.state, policyNumber: e.target.value })} />

                                        <TextareaField
                                            label="Описание"
                                            placeholder="Описание"
                                            rows="5"
                                            value={this.state.description}
                                            onChange={(e) => this.setState({ ...this.state, description: e.target.value })} />

                                        <FileDropzone
                                            label="Прикрепить скан полиса"
                                            title={this.state.policyFile ? this.state.policyFile.name : 'Добавить'}
                                            onChange={(e) => this.onChangeFile(e.target.files[0])}
                                            /* onChange={(e) => this.setState({ ...this.state, policyFile: e.target.files[0] })} */
                                        />

                                        <div>
                                            <Button
                                                variant="primary"
                                                size="lg"
                                                type="submit"
                                                disabled={this.state.submitDisabled()}
                                                loading={this.state.loading}>{ 'Отправить' }</Button>
                                        </div>
                                    </form>
                                </Container>
                            </TopWrapper>
                        </Wrapper>
                    </div>
                </AppLayout>
            </FadeTransition>
        );
    }
}

export default inject('store')(observer(FeedbackPage));
