import React from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';

const SuccessFeedbackModal = ({
    isShow,
    handleClose,
    body,
    description,
}) => (
    isShow && <Modal
        title={ 'Спасибо за обращение!' }
        body={ (body === '' || body === undefined) ? 'В ближайшее время с вами свяжется наш специалист.' : body}
        description = {description}
        footer={
            <Button
                style={{ width: '100%' }}
                size="lg"
                variant="primary"
                onClick={handleClose}>{ 'Закрыть' }</Button>
        }
        handleClose={handleClose} />
);

export default SuccessFeedbackModal;
