import React from 'react';
import styles from './styles.module.scss';

const Modal = ({
    title,
    subTitle,
    body,
    footer,
    description,
    handleClose,
    footerGridStyle,
}) => (
    <div className={styles.ModalContainer}>
        <div
            className={styles.ModalOverlay}
            onClick={handleClose}></div>
        <div className={styles.Modal}>
            {handleClose !== undefined && (
                <div
                    className={styles.ModalClose}
                    onClick={handleClose}
                />
            )}

            <div className={styles.ModalHead}>
                <div className={styles.ModalTitle}>
                    { title }

                    { subTitle && <div className={styles.ModalSubTitle}>{ subTitle }</div> }
                </div>
            </div>

            <div className={styles.ModalBody}>
                { body }
            </div>

            {description
                && <div className={styles.ModalBody}>
                    { description }
                </div>
            }

            <div className={footerGridStyle ? styles.ModalFoot_GridStyle : styles.ModalFoot_FlexStyle}>
                { footer }
            </div>
        </div>
    </div>
);

export default Modal;
