import React from 'react';
import styles from './styles.module.scss';

const ContentWrapper = ({
    color,
    style,
    children,
}) => (
    <div
        className={styles.ContentWrapper}
        style={{ ...style, background: color }}>{ children }</div>
);

export default ContentWrapper;
