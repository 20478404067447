import React from 'react';
import { inject, observer } from 'mobx-react';
import cookies from 'js-cookie';
import MainHeader from 'components/MainHeader';
import Footer from 'components/Footer';
import LoadingBar from 'components/LoadingBar';
import Modal from 'components/Modal';
import VerifyEmailModal from 'components/VerifyEmailModal';
import {
    AUTH_SEND_EMAIL,
    AUTH_VALIDATE_EMAIL,
} from 'api';
import styles from './styles.module.scss';

class AppLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            loadingVerifyEmailModalButton: false,
            email: '',
            emailError: '',
            code: '',
            codeError: '',
            codeSended: false,
        };

        this.resetVerifyEmailModal = this.resetVerifyEmailModal.bind(this);
        this.closeVerifyEmailModal = this.closeVerifyEmailModal.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.sendCode = this.sendCode.bind(this);
        this.confirmCode = this.confirmCode.bind(this);

        if (!props.noVerifyEmailModal) {
            this.store.app.setIsShowVerifyEmailModal((
                JSON.parse(cookies.get('isShowVerifyEmailModal')
                    || (
                        !this.store.profile.user.hasVerifiedEmail
                        && JSON.parse(cookies.get('showVerifyEmailModalCount') || 0) < 2
                    ))
            ));
        }
    }

    resetVerifyEmailModal() {
        this.store.app.setIsShowVerifyEmailModal(false);

        this.setState({
            ...this.state,
            loadingVerifyEmailModalButton: false,
            email: '',
            emailError: '',
            code: '',
            codeError: '',
            codeSended: false,
        });
    }

    closeVerifyEmailModal() {
        this.resetVerifyEmailModal();

        cookies.set('isShowVerifyEmailModal', false);
        cookies.set('showVerifyEmailModalCount', JSON.parse(cookies.get('showVerifyEmailModalCount') || 0) + 1, {
            expires: 7,
        });
    }

    onChangeEmail(e) {
        const { value } = e.target;

        this.setState({
            ...this.state,
            email: value,
            emailError: '',
        });
    }

    onChangeCode(e) {
        const { value } = e.target;

        this.setState({
            ...this.state,
            code: value,
            codeError: '',
        });
    }

    sendCode(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            loadingVerifyEmailModalButton: true,
        });

        AUTH_SEND_EMAIL(this.state.email)
            .then(() => {
                this.setState({
                    ...this.state,
                    codeSended: true,
                    loadingVerifyEmailModalButton: false,
                });
            }).catch((err) => {
                const { data: { message } } = err;

                this.setState({
                    ...this.state,
                    emailError: message,
                    loadingVerifyEmailModalButton: false,
                });
            });
    }

    confirmCode(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            loadingVerifyEmailModalButton: true,
        });

        AUTH_VALIDATE_EMAIL(this.state.code)
            .then(() => {
                this.closeVerifyEmailModal();
            }).catch((err) => {
                const { data: { message } } = err;

                this.setState({
                    ...this.state,
                    codeError: message,
                    loadingVerifyEmailModalButton: false,
                });
            });
    }

    render() {
        const {
            userName,
            companyCode,
            hasBackground = true,
            children,
        } = this.props;

        return (
            <div className={styles.AppLayout}>
                <LoadingBar
                    started={!!(this.store.loading.startsCount)}
                    value={this.store.loading.value}
                />

                <MainHeader
                    hasBackground={hasBackground}
                    userName={userName}
                    companyCode={companyCode}
                    claimsNotifCount={this.store.profile.user.unreadMessagesByClientCount}
                />

                {this.store.app.modalData && (
                    <Modal
                        title={this.store.app.modalData.title}
                        body={<p>{ this.store.app.modalData.body }</p>}
                        footer={this.store.app.modalData.footer}
                        handleClose={() => this.store.app.setModalData(null)}
                    />
                )}

                <VerifyEmailModal
                    isShow={this.store.app.isShowVerifyEmailModal}
                    email={this.state.email}
                    emailError={this.state.emailError}
                    code={this.state.code}
                    codeError={this.state.codeError}
                    codeSended={this.state.codeSended}
                    loadingButton={this.state.loadingVerifyEmailModalButton}
                    onChangeEmail={this.onChangeEmail}
                    onChangeCode={this.onChangeCode}
                    handleSendCode={this.sendCode}
                    handleConfirmCode={this.confirmCode}
                    handleClose={this.closeVerifyEmailModal}
                />

                { children }

                <Footer companyCode={companyCode} />
            </div>
        );
    }
}

export default inject('store')(observer(AppLayout));
