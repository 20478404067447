import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import AdminUsers from 'components/AdminUsers';
import styles from './styles.module.scss';

class AdminUsersPage extends React.Component {
    render() {
        return (
            <div className={styles.InsuranceClaimsPageWrapper}>
                <Wrapper>
                    <div className={styles.AdminUsersContainer}>
                        <TopWrapper>
                            <AdminUsers/>
                        </TopWrapper>
                    </div>
                </Wrapper>
            </div>
        );
    }
}

export default inject('store')(observer(withRouter(AdminUsersPage)));
