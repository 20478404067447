import React from 'react';
import SafetyStatusShield from './SafetyStatusShield';
import styles from './styles.module.scss';

const SafetyStatus = ({
    percentage,
}) => (
    <div className={styles.SafetyStatus}>
        <SafetyStatusShield percentage={percentage} />

        <div className={styles.SafetyStatusContent}>
            <div className={styles.SafetyStatusText}>{ 'Ваша защита' }</div>
            <div className={styles.SafetyStatusPercentage}>{ `${percentage}%` }</div>
        </div>
    </div>
);

export default SafetyStatus;
