import React, { forwardRef } from 'react';
import styles from './styles.module.scss';

const FileDropzone = forwardRef(({
    label,
    title,
    ...rest
}, ref) => (
    <div className={styles.FileDropzone}>
        <div className={styles.FileDropzonedLabel}>{ label }</div>
        <div className={styles.FileDropzonedWrapper}>
            <div className={styles.FileDropzoneTitle}>{ title }</div>
            <input
                ref={ref}
                type="file"
                {...rest} />
        </div>
    </div>
));

export default FileDropzone;
