import React from 'react';
import styles from './styles.module.scss';

const CheckboxToggle = ({
    checked,
    ...rest
}) => (
    <div className={styles.CheckboxToggle}>
        <label className={styles.CheckboxToggleWrapper}>
            <input
                type="checkbox"
                className={styles.CheckboxToggleInput}
                checked={checked}
                {...rest} />
            <div className={styles.CheckboxToggleToggle} />
        </label>
    </div>
);

export default CheckboxToggle;
