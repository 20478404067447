import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';
import Modal from 'components/Modal';

const SuccessReportModal = ({
    isShow,
    handleClose,
    history,
}) => {
    const close = () => {
        handleClose();
        history.push('/claims');
    };

    return (
        isShow && <Modal
            title={ 'Спасибо!' }
            body={
                <p>Заявление сформировано и отправлено.<br /><br />За статусом рассмотрения вы можете наблюдать в личном кабинете в разделе <Button
                    buttonType="text"
                    variant="primary"
                    onClick={close}
                >
                        «Мои страховые случаи»
                </Button><br /><br />
                    По запросу от сотрудника страховой компании прикрепите необходимые документы, мы их проверим и ответим в чате.
                    Сроки урегулирования – 15 рабочих дней с даты получения последнего документа.
                    Но мы постараемся связаться с Вами быстрее.
                <br />
                    Номер заявления будет присвоен в течение 1 минуты.</p>
            }
            footer={
                <Button
                    style={{ width: '100%' }}
                    size="lg"
                    variant="primary"
                    onClick={close}>{ 'Завершить' }</Button>
            }
            handleClose={close} />
    );
};

export default withRouter(SuccessReportModal);
