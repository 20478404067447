import { observable, action, decorate } from 'mobx';

class FeedbackModule {
    requestTypeId = 0;

    constructor(props, appStore) {
        this.appStore = appStore;
    }

    setRequestTypeId(value) {
        this.requestTypeId = value;
    }
}

decorate(FeedbackModule, {
    requestTypeId: observable,
    setRequestTypeId: action.bound,
});

export default FeedbackModule;
