import React from 'react';
import styles from './styles.module.scss';

const StageLevelItem = ({
    number,
    title,
    isActive,
}) => (
    <div
        className={styles.StageLevelItem}
        data-active={isActive}
    >
        <div className={styles.StageLevelItemNumber}>{ number }</div>
        <div className={styles.StageLevelItemTitle}>{ title }</div>
    </div>
);

const StageLevel = ({
    items,
    active,
}) => (
    <div className={styles.StageLevel}>
        {items.map((item, itemIndex) => (
            <StageLevelItem
                key={itemIndex}
                number={itemIndex + 1}
                title={item.title}
                isActive={active === itemIndex}
            />
        ))}
    </div>
);

export default StageLevel;
