import React from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import InputField from 'components/InputField';
import MaskedInputField from 'components/MaskedInputField';

const VerifyEmailModal = ({
    isShow,
    email,
    emailError,
    code,
    codeError,
    codeSended,
    loadingButton,
    onChangeEmail,
    onChangeCode,
    handleSendCode,
    handleConfirmCode,
    handleClose,
}) => {
    const close = () => {
        handleClose();
    };

    return (
        isShow && (
            !codeSended
                ? (
                    <form onSubmit={handleSendCode}>
                        <Modal
                            title={ 'Укажите, пожалуйста, E-mail' }
                            subTitle={ 'И мы расскажем о работе вашего полиса и оперативно поделимся другой полезной информацией.' }
                            body={
                                <InputField
                                    size="lg"
                                    label="E-mail"
                                    error={emailError}
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={onChangeEmail}
                                />
                            }
                            footer={
                                <Button
                                    type="submit"
                                    style={{ width: '100%' }}
                                    size="lg"
                                    variant="primary"
                                    loading={loadingButton}
                                    disabled={loadingButton}
                                >{ 'Продолжить' }</Button>
                            }
                            handleClose={close} />
                    </form>
                )
                : (
                    <form onSubmit={handleConfirmCode}>
                        <Modal
                            title={
                                <span>
                                    Спасибо! Осталось подтвердить <br /> E-mail
                                </span>
                            }
                            body={
                                <React.Fragment>
                                    <p>Для подтверждения E-mail введите код из письма, направленного на адрес <b>{email}</b>. Пожалуйста, проверьте почту.</p>

                                    <MaskedInputField
                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                        label="Код"
                                        error={codeError}
                                        size="lg"
                                        type="text"
                                        placeholder="00000"
                                        name="code"
                                        value={code}
                                        onChange={onChangeCode} />
                                </React.Fragment>
                            }
                            footer={
                                <Button
                                    type="submit"
                                    style={{ width: '100%' }}
                                    size="lg"
                                    variant="primary"
                                    loading={loadingButton}
                                    disabled={loadingButton || !code}
                                >{ 'Продолжить' }</Button>
                            }
                            handleClose={close} />
                    </form>
                )
        )
    );
};

export default VerifyEmailModal;
