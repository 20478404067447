import React from 'react';
import { inject, observer } from 'mobx-react';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';

class BaseLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};

        this.store = props.store;
    }

    render() {
        const { children } = this.props;
        return (
            <React.Fragment>
                <BaseHeader companyCode={this.store.app.companyCode} />
                { children }
                <Footer companyCode={this.store.app.companyCode} />
            </React.Fragment>
        );
    }
}


export default inject('store')(observer(BaseLayout));
