import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';
import Modal from 'components/Modal';

const FailRegisterNotPolicyHolderModal = ({
    isShow,
    message,
    handleClose,
}) => (
    isShow && <Modal
        title={ 'Ошибка регистрации профиля!' }
        body={message}
        footer={
            <Button
                onClick={handleClose}
                style={{ width: '100%' }}
                size="lg"
                variant="primary">{ 'Продолжить' }</Button>
        }
        handleClose={handleClose} />
);

export default withRouter(FailRegisterNotPolicyHolderModal);
