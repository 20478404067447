import React from 'react';
import { inject, observer } from 'mobx-react';
import cookies from 'js-cookie';
import _ from 'lodash';
import { conformToMask } from 'react-text-mask';
import moment from 'moment';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import AutocompleteInputField from 'components/AutocompleteInputField';
import SelectField from 'components/SelectField';
import InputField from 'components/InputField';
import TextareaField from 'components/TextareaField';
import DateInputField from 'components/DateInputField';
import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';
import MaskedInputField from 'components/MaskedInputField';
import Button from 'components/Button';
import ModalOverlay from 'components/ModalOverlay';
import CheckSmsCodeCard from 'components/CheckSmsCodeCard';
import SuccessReportModal from 'components/SuccessReportModal';
import FileLoader from 'components/FileLoader';
import File from 'components/File';
import Panel from 'components/Panel';

import StageLevel from 'components/StageLevel';

import {
    STATEMENT_GET_RISK_SELECTOR_LIST,
    STATEMENT_GET_CLAIMS_LIST,
    STATEMENT_GET_CLAIM_TABS,
    STATEMENT_GET_CLAIM_TABS_FOR_EDIT,
    STATEMENT_EDIT_STATEMENT,
    STATEMENT_DELETE_DRAFT,
    STATEMENT_SEND_SMS,
    STATEMENT_VALIDATE_SMS,
    STATEMENT_ADD_STATEMENT_FILE,
    STATEMENT_DELETE_STATEMENT_FILE,
    SUGGESTIONS_BANK,
    SUGGESTIONS_ADDRESS,
    STATEMENT_GET_SELECTIONS_NOT_POLICY_HOLDER,
} from 'api';

import ReportDraftCard from 'components/ReportDraftCard';
import Label from 'components/Label';
import AddPolicyModal from 'components/AddPolicyModal';
import Modal from '../../components/Modal';
import styles from './styles.module.scss';

const prepareMask = (item) => {
    let localMask = item.mask || '';
    const variableMask = localMask.includes('{0,1}');

    localMask = localMask.replace(/'/g, '');
    localMask = localMask.replaceAll('{0,1}', '');
    localMask = localMask.split(', ');
    // eslint-disable-next-line no-param-reassign
    if (variableMask && item.value.length <= localMask.length) { item.error = false; }

    localMask = localMask.map((maskItem) => {
        switch (maskItem) {
        case '-': case '.':
            return maskItem;
        default:
            return RegExp(maskItem);
        }
    });

    return localMask;
};

const defaultTabs = [
    {
        title: 'Полис',
        nameForStepList: 'Полис',
        items: [],
    },
];

class ReportPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            title: 'Заявление о страховом случае',

            activeTab: 0,

            claimId: null,
            formType: null,
            policyId: -1,
            otherInsuredSurname: '',
            otherInsuredName: '',
            otherInsuredPatronymic: '',
            otherInsuredBirthday: null,
            selections: [],
            files: [],
            partner: null,
            onlineDoctorRedirectUrl: null,
            policyList: [],
            riskSelectionsSelects: [],
            riskSelectionsList: null,
            claimsList: [],
            fileTypes: [],

            tabs: [...defaultTabs],

            editStatementLoading: false,
            editStatementError: false,

            checkoutStatement: {
                isShowForm: false,
                isShowSuccessModal: false,
                code: '',
                codeError: null,
                resendLoading: false,
                submitLoading: false,
                confirmCodeLoading: false,
            },

            suggestionsBankResult: [],
            suggestionsAddressResult: [],
            formAddPolicy: {
                surname: '',
                name: '',
                patronymic: '',
                birthDate: '',
                policyNumber: '',
            },
        };

        this.addPolicy = this.addPolicy.bind(this);
        this.addPolicyToList = this.addPolicyToList.bind(this);
        this.updateFormAddPolicy = this.updateFormAddPolicy.bind(this);
        this.selectDraft = this.selectDraft.bind(this);
        this.deleteDraft = this.deleteDraft.bind(this);
        this.onChangeFormField = this.onChangeFormField.bind(this);
        this.onBlurFormField = this.onBlurFormField.bind(this);
        this.onChangePolicy = this.onChangePolicy.bind(this);
        this.onChangeSelections = this.onChangeSelections.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.getProcessedTabItems = this.getProcessedTabItems.bind(this);

        this.debounceEditStatement = _.debounce(this.editStatement, 1000);
        this.debounceFindSuggestionsBank = _.debounce(this.findSuggestionsBank, 50);
        this.debounceFindSuggestionsAddress = _.debounce(this.findSuggestionsAddress, 50);

        this.titleRef = React.createRef();
    }

    setTabItem(tabIndex, before, data) {
        const { tabs } = this.state;

        tabs[tabIndex].items.splice(before, 0, data);

        this.setState({
            ...this.state,
            tabs,
        });
    }

    addPolicy(surname, name, patronymic, birthDate, policyNumber) {
        this.store.policy.setIsLoadingAddPolicy(true);
        STATEMENT_GET_SELECTIONS_NOT_POLICY_HOLDER(policyNumber.trim(), `${surname.trim()} ${name.trim()} ${patronymic.trim()}`, moment(birthDate).format('YYYY-MM-DD')).then(({ result }) => {
            this.store.policy.setIsLoadingAddPolicy(false);
            this.store.policy.setIsAddPolicyModal(false);
            const { policyList } = this.state;
            const { policyId, contractNumber, insuranceProgram } = result;
            if (!policyList.find((item) => item.id === policyId)) {
                const addPolicyElem = policyList.pop();
                policyList.push({
                    id: policyId,
                    name: `${contractNumber} — ${insuranceProgram}`,
                });
                policyList.push(addPolicyElem);

                this.setState({
                    ...this.state,
                    policyList,
                    policyId: policyId || -1,
                    tabs: [...defaultTabs],
                    selections: [],
                    riskSelectionsSelects: [],
                    partner: null,
                    onlineDoctorRedirectUrl: null,
                }, () => {
                    if (policyId) {
                        this.store.policy.getPolicy(this.state.policyId).then(() => {
                            this.redirectByPolicyType();
                            this.fillRisks();
                        });
                    }
                });
            }
            this.setState({
                ...this.state,
                formAddPolicy: {
                    surname: '',
                    name: '',
                    patronymic: '',
                    birthDate: '',
                    policyNumber: '',
                },
            });
        }).catch((error) => {
            this.store.policy.setErrorMessage(error.data.message);
            this.store.policy.setIsLoadingAddPolicy(false);
            this.store.policy.setIsAddPolicyModal(false);
            this.store.policy.setIsFailAddPolicyModal(true);
        });
    }

    updateFormAddPolicy(field, value) {
        this.setState({
            ...this.state,
            formAddPolicy: {
                ...this.state.formAddPolicy,
                [`${field}`]: value,
            },
        });
    }

    setTabItemValue(name, value) {
        const tabs = [...this.state.tabs];

        tabs.forEach((tab, tabIndex) => {
            tab.items.forEach((tabItem, tabItemIndex) => {
                if (tabItem.parameterName === name) {
                    tabs[tabIndex].items[tabItemIndex].value = value;
                }
            });
        });

        this.setState({
            ...this.state,
            tabs,
        });
    }

    setTabItemError(name) {
        const tabs = [...this.state.tabs];

        tabs.forEach((tab, tabIndex) => {
            tab.items.forEach((tabItem, tabItemIndex) => {
                if (tabItem.parameterName === name) {
                    tabs[tabIndex].items[tabItemIndex].error = true;
                }
            });
        });

        this.setState({
            ...this.state,
            tabs,
        });
    }

    removeTabItemError(name) {
        const tabs = [...this.state.tabs];

        tabs.forEach((tab, tabIndex) => {
            tab.items.forEach((tabItem, tabItemIndex) => {
                if (tabItem.parameterName === name) {
                    delete tabs[tabIndex].items[tabItemIndex].error;
                }
            });
        });

        this.setState({
            ...this.state,
            tabs,
        });
    }

    setTabItemValueByType(type, value) {
        const tabs = [...this.state.tabs];

        tabs.forEach((tab, tabIndex) => {
            tab.items.forEach((tabItem, tabItemIndex) => {
                if (tabItem.type === type) {
                    tabs[tabIndex].items[tabItemIndex].value = value;
                }
            });
        });

        this.setState({
            ...this.state,
            tabs,
        });
    }

    getTabItemValue(name) {
        const tabs = [...this.state.tabs];
        let result = '';

        tabs.forEach((tab) => {
            tab.items.forEach((tabItem) => {
                if (tabItem.parameterName === name) {
                    result = tabItem.value;
                }
            });
        });

        return result;
    }

    getItemPosition(name) {
        const tabs = [...this.state.tabs];

        let tabIndex = -1;
        let itemIndex = -1;

        tabs.forEach((tab) => {
            tab.items.forEach((tabItem) => {
                if (tabItem.parameterName === name) {
                    tabIndex = tabs.indexOf(tab);
                    itemIndex = tab.items.indexOf(tabItem);
                }
            });
        });

        return [tabIndex, itemIndex];
    }

    setDefaultTabItemData(data) {
        const tabs = [...data];

        tabs.forEach((tab, tabIndex) => {
            if (tab.items.length !== 0) {
                tab.items.forEach((item, itemIndex) => {
                    if (!item.value) {
                        switch (item.type) {
                        case 'select':
                            tabs[tabIndex].items[itemIndex].value = -1;
                            break;
                        case 'checkbox':
                            tabs[tabIndex].items[itemIndex].value = 'false';
                            break;
                        case 'files':
                            tabs[tabIndex].items[itemIndex].value = true;
                            break;
                        default:
                            tabs[tabIndex].items[itemIndex].value = '';
                            break;
                        }
                    } else {
                        switch (item.type) {
                        case 'phone':
                            tabs[tabIndex].items[itemIndex].value = conformToMask(item.value.substr(1), ['+', '7', ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]).conformedValue;
                            break;
                        case 'checkbox': case 'radio': case 'hidden':
                            tabs[tabIndex].items[itemIndex].value = typeof item.value === 'boolean' ? item.value.toString() : item.value;
                            break;
                        default:
                            break;
                        }
                    }
                });
            }
        });

        this.setState({
            ...this.state,
            tabs,
        });
    }

    checkDefaultsValues() {
        const tabs = [...this.state.tabs];

        tabs.forEach((tab, tabIndex) => {
            if (tab.items.length !== 0) {
                tab.items.forEach((item, itemIndex) => {
                    switch (item.type) {
                    case 'email':
                        if (item.value && item.actions && item.actions.regular) {
                            if (!new RegExp(item.actions.regular).test(item.value)) {
                                tabs[tabIndex].items[itemIndex].error = true;
                            } else {
                                delete tabs[tabIndex].items[itemIndex].error;
                            }
                        }
                        break;
                    case 'bank-rs':
                        this.checkBankRSVerification();
                        break;
                    default:
                        if (item.mask) {
                            if (item.value && item.value.length < item.mask.split(',').length) {
                                tabs[tabIndex].items[itemIndex].error = true;
                            } else {
                                delete tabs[tabIndex].items[itemIndex].error;
                            }
                        }
                        break;
                    }
                });
            }
        });

        this.setState({
            ...this.state,
            tabs,
        });
    }

    redirectByPolicyType(claimId) {
        let search;
        if (claimId) {
            search = `/claim?claimId=${claimId}`;
        } else {
            search = `/claim?policyId=${this.state.policyId}`;
        }
        this.store.app.redirectCompany(this.store.policy.policy.companyCode, search);
    }

    fillPolicies() {
        this.store.loading.start();

        this.store.policy.getPolicies(JSON.parse(cookies.get('fetchPoliciesLikeCompany') || true))
            .then(() => {
                let search = this.props.location.search.substring(1);
                if (search) search = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);

                const policyList = [];
                const { policyId } = search || {};

                this.store.policy.policies.forEach((policy) => {
                    policyList.push({
                        id: policy.policyId,
                        name: `${policy.contractNumber} — ${policy.insuranceProgram}`,
                    });
                });

                if (policyId && !policyList.some((policy) => policy.id === policyId)) {
                    this.store.policy.getPolicy(policyId).then(() => {
                        policyList.push(this.addPolicyToList(policyId));
                        policyList.push({
                            id: 'Добавить полис',
                            name: 'Добавить полис',
                        });
                        this.store.loading.complete();

                        this.setState({
                            ...this.state,
                            policyList,
                            policyId,
                        }, () => {
                            this.redirectByPolicyType();
                            this.fillRisks();
                        });
                    }).catch(() => {
                        policyList.push({
                            id: 'Добавить полис',
                            name: 'Добавить полис',
                        });
                        this.store.loading.complete();

                        this.setState({
                            ...this.state,
                            policyList,
                            policyId: -1,
                        });
                    });
                } else {
                    policyList.push({
                        id: 'Добавить полис',
                        name: 'Добавить полис',
                    });
                    this.store.loading.complete();

                    this.setState({
                        ...this.state,
                        policyList,
                        policyId: policyId || -1,
                    }, () => {
                        if (policyId) {
                            this.store.policy.getPolicy(this.state.policyId).then(() => {
                                this.redirectByPolicyType();
                                this.fillRisks();
                            });
                        }
                    });
                }
            }).catch(() => {
                this.store.loading.complete();
            });
    }

    fillRisks(savedOtherInsured) {
        this.store.loading.start();

        return STATEMENT_GET_RISK_SELECTOR_LIST(this.state.policyId)
            .then((data) => {
                this.store.loading.complete();

                const { result: { risks } } = data;

                const riskSelectionsSelects = [...this.state.riskSelectionsSelects];
                // если имеется Черновик с сохранённым "Другой застрахованный"
                if (savedOtherInsured) {
                    const indexAddOtherInsured = risks[0].selections.findIndex((item) => item.code === 'OtherInsured');
                    // чтобы "Другой застрахованный" показывался последним в select
                    if (indexAddOtherInsured) {
                        const itemAddOtherInsured = risks[0].selections[indexAddOtherInsured];
                        risks[0].selections[indexAddOtherInsured] = savedOtherInsured;
                        risks[0].selections.push(itemAddOtherInsured);
                    } else {
                        risks[0].selections.push(savedOtherInsured);
                    }
                    // eslint-disable-next-line prefer-destructuring
                    riskSelectionsSelects[0] = risks[0];
                }

                this.setState({
                    ...this.state,
                    riskSelectionsList: risks[0],
                    riskSelectionsSelects,
                }, () => {
                    this.prepareSelects(this.state.riskSelectionsList);
                });
            }).catch(() => {
                this.store.loading.complete();
            });
    }

    fillClaims() {
        this.store.loading.start();

        STATEMENT_GET_CLAIMS_LIST(true, false).then((data) => {
            this.store.loading.complete();

            let search = this.props.location.search.substring(1);
            if (search) search = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);

            const { result: { claims: claimsList = [] } } = data;
            const { claimId } = search || {};

            this.setState({
                ...this.state,
                claimsList,
            }, () => {
                if (claimId) {
                    this.selectDraft(claimId);
                }
            });
        }).catch(() => {
            this.store.loading.complete();
        });
    }

    fillTabs() {
        this.store.loading.start();

        STATEMENT_GET_CLAIM_TABS(
            this.state.policyId,
            this.store.profile.user.clientId,
            this.state.otherInsuredSurname,
            this.state.otherInsuredName,
            this.state.otherInsuredPatronymic,
            this.state.otherInsuredBirthday && moment(this.state.otherInsuredBirthday).format('YYYY-MM-DD'),
            this.state.selections,
        ).then((data) => {
            this.store.loading.complete();

            const { result: { FormType: formType, fileTypes, claimId, partner = 'uralsibins', url } } = data;
            const { result: { tabs = [] } } = data;

            this.setDefaultTabItemData(tabs.length === 0 ? defaultTabs : tabs);
            this.checkDefaultsValues();

            this.setState({
                ...this.state,
                claimId,
                formType,
                fileTypes,
                partner,
                ...(partner === 'onlinedoctor' && ({
                    onlineDoctorRedirectUrl: url,
                })),
            });
        }).catch((error) => {
            const { data: { message } } = error;

            this.store.loading.complete();

            this.store.app.setModalData({
                title: 'Ошибка',
                body: message,
            });
        });
    }

    editStatement() {
        STATEMENT_EDIT_STATEMENT(this.state.claimId, this.state.formType, this.getFormFieldsObject())
            .then((data) => {
                const { error } = data;

                this.setState({
                    ...this.state,
                    editStatementError: !!error,
                    editStatementLoading: false,
                });

                if (error) throw error;
            })
            .catch((error) => {
                const { message } = error;

                this.setState({
                    ...this.state,
                    editStatementError: true,
                    editStatementLoading: false,
                }, () => {
                    this.store.app.setModalData({
                        title: 'Ошибка',
                        body: message,
                    });
                });
            });
    }

    clearSuggestionsBank() {
        this.setTabItemValueByType('bank-name', '');
        this.setTabItemValueByType('bank-schet', '');
        this.setTabItemValueByType('bank-inn', '');
    }

    setSuggestionsBank(index) {
        const finded = this.state.suggestionsBankResult[index] || {};

        this.setTabItemValueByType('bank-name', finded.name);
        this.setTabItemValueByType('bank-schet', finded.correspondent);
        this.setTabItemValueByType('bank-bik', finded.bik);
        this.setTabItemValueByType('bank-inn', finded.inn);
    }

    findSuggestionsBank(value) {
        SUGGESTIONS_BANK(value).then((data) => {
            let { suggestions = [] } = data;

            suggestions = suggestions.map((item) => ({
                name: item.value,
                bik: item.data.bic,
                inn: item.data.inn,
                correspondent: item.data.correspondent_account,
                address: (item.data.address || {}).value,
            }));

            this.setState({
                ...this.state,
                suggestionsBankResult: suggestions,
            });
        });
    }

    findSuggestionsAddress(name, value) {
        SUGGESTIONS_ADDRESS(value).then((data) => {
            let { suggestions = [] } = data;

            suggestions = suggestions.map((item) => item.unrestricted_value);

            this.setState({
                ...this.state,
                suggestionsAddressResult: {
                    [name]: suggestions,
                },
            });
        });
    }

    checkBankRSVerification() {
        const weightingFactors = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

        const bikValue = this.getTabItemValue('PaymentRecipientBankBik');
        const rsValue = this.getTabItemValue('PaymentRecipientPaymentAccount');

        const bikLastDigitsValueArray = Array.from(bikValue.substring(bikValue.length - 3), Number);
        const rsValueArray = Array.from(rsValue, Number);

        let sum = null;

        bikLastDigitsValueArray.forEach((bikLastDigitsValueArrayItem, i) => {
            sum += bikLastDigitsValueArrayItem * weightingFactors[i];
        });

        rsValueArray.forEach((rsValueArrayItem, i) => {
            sum += rsValueArrayItem * weightingFactors[i];
        });

        if (rsValue.length !== 0) {
            const lastDigitOfSum = sum === null ? 1 : sum % 10;

            if (lastDigitOfSum !== 0 || rsValue.length < 20) {
                this.setTabItemError('PaymentRecipientPaymentAccount');
            } else {
                this.removeTabItemError('PaymentRecipientPaymentAccount');
            }
        }
    }

    onChangeFormField(value, tabIndex, itemIndex) {
        const tabs = [...this.state.tabs];
        const item = tabs[tabIndex].items[itemIndex];

        switch (tabs[tabIndex].items[itemIndex].type) {
        case 'datetime': case 'date':
            tabs[tabIndex].items[itemIndex].value = moment(value).format('YYYY-MM-DDTHH:mm:ss');
            break;
        case 'checkbox':
            tabs[tabIndex].items[itemIndex].value = value.toString();
            break;
        case 'email':
            if (value && item.actions && item.actions.regular) {
                if (!new RegExp(item.actions.regular).test(value)) {
                    tabs[tabIndex].items[itemIndex].error = true;
                } else {
                    delete tabs[tabIndex].items[itemIndex].error;
                }
            }

            tabs[tabIndex].items[itemIndex].value = value.toString();
            break;
        default:
            if (value && item.actions && item.actions.regular) {
                if (!new RegExp(item.actions.regular).test(value)) {
                    return;
                }
            }

            if (value && item.actions && item.actions.maxLength && parseInt(item.actions.maxLength, 10)) {
                if (value.length > parseInt(item.actions.maxLength, 10)) {
                    return;
                }
            }

            if (item.mask) {
                if (value && value.length < item.mask.split(',').length) {
                    tabs[tabIndex].items[itemIndex].error = true;
                } else {
                    delete tabs[tabIndex].items[itemIndex].error;
                }
            }

            tabs[tabIndex].items[itemIndex].value = value ? value.toString() : '';
            break;
        }

        if (item.type === 'bank-bik') {
            this.checkBankRSVerification();
            this.clearSuggestionsBank();
            this.debounceFindSuggestionsBank(value);
        }

        if (item.type === 'bank-rs') {
            this.checkBankRSVerification();
        }

        if (item.type === 'address') {
            this.debounceFindSuggestionsAddress(item.parameterName, value);
        }

        this.setState({
            ...this.state,
            tabs,
        }, () => {
            this.setState({
                ...this.state,
                editStatementLoading: true,
            });

            this.debounceEditStatement();
        });
    }
    //

    onBlurFormField(tabIndex, itemIndex) {
        const tabs = [...this.state.tabs];
        const item = tabs[tabIndex].items[itemIndex];

        if (item.type === 'bank-bik') {
            if (item.value.length === 9 && this.state.suggestionsBankResult.length) {
                this.setSuggestionsBank(0);
            }
        }
    }

    onChangePolicy(e) {
        const { value: policyId } = e.target;

        if (policyId === 'Добавить полис') {
            this.store.policy.setIsAddPolicyModal(true);
        } else {
            this.setState({
                ...this.state,
                policyId,
                tabs: [...defaultTabs],
                selections: [],
                riskSelectionsSelects: [],
                partner: null,
                onlineDoctorRedirectUrl: null,
            }, () => {
                this.store.policy.getPolicy(this.state.policyId).then(() => {
                    this.redirectByPolicyType();
                    this.fillRisks();
                });
            });
        }
    }

    onChangeFiles(e, type, tabIndex, itemIndex) {
        const files = [...e.target.files];
        const tabs = [...this.state.tabs];
        const filesError = [];

        e.target.value = null;

        for (let i = 0; i < files.length; i += 1) {
            const maxFileLength = 40;
            const maxFileSize = 20;
            const allowedExtensions = /(\.pdf|\.jpeg|\.jpg|\.bmp|\.gif|\.png|\.tif|\.tiff|\.sig)$/i;
            const file = files[i];
            const { size: fileSize, name: fileName } = file;
            const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);

            const checkFileTypeLength = Boolean(tabs[tabIndex].items[itemIndex].value.filter((item) => item.fileType === type).length >= maxFileLength);
            const checkAllowedExtensions = allowedExtensions.exec(fileName);

            if (checkFileTypeLength) {
                filesError.push(`К загрузке допускается всего ${maxFileLength} файлов.`);

                break;
            } else if (!checkAllowedExtensions) {
                filesError.push(`Недопустимое расширение у файла ${fileName}`);
            } else if (fileSizeMB > maxFileSize) {
                filesError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
            } else {
                tabs[tabIndex].items[itemIndex].value.push({
                    name: fileName,
                    fileType: type,
                    tempIndex: i,
                });

                this.setState({
                    ...this.state,
                    tabs,
                });

                STATEMENT_ADD_STATEMENT_FILE(type, this.state.claimId, file)
                    .then((data) => {
                        const { result } = data;
                        const findFileObjectIndex = tabs[tabIndex].items[itemIndex].value.findIndex((item) => item.tempIndex === i);

                        tabs[tabIndex].items[itemIndex].value[findFileObjectIndex].id = result;
                        delete tabs[tabIndex].items[itemIndex].value[findFileObjectIndex].tempIndex;

                        this.setState({
                            ...this.state,
                            tabs,
                        });
                    });
            }
        }

        if (filesError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: filesError.join('\n'),
            });
        }
    }

    deleteFile(id, tabIndex, itemIndex) {
        const tabs = [...this.state.tabs];
        const findFileObjectIndex = tabs[tabIndex].items[itemIndex].value.findIndex((item) => item.id === id);

        tabs[tabIndex].items[itemIndex].value.splice(findFileObjectIndex, 1);

        this.setState({
            ...this.state,
            tabs,
        }, () => {
            STATEMENT_DELETE_STATEMENT_FILE(this.state.claimId, id);
        });
    }

    addPolicyToList(policyId) {
        const { policy } = this.store.policy;
        const from = policy.productName.indexOf('«') + 1;
        const to = policy.productName.indexOf('»');
        let insuranceProgram;
        if (from !== -1 && to !== -1) {
            insuranceProgram = policy.productName.substring(from, to);
        } else {
            insuranceProgram = policy.productName;
        }

        return {
            id: policyId,
            name: `${policy.policyNumber} — ${insuranceProgram}`,
        };
    }

    selectDraft(claimId) {
        this.store.loading.start();

        STATEMENT_GET_CLAIM_TABS_FOR_EDIT(claimId).then(async (data) => {
            this.store.loading.complete();

            const { result: { policyId, selections, FormType: formType, fileTypes, status } } = data;
            const { result: { tabs } } = data;

            if (!policyId) {
                throw new Error('Не удалось получить номер полиса');
            }

            await this.setState({
                ...this.state,
                policyId,
            }, async () => {
                this.store.policy.getPolicy(policyId)
                    .then(async () => {
                        this.redirectByPolicyType(claimId);
                        const { policyList } = this.state;
                        if (!policyList.some((policy) => policy.id === policyId)) {
                            const addPolicyElem = policyList.pop();
                            policyList.push(this.addPolicyToList(policyId));
                            policyList.push(addPolicyElem);
                            this.setState({
                                ...this.state,
                                policyList,
                            });
                            await this.fillRisks(selections.selections[0].code === 'OtherInsured' && selections.selections[0]);
                            this.setDefaultTabItemData(tabs);
                            this.checkDefaultsValues();

                            const selectionsResult = [];

                            const getSelectionValue = (selectionsData = selections) => {
                                selectionsResult.push({
                                    code: selectionsData.selections[0].code,
                                    name: selectionsData.selections[0].name,
                                    selectionLevelCode: selectionsData.code,
                                });

                                if (selectionsData.selections[0].nextLevel) {
                                    getSelectionValue(selectionsData.selections[0].nextLevel);
                                }
                            };

                            getSelectionValue();

                            this.setState({
                                ...this.state,
                                claimId,
                                formType,
                                fileTypes,
                                claimStatus: status.code,
                                selections: selectionsResult,
                            }, () => {
                                this.prepareSelects(this.state.riskSelectionsList);
                            });
                        } else {
                            await this.fillRisks(selections.selections[0].code === 'OtherInsured' && selections.selections[0]);

                            this.setDefaultTabItemData(tabs);
                            this.checkDefaultsValues();

                            const selectionsResult = [];

                            const getSelectionValue = (selectionsData = selections) => {
                                selectionsResult.push({
                                    code: selectionsData.selections[0].code,
                                    name: selectionsData.selections[0].name,
                                    selectionLevelCode: selectionsData.code,
                                });

                                if (selectionsData.selections[0].nextLevel) {
                                    getSelectionValue(selectionsData.selections[0].nextLevel);
                                }
                            };

                            getSelectionValue();

                            this.setState({
                                ...this.state,
                                claimId,
                                formType,
                                fileTypes,
                                claimStatus: status.code,
                                selections: selectionsResult,
                            }, () => {
                                this.prepareSelects(this.state.riskSelectionsList);
                            });
                        }
                    });
            });
        }).catch((error) => {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: error.message,
            });

            this.store.loading.complete();
        });
    }

    deleteDraft(claimId) {
        this.store.loading.start();

        STATEMENT_DELETE_DRAFT(claimId)
            .then(() => {
                this.store.loading.complete();

                let copyClaimsList = [...this.state.claimsList];

                copyClaimsList = copyClaimsList.filter((item) => item.claimId !== claimId);

                this.setState({
                    ...this.state,
                    claimsList: copyClaimsList,
                });
            }).catch(() => {
                this.store.loading.complete();
            });
    }

    isTabFilled() {
        const executeTypes = ['label'];
        let countEmptyFields = 0;

        this.getProcessedTabItems(this.state.activeTab).forEach((item) => {
            if (
                ((item.value === '' || item.value === null) || item.error)
                && (this.isFormItemRequired(item) || item.error)
                && !this.isFormItemHidden(item)
                && !executeTypes.includes(item.type)) {
                countEmptyFields += 1;
            }
        });

        return !countEmptyFields;
    }

    isActiveTabLast() {
        return this.state.activeTab === this.state.tabs.length - 1 && this.state.tabs.length !== 1;
    }

    nextTabAvailable(force = false) {
        if (force) {
            return this.state.activeTab === 0 || !this.isActiveTabLast();
        }

        return (this.state.activeTab < this.state.tabs.length - 1 && this.state.tabs.length > 1)
            && this.isTabFilled()
            && !this.state.editStatementLoading
            && !this.state.editStatementError;
    }

    prevTabAvailable() {
        return this.state.activeTab !== 0;
    }

    switchTab(index) {
        this.setState({
            ...this.state,
            activeTab: index,
        }, () => {
            this.titleRef.current.scrollIntoView();
        });
    }

    nextTab() {
        if (this.nextTabAvailable()) {
            this.switchTab(this.state.activeTab + 1);
        }
    }

    prevTab() {
        if (this.prevTabAvailable()) {
            this.switchTab(this.state.activeTab - 1);
        }
    }

    submitDisabled() {
        if (this.isActiveTabLast() && this.isTabFilled() && !this.state.editStatementLoading && !this.state.editStatementError) {
            return false;
        }

        return !this.nextTabAvailable();
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.isActiveTabLast()) {
            if (this.state.claimId) {
                this.editStatement();
                this.sendConfirmationCode();
            }
        }

        this.nextTab();
    }

    getFormFieldsObject() {
        const formFields = {};

        this.state.tabs.forEach((tab) => {
            tab.items.forEach((item) => {
                if (item.parameterName) {
                    switch (item.type) {
                    case 'phone':
                        formFields[item.parameterName] = item.value.replace(/[^0-9.]/g, '');
                        break;
                    case 'datetime': case 'date':
                        formFields[item.parameterName] = item.value ? moment(item.value).format('YYYY-MM-DDTHH:mm:ss') : null;
                        break;
                    default:
                        formFields[item.parameterName] = item.value;
                        break;
                    }
                }
            });
        });

        return formFields;
    }

    closeConfirmationForm(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            checkoutStatement: {
                ...this.state.checkoutStatement,
                isShowForm: false,
            },
        });
    }

    sendConfirmationCode() {
        this.setState({
            ...this.state,
            checkoutStatement: {
                ...this.state.checkoutStatement,
                submitLoading: true,
            },
        });

        STATEMENT_SEND_SMS(this.store.profile.user.phone)
            .then(() => {
                this.setState({
                    ...this.state,
                    checkoutStatement: {
                        ...this.state.checkoutStatement,
                        isShowForm: true,
                        codeError: null,
                        submitLoading: false,
                    },
                });
            }).catch((error) => {
                const { message } = error.data;

                this.setState({
                    ...this.state,
                    checkoutStatement: {
                        ...this.state.checkoutStatement,
                        codeError: message,
                    },
                });
            });
    }

    checkConfirmationCode(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            checkoutStatement: {
                ...this.state.checkoutStatement,
                confirmCodeLoading: true,
            },
        });

        STATEMENT_VALIDATE_SMS(this.state.claimId, this.state.checkoutStatement.code, this.store.profile.user.phone)
            .then(() => {
                this.setState({
                    ...this.state,
                    checkoutStatement: {
                        ...this.state.checkoutStatement,
                        isShowForm: false,
                        isShowSuccessModal: true,
                        codeError: null,
                        confirmCodeLoading: false,
                    },
                });
            }).catch((error) => {
                const { message } = error.data;

                this.setState({
                    ...this.state,
                    checkoutStatement: {
                        ...this.state.checkoutStatement,
                        codeError: message,
                    },
                });
            });
    }

    isFormItemHidden(item = {}) {
        const tabs = [...this.state.tabs];
        const { type, hidden = {} } = item;

        let isHidden = false;

        Object.keys(hidden).forEach((key) => {
            tabs.forEach((tab) => {
                tab.items.forEach((tabItem) => {
                    if (tabItem.parameterName === key) {
                        isHidden = tabItem.value === hidden[key];
                    }
                });
            });
        });

        switch (type) {
        case 'hidden':
            isHidden = true;
            break;
        default:
            break;
        }

        return isHidden;
    }

    isFormItemRequired(item = {}) {
        let isRequired = false;

        const tabs = [...this.state.tabs];
        const { required = {} } = item;

        Object.keys(required).forEach((key) => {
            switch (key) {
            case 'required':
                isRequired = required[key];
                break;
            default:
                tabs.forEach((tab) => {
                    tab.items.forEach((tabItem) => {
                        if (tabItem.parameterName === key) {
                            isRequired = tabItem.value === required[key];
                        }
                    });
                });
                break;
            }
        });

        return isRequired;
    }

    isFormItemDisabled(item = {}) {
        let isDisabled = false;

        const tabs = [...this.state.tabs];
        const { disabled = {} } = item;

        Object.keys(disabled).forEach((key) => {
            switch (key) {
            case 'disabled':
                isDisabled = JSON.parse(disabled[key]);
                break;
            default:
                tabs.forEach((tab) => {
                    tab.items.forEach((tabItem) => {
                        if (tabItem.parameterName === key) {
                            isDisabled = tabItem.value === disabled[key];

                            // TODO Сrutch :)
                            if (item.parameterName === 'PaymentRecipientName') {
                                const findVal = this.getTabItemValue('ApplicantName');

                                if (isDisabled) {
                                    if (item.value !== findVal) {
                                        this.setTabItemValue(item.parameterName, findVal);
                                    }
                                } else if (item.value === findVal) {
                                    this.setTabItemValue(item.parameterName, '');
                                }
                            }
                        }
                    });
                });
                break;
            }
        });

        return isDisabled;
    }

    getProcessedTabItems(index) {
        if (this.state.tabs[index]) {
            return this.state.tabs[index].items;
        }

        return [];
    }

    onChangeSelections(e, item) {
        let selections = [...this.state.selections];
        const { value: code } = e.target;
        /* Блок логики, удаляющий сохраненного из черновика "Другого застрахованного" в select в случае выбора другого застрахованного */
        const riskSelectionsSelects = [...this.state.riskSelectionsSelects];
        const riskSelections = riskSelectionsSelects[0].selections;

        const savedOtherInsured = riskSelections.find((riskItem) => riskItem.code === 'OtherInsured' && riskItem.name !== 'Другой застрахованный');
        const savedOtherInsuredIndex = riskSelections.indexOf(savedOtherInsured);

        if (savedOtherInsuredIndex !== -1) {
            riskSelections.splice(savedOtherInsuredIndex, 1);
        }
        /* */
        /* Блок, очищающий поля "другого застрахованного и табы" */
        let otherInsured = {};
        if (item.code === 'Insured') {
            otherInsured = {
                otherInsuredSurname: '',
                otherInsuredName: '',
                otherInsuredPatronymic: '',
                otherInsuredBirthday: null,
                tabs: defaultTabs,
            };
        }
        /**/
        const findItem = selections.find((elem) => elem.selectionLevelCode === item.code);
        const findIndex = selections.indexOf(findItem);

        if (findIndex !== -1) {
            selections.splice(findIndex, selections.length);
        }

        selections = [
            ...selections,
            {
                code,
                name: item.name,
                selectionLevelCode: item.code,
            },
        ];

        this.setState({
            ...this.state,
            selections,
            riskSelectionsSelects,
            ...otherInsured,
        }, () => {
            this.state.riskSelectionsSelects.forEach((riskSelectionsSelectsItem) => {
                if (riskSelectionsSelectsItem.code === item.code) {
                    item.selections.forEach((selectionsItem) => {
                        if (selectionsItem.code === code && !selectionsItem.nextLevel) {
                            this.fillTabs();
                        } else {
                            this.setState({
                                ...this.state,
                                tabs: defaultTabs,
                            });
                        }
                    });
                }
            });

            this.prepareSelects(item);
        });
    }

    prepareSelects(level) {
        const selectedCurrentLevel = this.state.selections.find((selectionsItem) => selectionsItem.selectionLevelCode === level.code);

        if (selectedCurrentLevel) {
            const selectedCurrentLevelSelections = level.selections.find((selectionsItem) => selectionsItem.code === selectedCurrentLevel.code);

            if (selectedCurrentLevelSelections && selectedCurrentLevelSelections.nextLevel) {
                const findSelect = this.state.riskSelectionsSelects.find((riskSelectionsSelectsItem) => riskSelectionsSelectsItem.code === selectedCurrentLevelSelections.nextLevel.code);

                if (!findSelect) {
                    this.setState({
                        ...this.state,
                        riskSelectionsSelects: [
                            ...this.state.riskSelectionsSelects,
                            selectedCurrentLevelSelections.nextLevel,
                        ],
                    }, () => {
                        this.prepareSelects(selectedCurrentLevelSelections.nextLevel);
                    });
                } else if (JSON.stringify(findSelect.selections) !== JSON.stringify(selectedCurrentLevelSelections.nextLevel.selections)) {
                    const riskSelectionsSelectsCopy = [...this.state.riskSelectionsSelects];
                    const findSelectIndex = riskSelectionsSelectsCopy.indexOf(findSelect);
                    riskSelectionsSelectsCopy.splice(findSelectIndex, 1, selectedCurrentLevelSelections.nextLevel);

                    this.setState({
                        ...this.state,
                        riskSelectionsSelects: riskSelectionsSelectsCopy,
                    });
                } else {
                    this.prepareSelects(selectedCurrentLevelSelections.nextLevel);
                }
            }
        } else if (this.state.riskSelectionsSelects.length === 0) {
            this.setState({
                ...this.state,
                riskSelectionsSelects: [level],
            });
        }
    }

    getSelectionValue(item) {
        const findSelection = this.state.selections.find((selection) => selection.selectionLevelCode === item.code) || {};

        return findSelection.code || -1;
    }

    getPolicyData() {
        return this.store.policy.policies.find((policy) => policy.policyId === this.state.policyId) || {};
    }

    itemStyles(item) {
        const display = this.isFormItemHidden(item) && 'none';
        const width = '100%';

        let marginTop = 30;
        let marginBottom = 0;

        switch (item.type) {
        case 'radio':
            marginTop = 0;
            marginBottom = 15;
            break;
        default:
            break;
        }

        return {
            display,
            width,
            marginTop,
            marginBottom,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    getSelectOptions(item) {
        let result = item.items;

        if (item.actions && item.actions.referenceName) {
            const referenceFieldValue = this.getTabItemValue(item.actions.referenceName);

            result = result.filter((obj) => obj.referenceValue === referenceFieldValue);
        }

        return result.map((obj) => ({
            id: obj.value,
            name: obj.text,
        }));
    }

    // eslint-disable-next-line class-methods-use-this
    getInputDateMask(item) {
        switch (item.type) {
        case 'datetime':
            return [/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ',', ' ', /\d/, /\d/, ':', /\d/, /\d/];
        default:
            return [/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
        }
    }

    getInputDateMaxMinRules(item) {
        const { initialContractBeginDate, contractEndDate } = this.store.policy.policy;
        switch (item.parameterName) {
        case 'EventDate':
            return {
                maxDate: new Date(contractEndDate) < new Date() ? new Date(contractEndDate) : new Date(),
                minDate: new Date(initialContractBeginDate),
            };
        default:
            if (item.actions && (item.actions.startDate || item.actions.endDate)) {
                return {
                    ...(item.actions.endDate || item.actions.maxDate) && {
                        maxDate: new Date(this.getTabItemValue(item.actions.endDate) || (item.actions.maxDate === 'true' ? new Date() : item.actions.maxDate)),
                    },
                    ...(item.actions.startDate || item.actions.minDate) && {
                        minDate: new Date(this.getTabItemValue(item.actions.startDate) || (item.actions.minDate === 'true' ? new Date() : item.actions.minDate)),
                    },
                };
            }

            if (item.actions && (item.actions.maxDate || item.actions.minDate)) {
                return {
                    ...item.actions.maxDate && {
                        maxDate: item.actions.maxDate === 'true' ? new Date() : new Date(item.actions.maxDate),
                    },
                    ...item.actions.minDate && {
                        minDate: item.actions.minDate === 'true' ? new Date() : new Date(item.actions.minDate),
                    },
                };
            }

            return {};
        }
    }

    getClaims() {
        let claims = this.state.claimsList || [];

        if (this.state.policyId !== -1) {
            claims = claims.filter((item) => item.policy.policyId === this.state.policyId);
        }

        if (this.state.claimId) {
            const currentClaim = claims.filter((item) => item.claimId === this.state.claimId)[0];

            if (currentClaim && currentClaim.status && currentClaim.status.code === 'LossClaimStatus_Sent') {
                return [];
            }

            claims = claims.filter((item) => item.claimId !== this.state.claimId);
        }

        return claims;
    }

    isPolicyBannerShow() {
        return ((this.state.partner === 'uralsibins'
                && this.state.policyId !== -1
                && (this.store.policy.policies.find((item) => item.policyId === this.state.policyId) || {}).url)
            || (this.store.policy.policies.find((item) => item.policyId === this.state.policyId) || {}).insuranceProgram === 'ДМС');
    }

    isOnlineDoctorBannerShow() {
        return (this.state.partner === 'onlinedoctor' && this.state.onlineDoctorRedirectUrl);
    }

    isNextButtonHidden() {
        return this.isPolicyBannerShow() || this.isOnlineDoctorBannerShow();
    }

    componentDidMount() {
        document.title = this.state.title;

        this.fillPolicies();
        this.fillClaims();

        const tabs = [...this.state.tabs];

        this.setDefaultTabItemData(tabs);
        this.checkDefaultsValues();

        this.setState({
            ...this.state,
            tabs,
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.userNameNavbar}
                companyCode={this.store.app.companyCode}
                hasBackground={false}
                noVerifyEmailModal
            >
                {this.state.checkoutStatement.isShowForm && (
                    <ModalOverlay handleClose={(e) => this.closeConfirmationForm(e)}>
                        <CheckSmsCodeCard
                            code={this.state.checkoutStatement.code}
                            codeError={this.state.checkoutStatement.codeError}
                            resendLoading={this.state.checkoutStatement.resendLoading}
                            submitLoading={this.state.checkoutStatement.confirmCodeLoading}
                            resendTime={180}
                            onCodeChange={(e) => this.setState({
                                ...this.state,
                                checkoutStatement: {
                                    ...this.state.checkoutStatement,
                                    code: e.target.value,
                                },
                            })}
                            onClose={(e) => this.closeConfirmationForm(e)}
                            onResend={() => this.sendConfirmationCode()}
                            onSubmit={(e) => this.checkConfirmationCode(e)}
                        />
                    </ModalOverlay>
                )}

                <SuccessReportModal
                    isShow={this.state.checkoutStatement.isShowSuccessModal}
                    handleClose={() => this.setState({
                        ...this.state,
                        checkoutStatement: {
                            ...this.state.checkoutStatement,
                            isShowSuccessModal: false,
                        },
                    })}
                />

                {this.store.policy.isFailAddPolicyModal && <Modal
                    title={ 'Ошибка добавления полиса' }
                    body={<p>{this.store.policy.errorMessage}</p>}
                    footer={
                        <Button
                            onClick={() => this.store.policy.setIsFailAddPolicyModal(false)}
                            style={{ width: '100%' }}
                            size="lg"
                            variant="primary">{ 'Закрыть' }</Button>
                    }
                    handleClose={() => this.store.policy.setIsFailAddPolicyModal(false)} />
                }

                <AddPolicyModal
                    isShow={this.store.policy.isAddPolicyModal}
                    addPolicy={this.addPolicy}
                    form={this.state.formAddPolicy}
                    loading={this.store.policy.isLoadingAddPolicy}
                    onFormChange={this.updateFormAddPolicy}
                    handleClose={() => this.store.policy.setIsAddPolicyModal(false)}/>

                <div className={styles.ReportPageWrapper}>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <Heading
                                    ref={this.titleRef}
                                    description={'Чтобы заявить о страховом событии онлайн заполните форму ниже\nи приложите необходимые сопроводительные документы.'}
                                >
                                    { this.state.title }
                                </Heading>

                                {(this.state.tabs.length > 1 && this.state.activeTab !== 0) && (
                                    <StageLevel
                                        items={this.state.tabs.map((tab) => ({ title: tab.shortTitle }))}
                                        active={this.state.activeTab}
                                    />
                                )}

                                <div className={styles.ReportPageContent}>
                                    {this.state.tabs.map((tab, tabIndex) => (
                                        this.state.activeTab === tabIndex && (
                                            <form onSubmit={this.handleSubmit}
                                                key={tabIndex}
                                                className={styles.ReportPageFormSide}
                                            >
                                                <Heading level={4}>{ tab.title }</Heading>

                                                {tabIndex === 0 && (
                                                    <React.Fragment>
                                                        <div style={{ width: '100%', marginTop: 30 }}>
                                                            <SelectField
                                                                label="№ Полиса"
                                                                size="lg"
                                                                name="PolicyNumber"
                                                                placeholder="№ Полиса"
                                                                value={this.state.policyId}
                                                                options={this.state.policyList}
                                                                onChange={this.onChangePolicy}
                                                                disabled={(this.state.claimStatus === 'LossClaimStatus_Sent')}
                                                            />
                                                        </div>

                                                        {this.state.riskSelectionsSelects.map((item, itemIndex) => (
                                                            <React.Fragment key={itemIndex}>
                                                                <div style={{ width: '100%', marginTop: 30 }}>
                                                                    <SelectField
                                                                        label={item.name}
                                                                        size="lg"
                                                                        name={item.code}
                                                                        placeholder={item.name}
                                                                        value={this.getSelectionValue(item)}
                                                                        options={item.selections.map((selection) => ({
                                                                            id: selection.code,
                                                                            name: selection.name,
                                                                        }))}
                                                                        onChange={(e) => this.onChangeSelections(e, item)}
                                                                        disabled={(this.state.claimStatus === 'LossClaimStatus_Sent')}
                                                                    />
                                                                </div>
                                                                {item.code === 'Insured' && this.state.selections[0]
                                                                    && this.state.selections[0].code === 'OtherInsured'
                                                                    && this.state.selections[0].name === 'Застрахованный'
                                                                    && (
                                                                        <>
                                                                            <div style={{ width: '100%', marginTop: 30 }}>
                                                                                <InputField
                                                                                    type="text"
                                                                                    label="Фамилия другого застрахованного"
                                                                                    size="lg"
                                                                                    name="otherInsuredSurname"
                                                                                    placeholder="Фамилия другого застрахованного"
                                                                                    value={this.state.otherInsuredSurname}
                                                                                    onChange={(e) => this.setState({
                                                                                        ...this.state,
                                                                                        otherInsuredSurname: e.target.value,
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <div style={{ width: '100%', marginTop: 30 }}>
                                                                                <InputField
                                                                                    type="text"
                                                                                    label="Имя другого застрахованного"
                                                                                    size="lg"
                                                                                    name="otherInsuredName"
                                                                                    placeholder="Имя другого застрахованного"
                                                                                    value={this.state.otherInsuredName}
                                                                                    onChange={(e) => this.setState({
                                                                                        ...this.state,
                                                                                        otherInsuredName: e.target.value,
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <div style={{ width: '100%', marginTop: 30 }}>
                                                                                <InputField
                                                                                    type="text"
                                                                                    label="Отчество другого застрахованного"
                                                                                    size="lg"
                                                                                    name="otherInsuredPatronymic"
                                                                                    placeholder="Отчество другого застрахованного"
                                                                                    value={this.state.otherInsuredPatronymic}
                                                                                    onChange={(e) => this.setState({
                                                                                        ...this.state,
                                                                                        otherInsuredPatronymic: e.target.value,
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <div style={{ width: '100%', marginTop: 30 }}>
                                                                                <DateInputField
                                                                                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                                    label="Дата рождения другого застрахованного"
                                                                                    size="lg"
                                                                                    format="dd.MM.yyyy"
                                                                                    placeholder="дд.мм.гггг"
                                                                                    name="otherInsuredBirthday"
                                                                                    value={this.state.otherInsuredBirthday}
                                                                                    onChange={(value) => this.setState({
                                                                                        ...this.state,
                                                                                        otherInsuredBirthday: value,
                                                                                    })}
                                                                                    minDate={new Date('1920-01-01')}
                                                                                    maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                        { (this.isPolicyBannerShow()) && (
                                                            <div style={{ width: '100%', marginTop: 30 }}>
                                                                <Panel>
                                                                    <p>По&nbsp;настоящей программе ООО&nbsp;СК&nbsp;«Инлайф страхование» организует и&nbsp;оплачивает медицинские услуги, предусмотренные программой при&nbsp;остром заболевании, обострении хронического заболевания, травме и&nbsp;отравлении.</p>
                                                                    <p>По&nbsp;любым вопросам медицинского обслуживания вы&nbsp;можете обращаться на&nbsp;круглосуточный медицинский пульт по&nbsp;телефону: <nobr><a href="tel:88002509707">8 800 250-97-07</a></nobr></p>
                                                                </Panel>
                                                            </div>
                                                        )}

                                                        {this.isOnlineDoctorBannerShow() && (
                                                            <div style={{ width: '100%', marginTop: 30 }}>
                                                                <Panel>
                                                                    <p>
                                                                        Чтобы воспользоваться услугой «Телемедицина» вам необходимо перейти в кабинет‎ «Онлайн Доктор»
                                                                    </p>
                                                                    <Button
                                                                        href={this.state.onlineDoctorRedirectUrl}
                                                                        variant="primary"
                                                                        size="sm"
                                                                    >Перейти в кабинет</Button>
                                                                </Panel>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}

                                                {this.getProcessedTabItems(tabIndex).map((item, itemIndex) => (
                                                    <div
                                                        key={itemIndex}
                                                        style={this.itemStyles(item)}
                                                    >

                                                        {item.type === 'select' && (
                                                            <SelectField
                                                                label={item.label}
                                                                size="lg"
                                                                name={item.parameterName}
                                                                placeholder={item.placeHolder}
                                                                value={item.value || ''}
                                                                options={this.getSelectOptions(item)}
                                                                onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                description={item.prompt}
                                                            />
                                                        )}

                                                        {(item.type === 'label' && (
                                                            <Label
                                                                required={this.isFormItemRequired(item)}
                                                            >{ item.label }</Label>
                                                        ))}

                                                        {(item.type === 'input' || item.type === 'text' || item.type === 'string' || item.type === 'email' || item.type === '' || item.type === 'bank-name') && (
                                                            item.mask === '' || !item.mask
                                                                ? (
                                                                    <InputField
                                                                        type="text"
                                                                        label={item.label}
                                                                        size="lg"
                                                                        name={item.parameterName}
                                                                        placeholder={item.placeHolder}
                                                                        value={item.value || ''}
                                                                        onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                        disabled={this.isFormItemDisabled(item)}
                                                                        required={this.isFormItemRequired(item)}
                                                                        error={item.error}
                                                                        description={item.prompt}
                                                                    />
                                                                )
                                                                : (
                                                                    <MaskedInputField
                                                                        mask={prepareMask(item)}
                                                                        type="text"
                                                                        label={item.label}
                                                                        size="lg"
                                                                        name={item.parameterName}
                                                                        placeholder={item.placeHolder}
                                                                        value={item.value || ''}
                                                                        onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                        disabled={this.isFormItemDisabled(item)}
                                                                        required={this.isFormItemRequired(item)}
                                                                        error={item.error}
                                                                        description={item.prompt}
                                                                    />
                                                                )
                                                        )}

                                                        {(item.type === 'textarea') && (
                                                            <TextareaField
                                                                rows="5"
                                                                label={item.label}
                                                                name={item.parameterName}
                                                                placeholder={item.placeHolder}
                                                                value={item.value || ''}
                                                                onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                description={item.prompt}
                                                            />
                                                        )}

                                                        {(item.type === 'bank-bik') && (
                                                            <AutocompleteInputField
                                                                Component={MaskedInputField}
                                                                mask={prepareMask(item)}
                                                                type="text"
                                                                label={item.label}
                                                                size="lg"
                                                                name={item.parameterName}
                                                                placeholder={item.placeHolder}
                                                                value={item.value || ''}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                error={item.error}
                                                                description={item.prompt}
                                                                items={this.state.suggestionsBankResult.map((elem) => `${elem.name} - ${elem.bik}`)}
                                                                includeSearch={false}
                                                                setValue={(value, index) => this.setSuggestionsBank(index) }
                                                                onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                onBlur={() => this.onBlurFormField(tabIndex, itemIndex)}
                                                            />
                                                        )}

                                                        {(item.type === 'address') && (
                                                            <AutocompleteInputField
                                                                type="text"
                                                                label={item.label}
                                                                size="lg"
                                                                name={item.parameterName}
                                                                placeholder={item.placeHolder}
                                                                value={item.value || ''}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                error={item.error}
                                                                description={item.prompt}
                                                                items={this.state.suggestionsAddressResult[item.parameterName] || []}
                                                                includeSearch={false}
                                                                setValue={(value) => this.onChangeFormField(value, tabIndex, itemIndex) }
                                                                onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                            />
                                                        )}

                                                        {(item.type === 'number' || item.type === 'bank-schet' || item.type === 'bank-inn' || item.type === 'bank-rs') && (
                                                            <MaskedInputField
                                                                mask={(val) => {
                                                                    if (item.mask) {
                                                                        return prepareMask(item);
                                                                    }

                                                                    const mask = [];

                                                                    for (let i = 0; i < val.length; i += 1) {
                                                                        mask.push(/[0-9]/);
                                                                    }

                                                                    return mask;
                                                                }}
                                                                type="text"
                                                                label={item.label}
                                                                size="lg"
                                                                name={item.parameterName}
                                                                placeholder={item.placeHolder}
                                                                value={item.value || ''}
                                                                onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                error={item.error}
                                                                description={item.prompt}
                                                            />
                                                        )}

                                                        {(item.type === 'phone') && (
                                                            <MaskedInputField
                                                                mask={['+', '7', ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                                                label={item.label}
                                                                size="lg"
                                                                type="tel"
                                                                placeholder={item.placeHolder}
                                                                name={item.parameterName}
                                                                value={item.value || ''}
                                                                onChange={(e) => this.onChangeFormField(e.target.value, tabIndex, itemIndex)}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                description={item.prompt}
                                                            />
                                                        )}

                                                        {(item.type === 'date' || item.type === 'datetime') && (
                                                            <DateInputField
                                                                mask={this.getInputDateMask(item)}
                                                                label={item.label}
                                                                size="lg"
                                                                name={item.parameterName}
                                                                icon="calendar"
                                                                value={item.value ? moment(item.value).toDate() : null}
                                                                format={item.type === 'datetime' ? 'dd.MM.yyyy, HH:mm' : 'dd.MM.yyyy'}
                                                                showTimeInput={item.type === 'datetime'}
                                                                placeholder={item.placeHolder}
                                                                onChange={(value) => this.onChangeFormField(value, tabIndex, itemIndex)}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                description={item.prompt}
                                                                {...this.getInputDateMaxMinRules(item)}
                                                            >
                                                                {item.description && (
                                                                    <p style={{
                                                                        marginLeft: 30,
                                                                        color: '#828a9f',
                                                                    }}>
                                                                        { item.description }
                                                                    </p>
                                                                )}
                                                            </DateInputField>
                                                        )}

                                                        {(item.type === 'checkbox' && (
                                                            <Checkbox
                                                                label={item.label}
                                                                name={item.parameterName}
                                                                checked={JSON.parse(item.value)}
                                                                onChange={(e) => this.onChangeFormField(e.target.checked, tabIndex, itemIndex)}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                description={item.prompt}
                                                            >
                                                                {item.placeHolder}
                                                            </Checkbox>
                                                        ))}

                                                        {(item.type === 'radio' && (
                                                            <Radio
                                                                // label={item.label}
                                                                name={item.parameterName}
                                                                checked={this.getTabItemValue(item.actions.referenceName) === item.value}
                                                                value={item.value}
                                                                onChange={(e) => this.onChangeFormField(e.target.value, ...this.getItemPosition(item.actions.referenceName))}
                                                                disabled={this.isFormItemDisabled(item)}
                                                                required={this.isFormItemRequired(item)}
                                                                description={item.prompt}
                                                            >
                                                                {item.placeHolder}
                                                            </Radio>
                                                        ))}

                                                        {(item.type === 'files' && (
                                                            this.state.fileTypes.map((fileType, fileTypeIndex) => (
                                                                <div
                                                                    key={fileTypeIndex}
                                                                    style={{ width: '100%', marginTop: fileTypeIndex !== 0 ? 30 : 0 }}
                                                                >
                                                                    <FileLoader
                                                                        title={'Добавить'}
                                                                        label={fileType.name}
                                                                        description={fileType.prompt}
                                                                        onChange={(e) => this.onChangeFiles(e, fileType.code, tabIndex, itemIndex)}
                                                                        files={(
                                                                            item.value.filter((file) => file.fileType === fileType.code).map((file, fileIndex) => (
                                                                                <File
                                                                                    key={fileIndex}
                                                                                    name={file.name}
                                                                                    href={`/api?method=Insurance&action=getFile&FileId=${file.id}&FileName=${file.name}`}
                                                                                    loading={file.tempIndex !== undefined}
                                                                                    onRemove={file.id ? () => this.deleteFile(file.id, tabIndex, itemIndex) : undefined }
                                                                                />
                                                                            )).reverse()
                                                                        )}
                                                                    />
                                                                </div>
                                                            ))
                                                        ))}

                                                        {(item.type === 'download_individual_application' && (
                                                            <Panel>
                                                                <p>Необходимо <b>скачать анкету</b> физического лица, отдать на заполнение выгодоприобретателю и отправить сканом в страховую компанию</p>
                                                                <File
                                                                    name={'Анкета физического лица'}
                                                                    href={
                                                                        ((this.getPolicyData() || {}).company || {}).code === 'Life'
                                                                            ? '/upload/docs/anketa.pdf'
                                                                            : 'https://backlk.in-lifeins.ru/upload/docs/Anketa_fizicheskogo_litsa_inlifeins.docx'
                                                                    }
                                                                />
                                                            </Panel>
                                                        ))}

                                                    </div>
                                                ))}

                                                <div className={styles.ReportPageFormSideSubmit}>
                                                    {this.prevTabAvailable()
                                                        ? (
                                                            <Button
                                                                type="button"
                                                                buttonType="text"
                                                                variant="secondary"
                                                                size="lg"
                                                                style={{ marginRight: 20 }}
                                                                onClick={() => this.prevTab()}
                                                            >
                                                                { 'Назад' }
                                                            </Button>
                                                        )
                                                        : (
                                                            <div />
                                                        )}

                                                    <div>
                                                        {!this.nextTabAvailable(true) && (
                                                            <Button
                                                                href={`/api?method=statement&action=getFileLossClaims&lossClaimId=${this.state.claimId}&fileTypeCode=FT-1000`}
                                                                type="button"
                                                                variant="primaryOutline"
                                                                size="lg"
                                                                style={{ marginRight: 30 }}
                                                            >
                                                                { 'Предпросмотр в PDF' }
                                                            </Button>
                                                        )}

                                                        {!this.isNextButtonHidden() && (
                                                            <Button
                                                                type="submit"
                                                                variant="primary"
                                                                size="lg"
                                                                disabled={this.submitDisabled()}
                                                                loading={this.state.checkoutStatement.submitLoading || this.state.editStatementLoading}
                                                            >
                                                                {
                                                                    this.nextTabAvailable(true)
                                                                        ? 'Продолжить'
                                                                        : 'Подписать и отправить'
                                                                }
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    ))}

                                    {this.getClaims().length !== 0 && (
                                        <div className={styles.ReportPageDraftSide}>
                                            <Heading level={4}>{ 'Продолжить оформление' }</Heading>

                                            <div className={styles.ReportPageDraftSideWrapper}>
                                                {this.getClaims().map((item, index) => (
                                                    <ReportDraftCard
                                                        key={index}
                                                        claimId={item.claimId}
                                                        title={item.previewText}
                                                        date={item.dateAndTime}
                                                        onSelect={this.selectDraft}
                                                        onDelete={this.deleteDraft}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(ReportPage));
