import React from 'react';
import styles from './styles.module.scss';
import QuestionLabel from '../QuestionLabel';

const Radio = ({
    label,
    style,
    required = false,
    description,
    children,
    ...rest
}) => (
    <div
        className={styles.RadioContainer}
        data-required={required}
    >
        {(label || description) && <label className={styles.RadioLabel}>
            { label }
            {description && <QuestionLabel text={description} />}
        </label>}

        <div
            style={{ ...style }}
            className={styles.Radio}>
            <label className={styles.RadioWrapper}>
                <input
                    type="radio"
                    className={styles.RadioInput}
                    {...rest} />
                <div className={styles.RadioToggler} />
            </label>
            { children && <div className={styles.RadioText}>{ children }</div> }
        </div>

        {description && <div className={styles.RadioPrompt}>{ description }</div>}
    </div>
);

export default Radio;
