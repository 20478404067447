import React from 'react';
import moment from 'moment';
import styles from './styles.module.scss';

const ReportDraftCard = ({
    claimId,
    number,
    title,
    date,
    onSelect,
    onDelete,
}) => {
    const handleOnSelect = (e) => {
        e.stopPropagation();

        onSelect(claimId);
    };

    const handleOnDelete = (e) => {
        e.stopPropagation();

        onDelete(claimId);
    };

    const formattedDate = () => {
        // let dateObject = moment.utc(date);
        // dateObject = moment(dateObject).local();
        const dateObject = moment(date);

        return `Сохранен: ${dateObject.format('DD.MM.YYYY')} в ${dateObject.format('HH:mm')}`;
    };

    return (
        <div
            className={styles.ReportDraftCard}
            onClick={handleOnSelect}
        >
            <div
                className={styles.ReportDraftCardRemove}
                onClick={handleOnDelete}
            />

            {number && (
                <div className={styles.ReportDraftCardNumber}>
                    { number }
                </div>
            )}

            {title && (
                <div className={styles.ReportDraftCardTitle}>
                    { title }
                </div>
            )}

            {date && (
                <div className={styles.ReportDraftCardDate}>
                    { formattedDate() }
                </div>
            )}
        </div>
    );
};

export default ReportDraftCard;
