import React from 'react';
import cn from 'classnames';
import HeaderFirstNavBar from './HeaderFirstNavBar';
import HeaderSecondNavBar from './HeaderSecondNavBar';
import styles from './styles.module.scss';

const MainHeader = ({
    hasBackground = true,
    companyCode,
    userName,
    claimsNotifCount = 0,
}) => (
    <div className={cn(styles.Header, { [styles.HeaderLight]: !hasBackground })}>
        <HeaderFirstNavBar companyCode={companyCode} />

        <HeaderSecondNavBar
            companyCode={companyCode}
            userName={userName}
            claimsNotifCount={claimsNotifCount}
        />
    </div>
);

export default MainHeader;
