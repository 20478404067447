import React, { Fragment } from 'react';
import cn from 'classnames';
import PolicyCard from 'components/PolicyCard';
import styles from './styles.module.scss';

const PolicyCardList = ({
    items = [],
}) => {
    const isAlter = (columns) => {
        switch (true) {
        case columns <= 3:
            return false;
        case columns === 4:
            return true;
        case columns <= 6:
            return false;
        case columns <= 8:
            return true;
        case columns === 9:
            return false;
        default:
            return true;
        }
    };

    return (
        <Fragment>
            {items.length !== 0 && (
                <div className={cn(styles.PolicyCardList, { [styles.PolicyCardListAlter]: isAlter(items.length) })}>
                    {items.map((item, index) => (
                        <PolicyCard
                            key={index}
                            data={item} />
                    ))}
                </div>
            )}
        </Fragment>
    );
};

export default PolicyCardList;
