import React, { PureComponent } from 'react';
import moment from 'moment/moment';
import styles from './styles.module.scss';

class UserItem extends PureComponent {
    render() {
        const { data, useUser } = this.props;
        return (
            <>
                <div className={styles.UserItemSurname}>{data.surname}</div>
                <div className={styles.UserItemName}>{data.name}</div>
                <div className={styles.UserItemPatronymic}>{data.patronymic}</div>
                <div className={styles.UserItemBirthDate}>{moment(data.birthDate).format('DD.MM.YYYY') }</div>
                <div className={styles.UserItemPhone}>{data.phone}</div>
                <div className={styles.UserItemEmail}>{data.email}</div>
                <div className={styles.UserItemPolicyNumber}>{data.policyNumber}</div>
                <div className={styles.UserItemCompanyNumber}>{data.companyName}</div>
                <div className={styles.UserItemUseButton}>
                    <button onClick={() => useUser(data.clientId, data.phone)}>Войти</button>
                </div>
            </>
        );
    }
}

export default UserItem;
