import { observable, action, decorate } from 'mobx';

class AppModule {
    companyCode = null;

    mainLayoutBackgroundUrl = null;

    modalData = null;

    isShowVerifyEmailModal = false;

    constructor() {
        const InsHosts = process.env.REACT_APP_INS_HOST.split(', ');
        const lifeHosts = process.env.REACT_APP_LIFE_HOST.split(', ');

        if (InsHosts.includes(window.location.hostname)) {
            this.setCompanyCode('SK');
        } else if (lifeHosts.includes(window.location.hostname)) {
            this.setCompanyCode('Life');
        } else {
            this.setCompanyCode('SK');
        }
    }

    setCompanyCode(value) {
        this.companyCode = value;
    }

    setMainLayoutBackgroundUrl(value) {
        this.mainLayoutBackgroundUrl = value;
    }

    setModalData(data) {
        this.modalData = data;
    }

    setIsShowVerifyEmailModal(data) {
        this.isShowVerifyEmailModal = data;
    }

    redirectCompany(code, path = null) {
        if (process.env.NODE_ENV !== 'development') {
            if (code === 'Life' && this.companyCode !== 'Life') {
                if (path) window.location.href = `${process.env.REACT_APP_LIFE_APP_URL}${path}`;
            }

            if (code === 'SK' && this.companyCode !== 'SK') {
                if (path) window.location.href = `${process.env.REACT_APP_INS_APP_URL}${path}`;
            }
        }
    }
}

decorate(AppModule, {
    companyCode: observable,
    mainLayoutBackgroundUrl: observable,
    modalData: observable,
    isShowVerifyEmailModal: observable,
    setCompanyCode: action.bound,
    setMainLayoutBackgroundUrl: action.bound,
    setModalData: action.bound,
    setIsShowVerifyEmailModal: action.bound,
    redirectCompany: action.bound,
});

export default AppModule;
