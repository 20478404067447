import { observable, action, decorate } from 'mobx';
import { DATA_CHARTS_GET_GRAPHICS_DATA } from 'api';

class InvestmentIncomeModule {
    graphicsData = [];

    initialization = false;

    loading = true;

    getGraphicsData(index, policyId) {
        return DATA_CHARTS_GET_GRAPHICS_DATA(index, policyId).then((data) => {
            this.graphicsData = data;
        });
    }

    setInitialization(value) {
        this.initialization = value;
    }

    setLoading(value) {
        this.loading = value;
    }
}

decorate(InvestmentIncomeModule, {
    graphicsData: observable,
    initialization: observable,
    loading: observable,
    getGraphicsData: action.bound,
    setInitialization: action.bound,
    setLoading: action.bound,
});

export default InvestmentIncomeModule;
