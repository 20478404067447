import 'styles/loader.scss';

import React from 'react';
import {
    Route,
    Switch,
    Redirect,
    BrowserRouter,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import Async from 'react-async';
import { Provider } from 'mobx-react';
import { registerLocale } from 'react-datepicker';
import localeRu from 'date-fns/locale/ru';

import initializeStore from 'stores';

import BaseLayout from 'layouts/BaseLayout';

import LoginPage from 'pages/LoginPage';
import Admin from 'pages/Admin';
import AdminUsersPage from 'pages/AdminUsersPage';
import AdminRegisterPage from 'pages/AdminRegisterPage';
import RegisterPage from 'pages/RegisterPage';
import RegisterConfirmationPage from 'pages/RegisterConfirmationPage';
import ClaimDMSPage from 'pages/ClaimDMSPage';
import FeedbackPage from 'pages/FeedbackPage';
import FeedbackRegisterPage from 'pages/FeedbackRegisterPage';
import ProfilePage from 'pages/ProfilePage';
import PolicyPage from 'pages/PolicyPage';
import SettingsPage from 'pages/SettingsPage';
import ReportPage from 'pages/ReportPage';
import ManagerReportPage from 'pages/ManagerReportPage';
import InsuranceClaimsPage from 'pages/InsuranceClaimsPage';
import InsuranceClaimsDetailsPage from 'pages/InsuranceClaimsDetailsPage';
import AddDocumentsPage from 'pages/InsuranceClaimsDetailsPage/AddDocumentsPage';
import ProfilePolicyUpdateRequestPage from 'pages/ProfilePolicyUpdateRequest';
import PaymentPageSuccess from 'pages/PaymentPage/PaymentPageSuccess';
import PaymentPageFail from 'pages/PaymentPage/PaymentPageFail';
import LiaPage from 'pages/LiaPage';
import Page404 from 'pages/Page404';
import GetDocuments from 'pages/GetDocuments';
import FadeTransition from 'components/FadeTransition';
import ScrollToTop from 'components/ScrollToTop';
import BonusPage from './pages/BonusesPage';
import ExternalRedirect from './ExternalRedirect';

registerLocale('ru', localeRu);

const initializeReactGA = (companyCode) => {
    const key = companyCode === 'Life' ? 'UA-10854799-10' : 'UA-10854799-9';

    ReactGA.initialize(key);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

const store = initializeStore();

if (process.env.NODE_ENV === 'production') {
    initializeReactGA(store.app.companyCode);
}

const getAuthUser = () => store.profile.getClientInfo();

const hasAuthUser = () => Object.keys(store.profile.user).length !== 0;

const { isAdmin } = store.profile.user;

const AppRoute = ({ component: Component, layout: Layout, meta = {}, verify, ...rest }) => {
    const { onlyWhenLoggedOut, isAdminPage } = meta;
    const isPublic = !!meta.public;
    const phoneRequired = !!meta.phoneRequired;
    const loggedIn = hasAuthUser();
    let toLogin = false;

    if (!Component && !isAdminPage) {
        return <Redirect to={{ pathname: '/login' }} />;
    }

    if (phoneRequired) {
        toLogin = !store.login.form.fields.phone.value;
    }

    return (
        <Route {...rest} render={(props) => (
            <React.Fragment>

                {((!loggedIn && !isPublic) || toLogin) && (
                    <Redirect to={{ pathname: '/login' }}/>
                )}

                {(((!loggedIn && !isPublic) || toLogin) && isAdminPage) && (
                    <Redirect to={{ pathname: '/admin' }}/>
                )}

                {(!isAdmin && isAdminPage) && (
                    <Redirect to={{ pathname: '/admin' }}/>
                )}

                {(loggedIn && !!onlyWhenLoggedOut) && (
                    <Redirect to={{ pathname: '/profile' }}/>
                )}

                {Layout
                    ? (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )
                    : (
                        <React.Fragment>
                            {store.loading.complete()}
                            {window.scrollTo(0, 0)}
                            <Component {...props} />
                        </React.Fragment>
                    )}
            </React.Fragment>
        )}/>
    );
};

const Router = () => (
    <BrowserRouter>
        <ScrollToTop callback={() => {
            document.getElementsByTagName('body')[0].scrollTop = 0;
            getAuthUser();
        }}>
            <Switch>
                <AppRoute exact path="/"/>
                <AppRoute exact path="/get/:guid" layout={BaseLayout} component={GetDocuments}/>
                <AppRoute exact path="/login" layout={BaseLayout} component={LoginPage}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: true,
                    }}
                />
                <AppRoute path="/a/" layout={BaseLayout} component={LoginPage}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: false,
                    }}
                />

                <AppRoute exact path="/admin" layout={BaseLayout} component={Admin}
                    meta={{
                        isAdminPage: true,
                    }}/>

                <AppRoute exact path="/admin/users" layout={BaseLayout} component={AdminUsersPage}
                    meta={{
                        isAdminPage: true,
                    }}/>

                <AppRoute exact path="/admin/register" layout={BaseLayout} component={AdminRegisterPage}
                    meta={{
                        isAdminPage: true,
                    }}/>

                <AppRoute exact path="/register" layout={BaseLayout} component={RegisterPage}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: true,
                        phoneRequired: true,
                    }}
                />
                <AppRoute exact path="/register/confirmation" layout={BaseLayout} component={RegisterConfirmationPage}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: true,
                        phoneRequired: true,
                    }}
                />
                {store.app.companyCode === 'SK'
                    ? <AppRoute
                        exact
                        path="/feedback"
                        component={FeedbackPage}
                        meta={{
                            public: true,
                        }}
                    />
                    : <ExternalRedirect from='/feedback' to='https://pyrus.sovcombank.ru/form/387411' />}
                {store.app.companyCode === 'SK'
                    ? <AppRoute
                        exact
                        path="/feedback/register"
                        component={FeedbackRegisterPage}
                        meta={{
                            public: true,
                        }}
                    /> : <ExternalRedirect from='/feedback' to='https://pyrus.sovcombank.ru/form/387411' />}
                <AppRoute
                    exact
                    path="/profile"
                    component={ProfilePage}
                />
                <AppRoute
                    exact
                    path="/profile/policy/:policyId"
                    component={PolicyPage}
                />
                <AppRoute
                    exact
                    path="/profile/policy/:policyId/update"
                    component={ProfilePolicyUpdateRequestPage}
                />
                <AppRoute
                    exact
                    path="/settings"
                    component={SettingsPage}
                />
                <AppRoute
                    exact
                    path="/claim"
                    component={ReportPage}
                />
                <AppRoute
                    exact
                    path="/claimDMS/:policyId"
                    component={ClaimDMSPage}
                />
                <AppRoute
                    exact
                    path="/claimDMS"
                    component={ClaimDMSPage}
                />
                <AppRoute
                    exact
                    path="/bonus"
                    component={BonusPage}
                />
                <AppRoute
                    exact
                    path="/admin/claim"
                    component={ManagerReportPage}
                />
                <AppRoute
                    exact
                    path="/claims"
                    component={InsuranceClaimsPage}
                />
                <AppRoute
                    exact
                    path="/claims/:claimId"
                    component={InsuranceClaimsDetailsPage}
                />
                <AppRoute
                    exact
                    path="/claims/:claimId/documents"
                    component={AddDocumentsPage}
                />
                <AppRoute
                    exact
                    path="/payment/success"
                    component={PaymentPageSuccess}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: false,
                    }}
                />
                <AppRoute
                    exact
                    path="/payment/fail"
                    component={PaymentPageFail}
                    meta={{
                        public: true,
                        onlyWhenLoggedOut: false,
                    }}
                />
                <AppRoute
                    exact
                    path="/lia"
                    component={LiaPage}
                    meta={{
                        public: true,
                    }}
                />
                <AppRoute
                    exact
                    path={'/scb'}
                    component={() => {
                        window.location.replace('https://pyrus.sovcombank.ru/form/373421');
                    }}
                    meta={{
                        public: true,
                    }}
                />
                <AppRoute
                    exact
                    path="*"
                    component={Page404}
                    meta={{
                        public: true,
                    }}
                />
            </Switch>
        </ScrollToTop>
    </BrowserRouter>
);

const App = () => (
    <React.Fragment>
        <Provider store={store}>
            <Async promiseFn={getAuthUser}>
                {({ isLoading }) => (
                    <FadeTransition loading={isLoading}>
                        {!isLoading && <Router/>}
                    </FadeTransition>
                )}
            </Async>
        </Provider>
    </React.Fragment>
);

export default App;
