import { observable, action, decorate } from 'mobx';
import { AUTH_ACCEPT_CONDITIONS, AUTH_SIGN_IN, AUTH_VALIDATE_SMS } from 'api';

class LoginModule {
    initialForm = {
        fields: {
            phone: {
                value: '',
                error: null,
            },
            code: {
                value: '',
                error: null,
            },
            userAgreement: {
                value: false,
            },
        },
        meta: {
            timeToResend: 180,
            codeSended: false,
            userAgreementConfirmed: false,
            loading: {
                SEND_SMS_CODE: false,
                CHECK_SMS_CODE: false,
            },
            submitDisabled: () => {
                switch (this.stage) {
                case 'phone':
                    return (!this.form.fields.phone.value || this.form.fields.phone.value.length !== 16) || this.form.meta.loading.SEND_SMS_CODE;
                case 'code':
                    return (!this.form.fields.code.value || this.form.fields.code.value.length !== 5) || !this.form.fields.userAgreement.value || this.form.meta.loading.CHECK_SMS_CODE;
                default:
                    return true;
                }
            },
        },
    }

    constructor(props, appStore) {
        this.appStore = appStore;
    }

    isShowConfirmModal = false;

    acceptCode = '';

    modalData = {
        success: false,
        message: false,
        phone: false,
        text: false,
    };

    stage = 'phone';

    form = { ...this.initialForm };

    userFound = false;

    userUnique = false;

    hasFirstLogin = false;

    isAdmin = false;

    startResendTimer() {
        if (this.form.meta.codeSended) {
            let interval = null;

            interval = setInterval(() => {
                if (this.form.meta.timeToResend > 1) {
                    this.form.meta.timeToResend -= 1;
                } else {
                    this.form.meta.codeSended = false;
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    setAcceptCode(value) {
        this.acceptCode = value;
    }

    setIsAdmin(value) {
        this.isAdmin = value;
    }

    setIsShowConfirmModal(value) {
        this.isShowConfirmModal = value;
    }

    onFieldChange = (field, value) => {
        this.form.fields[field].value = value;

        if (this.form.fields[field].error) {
            this.form.fields[field].error = null;
        }
    }

    signIn() {
        this.form.meta.loading.SEND_SMS_CODE = true;

        return AUTH_SIGN_IN(this.form.fields.phone.value)
            .then((data) => {
                this.form.meta.loading.SEND_SMS_CODE = false;

                const { success, message, isTermsOfUseConfirmed } = data;

                if (success) {
                    this.stage = 'code';
                    this.form.meta.timeToResend = 180;
                    this.form.meta.codeSended = true;
                    this.form.meta.userAgreementConfirmed = isTermsOfUseConfirmed;
                    this.form.fields.userAgreement.value = isTermsOfUseConfirmed;

                    this.form.fields.phone.error = null;

                    this.startResendTimer();
                } else {
                    this.form.fields.phone.error = message;
                }
            })
            .catch((error) => {
                this.form.meta.loading.SEND_SMS_CODE = false;

                const { message } = error.data;

                this.form.fields.phone.error = message || 'Не удалось отправить код';
            });
    }

    validateSMS() {
        this.form.meta.loading.CHECK_SMS_CODE = true;

        return new Promise((res) => {
            AUTH_VALIDATE_SMS(this.form.fields.phone.value, this.form.fields.code.value)
                .then((data) => {
                    this.form.meta.loading.CHECK_SMS_CODE = false;

                    const { userFound, userUnique, hasFirstLogin } = data;

                    this.userFound = userFound;
                    this.userUnique = userUnique;
                    this.hasFirstLogin = hasFirstLogin;

                    res();
                }).catch((error) => {
                    this.form.meta.loading.CHECK_SMS_CODE = false;

                    const { message } = error.data;

                    this.form.fields.code.error = message || 'Не удалось обработать код';
                });
        });
    }

    acceptConditions() {
        return new Promise((res) => {
            AUTH_ACCEPT_CONDITIONS(this.acceptCode)
                .then((data) => {
                    this.modalData = data;
                    res();
                }).catch((error) => {
                    console.log(error);
                    res();
                });
        });
    }

    reset() {
        this.stage = 'phone';
        this.form = { ...this.initialForm };
    }
}

decorate(LoginModule, {
    isShowConfirmModal: observable,
    stage: observable,
    form: observable,
    userFound: observable,
    userUnique: observable,
    hasFirstLogin: observable,
    isAdmin: observable,

    setAcceptCode: action.bound,
    setIsShowConfirmModal: action.bound,
    onFieldChange: action.bound,
    signIn: action.bound,
    validateSMS: action.bound,
    reset: action.bound,
    setIsAdmin: action.bound,
});

export default LoginModule;
