import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import styles from './Page404.module.scss';
import TopWrapper from '../../components/TopWrapper';

class Page404 extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Страница не найдена',
        };
    }

    componentDidMount() {
        document.title = this.state.title;
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.userNameNavbar}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <TopWrapper background={this.state.dayBackground}/>
                    <ContentWrapper>
                        <div className={styles.NotFountContainer}>
                            <h2 className={styles.NotFountH1}>404</h2>
                            <h1 className={styles.NotFountH2}>Страница не найдена</h1>
                        </div>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(Page404));
