import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';
import Modal from 'components/Modal';

const SuccessRegisterNotPolicyHolderModal = ({
    isShow,
    history,
}) => {
    const close = () => {
        history.push('/profile');
    };
    return (
        isShow && <Modal
            title={ 'Регистрация прошла успешно!' }
            body={<p>Добро пожаловать в личный кабинет.</p>}
            footer={
                <Button
                    onClick={close}
                    style={{ width: '100%' }}
                    size="lg"
                    variant="primary">{ 'Продолжить' }</Button>
            }
            handleClose={close} />
    );
};

export default withRouter(SuccessRegisterNotPolicyHolderModal);
