import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import ClaimStages from 'components/ClaimStages';
import ClaimDocumentsCard from 'components/ClaimDocumentsCard';
import {
    STATEMENT_LOSS_CLAIM_DATA,
    STATEMENT_GET_CLAIMS_LIST,
    STATEMENT_NEW_MESSAGE,
    STATEMENT_ADD_EVENT,
    STATEMENT_ADD_EVENT_FILE,
    STATEMENT_DELETE_EVENT_FILE,
} from 'api';
import moment from 'moment';
import Preloader from 'components/Preloader/Preloader';
import styles from './styles.module.scss';

class InsuranceClaimsDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            title: 'Заявление на страховой случай №',
            number: null,
            claims: [],
            curatorName: '',
            fileTypes: [],
            canWriteMessage: false,
            canAddFile: false,
            loadingPage: true,
            submitFormNewMessageLoading: false,
        };

        this.onChangeFile = this.onChangeFile.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
        this.onAddEvent = this.onAddEvent.bind(this);
        this.onAddFormNewMessage = this.onAddFormNewMessage.bind(this);
        this.onChangeClaimField = this.onChangeClaimField.bind(this);
        this.onDeleteFormNewMessage = this.onDeleteFormNewMessage.bind(this);
        this.onSubmitFormNewMessage = this.onSubmitFormNewMessage.bind(this);
    }

    setTitle(number) {
        this.setState({
            ...this.state,
            title: `Заявление на страховой случай № ${number || ''}`,
        }, () => {
            document.title = this.state.title;
        });
    }

    async getLossClaimEvents(onlyNumber = false) {
        return STATEMENT_LOSS_CLAIM_DATA(this.props.match.params.claimId)
            .then((data) => {
                const { result: { lossNumber: number, fileTypes } } = data;
                let { result: { lossClaimEvents: claims, curatorName } } = data;

                claims = claims || [];

                claims = claims.map((claim) => ({
                    ...claim,
                    userFiles: [],
                    userMessage: '',
                }));

                curatorName = curatorName || '';

                if (onlyNumber) {
                    this.setState({
                        ...this.state,
                        number,
                    }, () => {
                        if (number) {
                            this.setTitle(number);
                        } else {
                            setTimeout(() => {
                                this.getLossClaimEvents(true);
                            }, 60000);
                        }
                    });
                } else {
                    this.setState({
                        ...this.state,
                        number,
                        claims,
                        curatorName,
                        fileTypes,
                    }, () => {
                        if (number) {
                            this.setTitle(number);
                        } else {
                            setTimeout(() => {
                                this.getLossClaimEvents(true);
                            }, 60000);
                        }
                    });
                }
            });
    }

    async getLossClaimFlags() {
        return STATEMENT_GET_CLAIMS_LIST(false, true).then((data) => {
            const { claims } = data.result;
            const claimItem = claims.find((item) => item.claimId === this.props.match.params.claimId);
            this.setState({
                ...this.state,
                canWriteMessage: claimItem.canWriteMessage,
                canAddFile: claimItem.canAddFile,
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    onChangeClaimField(e, index, field) {
        const { value } = e.target;
        const claims = [...this.state.claims];

        claims[index][field] = value;

        this.setState({
            ...this.state,
            claims,
        });
    }

    onChangeFile(e, type, index) {
        const files = [...e.target.files];
        const claims = [...this.state.claims];
        const filesError = [];

        e.target.value = null;

        for (let i = 0; i < files.length; i += 1) {
            const maxFileLength = 40;
            const maxFileSize = 20;
            const allowedExtensions = /(\.pdf|\.jpeg|\.jpg|\.bmp|\.gif|\.png|\.tif|\.tiff|\.sig)$/i;
            const file = files[i];
            const { size: fileSize, name: fileName } = file;
            const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);

            const checkFileTypeLength = Boolean(claims[index].userFiles.filter((item) => item.fileType === type).length >= maxFileLength);
            const checkAllowedExtensions = allowedExtensions.exec(fileName);

            if (checkFileTypeLength) {
                filesError.push(`К загрузке допускается всего ${maxFileLength} файлов.`);

                break;
            } else if (!checkAllowedExtensions) {
                filesError.push(`Недопустимое расширение у файла ${fileName}`);
            } else if (fileSizeMB > maxFileSize) {
                filesError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
            } else {
                claims[index].userFiles.push({
                    name: fileName,
                    fileType: type,
                    tempIndex: i,
                });

                this.setState({
                    ...this.state,
                    claims,
                });

                STATEMENT_ADD_EVENT_FILE('insuranceClaim', type, claims[index].lossClaimEventId, file)
                    .then((data) => {
                        const { result } = data;
                        const findFileObjectIndex = claims[index].userFiles.findIndex((item) => item.tempIndex === i);

                        claims[index].userFiles[findFileObjectIndex].id = result;
                        delete claims[index].userFiles[findFileObjectIndex].tempIndex;

                        this.setState({
                            ...this.state,
                            claims,
                        });
                    });
            }
        }

        if (filesError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: filesError.join('\n'),
            });
        }
    }

    onDeleteFile(id, index) {
        const claims = [...this.state.claims];
        const findFileObjectIndex = claims[index].userFiles.findIndex((item) => item.id === id);

        claims[index].userFiles.splice(findFileObjectIndex, 1);

        this.setState({
            ...this.state,
            claims,
        }, () => {
            STATEMENT_DELETE_EVENT_FILE(claims[index].lossClaimEventId, id);
        });
    }

    onAddEvent(e, index) {
        e.preventDefault();

        const { claimId } = this.props.match.params;

        STATEMENT_ADD_EVENT(claimId, this.state.claims[index].lossClaimEventId, this.state.claims[index].userMessage)
            .then(() => {
                this.getLossClaimEvents();
            });
    }

    onAddFormNewMessage() {
        const { claims } = this.state;

        if (claims.length > 0) {
            if (claims[0].type.code === 'CustomCode_NewMessage') {
                const newMessageForm = document.querySelector('#newMessageForm');
                newMessageForm.scrollIntoView();
            } else {
                const newMessageItem = {
                    createdAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    fileTypes: null,
                    files: [],
                    lossClaimEventId: '',
                    message: null,
                    newMessage: true,
                    needsReply: false,
                    replyId: null,
                    status: null,
                    type: {
                        code: 'CustomCode_NewMessage',
                        name: 'Вопрос к менеджеру',
                    },
                    userFiles: [],
                    userMessage: '',
                    userNewMessage: '',
                };
                claims.unshift(newMessageItem);
                this.setState({
                    ...this.state,
                    claims,
                }, () => {
                    const newMessageForm = document.querySelector('#newMessageForm');
                    newMessageForm.scrollIntoView();
                });
            }
        }
    }

    onDeleteFormNewMessage() {
        const { claims } = this.state;
        if (claims[0].type.code === 'CustomCode_NewMessage') {
            claims.shift();
            this.setState({
                ...this.state,
                claims,
            });
        }
    }

    onSubmitFormNewMessage(e, index) {
        e.preventDefault();

        const { claimId } = this.props.match.params;
        const message = this.state.claims[index].userNewMessage;

        if (message.trim() !== '') {
            this.setState({
                ...this.state,
                submitFormNewMessageLoading: true,
            });
            STATEMENT_NEW_MESSAGE(claimId, message).then(() => {
                this.setState({
                    ...this.state,
                    submitFormNewMessageLoading: false,
                });
                this.getLossClaimEvents();
            });
        }
    }

    async componentDidMount() {
        this.setTitle();
        await this.getLossClaimEvents();
        await this.getLossClaimFlags();
        this.setState({
            ...this.state,
            loadingPage: false,
        });
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.userNameNavbar}
                companyCode={this.store.app.companyCode}
                hasBackground={false}
            >
                {this.state.loadingPage ? (
                    <Preloader/>
                ) : (
                    <div className={styles.InsuranceClaimsDetailsPageWrapper}>
                        <Wrapper>
                            <TopWrapper>
                                <Container fluid>
                                    <Heading loading={!this.state.number}>
                                        {this.state.title}
                                    </Heading>
                                    <div className={styles.ClaimInfoWrapper}>
                                        <ClaimStages
                                            claims={this.state.claims}
                                            curatorName={this.state.curatorName}
                                            onAddEvent={this.onAddEvent}
                                            onChangeFile={this.onChangeFile}
                                            onDeleteFile={this.onDeleteFile}
                                            onChangeClaimField={this.onChangeClaimField}
                                            onSubmitFormNewMessage={this.onSubmitFormNewMessage}
                                            onDeleteFormNewMessage={this.onDeleteFormNewMessage}
                                            submitFormNewMessageLoading={this.state.submitFormNewMessageLoading}
                                        />
                                        <ClaimDocumentsCard
                                            canWriteMessage={this.state.canWriteMessage}
                                            canAddFile={this.state.canAddFile}
                                            claimId={this.props.match.params.claimId}
                                            fileTypes={this.state.fileTypes}
                                            onAddFormNewMessage={this.onAddFormNewMessage}
                                        />
                                    </div>
                                </Container>
                            </TopWrapper>
                        </Wrapper>
                    </div>
                )}
            </AppLayout>
        );
    }
}

export default inject('store')(observer(InsuranceClaimsDetailsPage));
