import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Container from 'components/Container';
import MenuBurger from 'components/MenuBurger';
import styles from './styles.module.scss';

const BaseHeader = ({
    companyCode,
}) => {
    const phoneByCompanyCode = () => {
        if (companyCode === 'Life') {
            return '8 800 234-92-02';
        }

        return '8 800 234-77-55';
    };

    const logoByCompanyCode = () => {
        if (companyCode === 'Life') {
            return styles.BaseHeaderNavSecondLogoLIFE;
        }

        return styles.BaseHeaderNavSecondLogoSK;
    };

    const Nav = () => (
        <div className={styles.BaseHeaderNavContainer}>
            <div className={styles.BaseHeaderNavContent}>
                <a
                    href="https://in-lifeins.ru/chastnyj-klient"
                    className={styles.BaseHeaderNavItem}
                    data-active={true}
                    data-dropdown={true}>
                    <span>{ 'Частным клиентам' }</span>
                </a>
            </div>

            <div className={styles.BaseHeaderNavContent}>
                <a
                    href="https://in-lifeins.ru/korp-klient"
                    className={styles.BaseHeaderNavItem}>
                    <span>{ 'Бизнесу' }</span>
                </a>

                <a
                    href="https://in-lifeins.ru/partner"
                    className={styles.BaseHeaderNavItem}>
                    <span>{ 'Партнерам' }</span>
                </a>
            </div>

            <div className={styles.BaseHeaderNavContent}>
                <a
                    href="https://in-lifeins.ru/kompaniya/novosti"
                    className={styles.BaseHeaderNavItem}>
                    <span>{ 'Пресс-центр' }</span>
                </a>

                <a
                    href="https://in-lifeins.ru/kompaniya/raskrytie-informatsii"
                    className={styles.BaseHeaderNavItem}>
                    <span>{ 'О компании' }</span>
                </a>

                <Link
                    to="/"
                    className={styles.BaseHeaderNavItem}
                    data-name="feedback">
                    <span>{ 'Обратная связь' }</span>
                </Link>

                <div className={styles.BaseHeaderNavLocation}>{ 'Москва' }</div>

                <a
                    href={`tel:${phoneByCompanyCode()}`}
                    className={styles.BaseHeaderNavPhone}>{ phoneByCompanyCode() }</a>

                <a
                    href="https://in-lifeins.ru/strahovoy-sluchay"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className={styles.BaseHeaderNavSos}>{ 'SOS' }</div>
                </a>
            </div>
        </div>
    );

    const LifeNav = () => (
        <div className={styles.BaseHeaderNavContainer}>
            <div className={styles.BaseHeaderNavContent}>
                <a
                    href="https://in-life.ru/klient"
                    className={styles.BaseHeaderNavItem}
                    data-active={true}
                    data-dropdown={true}>
                    <span>{ 'Частным клиентам' }</span>
                </a>
            </div>

            <div className={styles.BaseHeaderNavContent}>
                <a
                    href="https://in-life.ru/partner"
                    className={styles.BaseHeaderNavItem}>
                    <span>{ 'Партнерам' }</span>
                </a>
            </div>

            <div className={styles.BaseHeaderNavContent}>
                <a
                    href="https://in-life.ru/kompaniya/novosti"
                    className={styles.BaseHeaderNavItem}>
                    <span>{'Пресс-центр'}</span>
                </a>

                <a
                    href="https://in-life.ru/kompaniya/raskrytie-informatsii"
                    className={styles.BaseHeaderNavItem}>
                    <span>{'О компании'}</span>
                </a>

                <a
                    href='https://pyrus.sovcombank.ru/form/387411'
                    target='_blank'
                    rel="noopener noreferrer"
                    className={styles.BaseHeaderNavItem}
                    data-name="feedback">
                    Связаться с нами
                </a>

                <div className={styles.BaseHeaderNavLocation}>{'Москва'}</div>

                <a
                    href={`tel:${phoneByCompanyCode()}`}
                    className={styles.BaseHeaderNavPhone}>{phoneByCompanyCode()}</a>

                <a
                    href="https://in-life.ru/strahovoy-sluchay"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className={styles.BaseHeaderNavSos}>{'SOS'}</div>
                </a>
            </div>
        </div>
    );

    return (
        <div className={styles.BaseHeader}>
            <nav className={styles.BaseHeaderNav}>
                <Container fluid>
                    {companyCode === 'Life'
                        ? <LifeNav />
                        : <Nav />
                    }
                </Container>
            </nav>

            <nav className={styles.BaseHeaderNavSecond}>
                <Container fluid>
                    <div className={styles.BaseHeaderNavSecondContainer}>
                        <a href={companyCode === 'Life' ? 'https://in-life.ru' : 'https://in-lifeins.ru'}
                            className={cn(styles.BaseHeaderNavSecondLogo, logoByCompanyCode())}> </a>
                        <div className={styles.BaseHeaderNavSecondContent}>
                            <div className={styles.BaseHeaderNavSecondLoginItem} data-active={true}>
                                <Link to="/login">
                                    { 'Войти' }
                                </Link>
                            </div>

                            <div className={styles.BaseHeaderNavSecondSearch} />

                            <div className={styles.BaseHeaderNavSecondMenuToggle}>
                                <MenuBurger />
                            </div>
                        </div>
                    </div>
                </Container>
            </nav>
        </div>
    );
};

export default BaseHeader;
