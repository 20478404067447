import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

class LiaPage extends Component {
    render() {
        return (
            <div className={styles.LiaPageLayout}>
                <div className={styles.LiaPageHeader}/>
                <div className={styles.LiaPageBody}>
                    <div className={styles.LiaPageErrorIcon}/>
                    <div className={styles.LiaPageMainBlock}>
                        <div
                            className={cn(styles.LiaPageMainBlock_Block1, styles.LiaPageMainBlock_Text, styles.LiaPageMainBlock_Text__Title)}>
                            Уважаемый клиент, данная страница оплаты
                            страховых договоров больше не активна.
                        </div>
                        <div className={cn(styles.LiaPageMainBlock_Block2, styles.LiaPageMainBlock_Text)}>
                            Мы развиваем наши цифровые сервисы обслуживания. Теперь оплатить очередной
                            страховой взнос по договору вы можете в своём Личном кабинете.
                        </div>
                        <div className={styles.LiaPageMainBlock_Block3}>
                            <Link className={styles.LiaPageMainBlock_Block3_Link} to="/login">Личный кабинет</Link>
                        </div>
                        <div className={cn(styles.LiaPageMainBlock_Block4, styles.LiaPageMainBlock_Text)}>
                            Вход в Личный кабинет осуществляется по номеру мобильного телефона, указанного при
                            заключении вашего договора страхования.
                        </div>
                    </div>
                    <div className={styles.LiaPageTeamRespect}>
                        С уважением, Команда Инлайф страхование жизни
                    </div>
                    <div className={styles.LiaPageCompanyInfo}>
                        <div className={styles.LiaPageCompanyInfo_Row}>
                            © АО «Инлайф страхование жизни». Лицензии на осуществление страхования CЖ №3987
                        </div>
                        <div className={styles.LiaPageCompanyInfo_Row}>
                            и СЛ №3987 от 04.08.2023 г. выданы Банком России без ограничения срока действия.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default inject('store')(observer(LiaPage));
