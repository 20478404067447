import React from 'react';
import styles from './styles.module.scss';
import Button from '../Button';

const ClaimDocumentCard = ({ canWriteMessage, canAddFile, claimId, fileTypes, onAddFormNewMessage,
}) => (
    <div className={styles.ClaimDocumentsCardWrapper}>
        <div className={styles.ClaimDocumentsCard}>
            {fileTypes.length > 0 && (
                <div className={styles.CardItem}>
                    <div className={styles.CardHeader}>
                        <div className={styles.HeaderTitle}>
                            Список документов*
                        </div>
                        <div className={styles.HeaderSubtitle}>
                            {'*Полный список документов, которые могут потребоваться для рассмотрения страхового случая. В случае, если какие документы из списка Вы не приложили, не переживайте, при необходимости предоставления дополнительных документов запрос будет размещен на данной странице.' }
                        </div>
                    </div>
                </div>
            )}
            {fileTypes.map((item, itemIndex) => (
                <div className={styles.CardItem}
                    key={itemIndex}>
                    <div className={styles.ItemDocument}>
                        <div className={styles.DocumentName}>
                            {item.name}
                        </div>
                    </div>
                </div>
            ))}
            <div className={styles.CardItem}>
                <div className={styles.CardFooter}>
                    {canAddFile && (
                        <div className={styles.ButtonLoadDocuments}>
                            <Button
                                size="lg"
                                variant="danger"
                                style={{ width: '100%', borderRadius: '10px' }}
                                onClick={() => window.open(`/claims/${claimId}/documents`, '_self')}
                            >Загрузить документы</Button>
                        </div>
                    )}
                    {canWriteMessage && (
                        <div className={styles.ButtonNewMessage}>
                            <Button
                                size="lg"
                                buttonType="text"
                                variant="primary"
                                style={{ width: '100%', height: '64px' }}
                                onClick={onAddFormNewMessage}
                            >Задать вопрос</Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

export default ClaimDocumentCard;
