import React from 'react';
import { inject, observer } from 'mobx-react';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import InputField from 'components/InputField';
import MaskedInputField from 'components/MaskedInputField';
import DateInputField from 'components/DateInputField';
import Button from 'components/Button';
import SuccessLoginModal from 'components/SuccessRegisterModal';
import FailRegisterModal from 'components/FailRegisterModal';
import styles from './styles.module.scss';

class RegisterConfirmationPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            title: 'Регистрация',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = this.state.title;
    }

    handleSubmit(e) {
        e.preventDefault();

        this.store.register.registrationClientNotUnique();
    }

    render() {
        return (
            <React.Fragment>
                <SuccessLoginModal
                    isShow={this.store.register.isShowSuccessModal} />

                <FailRegisterModal
                    isShow={this.store.register.isShowFailModal}
                    handleClose={() => this.store.register.setIsShowFailModal(false)} />

                <Wrapper topIndent>
                    <Container fluid>
                        <Heading description={ `Для первого входа в личный кабинет по номеру ${this.store.login.form.fields.phone.value} \n необходимо подтвердить вашу личность.` }>{ 'Добро пожаловать!' }</Heading>

                        <form
                            className={styles.RegisterForm}
                            onSubmit={this.handleSubmit}>

                            <MaskedInputField
                                mask={['+', /[7-7]/, ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                label={'Телефон'}
                                size="lg"
                                type="tel"
                                placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                name="phone"
                                value={this.store.login.form.fields.phone.value}
                                disabled>
                                <Button
                                    to="/login"
                                    buttonType="text"
                                    variant="primary"
                                    size="sm">{ 'Ввести другой номер телефона' }</Button>
                            </MaskedInputField>

                            <InputField
                                label="Фамилия"
                                size="lg"
                                type="text"
                                placeholder="Фамилия"
                                name="surname"
                                value={this.store.register.form.fields.surname.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <InputField
                                label="Имя"
                                size="lg"
                                type="text"
                                placeholder="Имя"
                                name="name"
                                value={this.store.register.form.fields.name.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <InputField
                                label="Отчество"
                                size="lg"
                                type="text"
                                placeholder="Отчество"
                                name="patronymic"
                                value={this.store.register.form.fields.patronymic.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <DateInputField
                                mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                label="Дата рождения"
                                size="lg"
                                icon="calendar"
                                value={this.store.register.form.fields.birthDate.value}
                                format="dd.MM.yyyy"
                                placeholder="дд.мм.гггг"
                                onChange={(value) => this.store.register.onFieldChange('birthDate', value)} />

                            <div>
                                <Button
                                    variant="primary"
                                    size="lg"
                                    type="submit"
                                    disabled={this.store.register.form.meta.notUniqueSubmitDisabled()}
                                    loading={this.store.register.form.meta.loading}>{ 'Отправить' }</Button>
                            </div>
                        </form>
                    </Container>
                </Wrapper>
            </React.Fragment>
        );
    }
}

export default inject('store')(observer(RegisterConfirmationPage));
