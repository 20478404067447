import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import cookies from 'js-cookie';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import HeadingWrapper from 'components/HeadingWrapper';
import SafetyStatus from 'components/SafetyStatus';
import Switch from 'components/Switch';
import PolicyCardList from 'components/PolicyCardList';
import TheIsDisplayContactsPanel from 'components/TheIsDisplayContactsPanel';

import dayProfileBackground from 'assets/images/day_profile_background.jpg';
import evenProfileBackground from 'assets/images/even_profile_background.jpg';
import nightProfileBackground from 'assets/images/night_profile_background.jpg';
import morningProfileBackground from 'assets/images/morning_profile_background.jpg';

import styles from './styles.module.scss';
import Button from '../../components/Button';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        let phone = '';
        if (this.store.profile.user.phone) { phone = this.store.profile.user.phone; }

        let clientId = '';
        if (this.store.profile.user.clientId) { clientId = this.store.profile.user.clientId; }

        let isVidget = false;
        if (this.store.profile.user.investmentQuestionnaire) { isVidget = this.store.profile.user.investmentQuestionnaire.isNeeded && !this.store.profile.user.investmentQuestionnaire.isSent; }

        this.state = {
            title: 'Личный кабинет',
            dayTitle: '',
            dayBackground: null,
            isShowArchivedPolicies: JSON.parse(cookies.get('isShowArchivedPolicies') || false),
            fetchPoliciesLikeCompany: JSON.parse(cookies.get('fetchPoliciesLikeCompany') || true),
            isShowTheIsDisplayContractsPanel: JSON.parse(cookies.get('isShowTheIsDisplayContractsPanel') || true),
            country: '',
            startDate: '',
            endDate: '',
            phone,
            clientId,
            isVidget,
            udsPoints: String(this.store.profile.user.udsPoints).replace('.', ','),
        };

        this.handleAcceptShowAllPolicies = this.handleAcceptShowAllPolicies.bind(this);
        this.handleCancelShowAllPolicies = this.handleCancelShowAllPolicies.bind(this);
        this.handleToggleShowArchivedPolicies = this.handleToggleShowArchivedPolicies.bind(this);
        this.handleCalculateSubmit = this.handleCalculateSubmit.bind(this);
    }

    handleCalculateSubmit(e) {
        e.preventDefault();

        window.open(`https://www.uralsibins.ru/puteshestvenniki/kalkulyator/?country=${this.state.country}&beginDate=${moment(this.state.startDate).format('DD.MM.YYYY')}&endDate=${moment(this.state.endDate).format('DD.MM.YYYY')}`);
    }

    handleAcceptShowAllPolicies() {
        this.setState({
            ...this.state,
            fetchPoliciesLikeCompany: false,
            isShowTheIsDisplayContractsPanel: false,
        });

        cookies.set('fetchPoliciesLikeCompany', false);
        cookies.set('isShowTheIsDisplayContractsPanel', false);
    }

    handleCancelShowAllPolicies() {
        this.setState({ ...this.state, isShowTheIsDisplayContractsPanel: false });

        cookies.set('isShowTheIsDisplayContractsPanel', false);
    }

    handleToggleShowArchivedPolicies(e) {
        const { checked } = e.target;

        this.setState({ ...this.state, isShowArchivedPolicies: checked });

        cookies.set('isShowArchivedPolicies', checked);
    }

    getTimeCategory(time) {
        const timeFormat = 'HH:mm:ss';

        if (
            time.isBetween(moment('00:00:00', timeFormat), moment('04:59:59', timeFormat))
            || time.isSame(moment('00:00:00', timeFormat))
            || time.isSame(moment('04:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Доброй ночи',
                dayBackground: nightProfileBackground,
            });
        } else if (
            time.isBetween(moment('05:00:00', timeFormat), moment('11:59:59', timeFormat))
            || time.isSame(moment('05:00:00', timeFormat))
            || time.isSame(moment('11:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Доброе утро',
                dayBackground: morningProfileBackground,
            });
        } else if (
            time.isBetween(moment('12:00:00', timeFormat), moment('17:59:59', timeFormat))
            || time.isSame(moment('12:00:00', timeFormat))
            || time.isSame(moment('17:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Добрый день',
                dayBackground: dayProfileBackground,
            });
        } else if (
            time.isBetween(moment('18:00:00', timeFormat), moment('23:59:59', timeFormat))
            || time.isSame(moment('18:00:00', timeFormat))
            || time.isSame(moment('23:59:59', timeFormat))
        ) {
            this.setState({
                ...this.state,
                dayTitle: 'Добрый вечер',
                dayBackground: evenProfileBackground,
            });
        }
    }

    protectionPercent() {
        let result = 0;

        if (this.store.profile.user.protectionPercent) {
            if (!this.state.fetchPoliciesLikeCompany) {
                result = this.store.profile.user.protectionPercent.all;
            } else if (this.store.app.companyCode === 'Life') {
                result = this.store.profile.user.protectionPercent.life;
            } else {
                result = this.store.profile.user.protectionPercent.sk;
            }
        }

        return result;
    }

    componentDidMount() {
        document.title = this.state.title;
        const format = 'hh:mm:ss';
        const currentTime = moment(new Date(), format);
        this.getTimeCategory(currentTime);
        this.store.policy.getPolicies(this.state.fetchPoliciesLikeCompany);
    }

    componentWillUnmount() {
        this.store.policy.clearPolicies();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fetchPoliciesLikeCompany !== this.state.fetchPoliciesLikeCompany) {
            this.store.policy.getPolicies(this.state.fetchPoliciesLikeCompany);
        }
    }

    render() {
        let phpSess = '';
        if (document.cookie.match(/RS_KEY=[^;]+/) && document.cookie.match(/RS_KEY=[^;]+/)[0].split('=')[1]) { phpSess = document.cookie.match(/RS_KEY=[^;]+/)[0].split('=')[1]; }

        let authFrame = <iframe src={`https://lk.in-lifeins.ru/login?${phpSess}`} className={styles.DisplayNone} title="1" />;
        if (document.location.host === 'lk.in-lifeins.ru') { authFrame = <iframe src={`https://lk.in-life.ru/login?${phpSess}`} className={styles.DisplayNone} title="1" />; }
        if (document.location.host === 'lk-test1.in-life.ru') { authFrame = <iframe src={`https://lk-test2.in-life.ru/login?${phpSess}`} className={styles.DisplayNone} title="1" />; }
        if (document.location.host === 'lk-test2.in-life.ru') { authFrame = <iframe src={`https://lk-test1.in-life.ru/login?${phpSess}`} className={styles.DisplayNone} title="1" />; }

        return (
            <AppLayout
                userName={this.store.profile.userNameNavbar}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <TopWrapper background={this.state.dayBackground}>
                        <Container fluid>
                            <HeadingWrapper>
                                <div className={styles.ProfileHeading}>
                                    <Heading
                                        level="2"
                                        color="#FFFFFF">
                                        { `${this.state.dayTitle}, ${this.store.profile.user.name}!` }
                                    </Heading>
                                </div>
                                <div className={styles.ProfileFirstLineButtons}>
                                    <div></div>
                                    <SafetyStatus percentage={this.protectionPercent()} />
                                </div>
                                <div className={styles.ProfileSwitch}>
                                    <Switch
                                        onText={'Архив полисов'}
                                        onTextAlter={'Показать архив полисов'}
                                        offText={'Действующие полисы'}
                                        offTextWidth={195}
                                        checked={this.state.isShowArchivedPolicies}
                                        onChange={this.handleToggleShowArchivedPolicies} />
                                </div>
                                <Button
                                    to={this.store.profile.user.isAdmin ? 'admin/claim' : '/claim'}
                                    type="button"
                                    variant="danger"
                                    size="lg"
                                    className={styles.ProfileClaimButton}
                                >
                                    {'Заявить о страховом случае'}
                                </Button>
                            </HeadingWrapper>

                            {(this.state.isShowTheIsDisplayContractsPanel && this.store.profile.existsAnotherCompanyPolicies) && (
                                <div className={styles.ProfileTheIsDisplayContactsPanelWrapper}>
                                    <TheIsDisplayContactsPanel
                                        companyCode={this.store.app.companyCode}
                                        onAccept={this.handleAcceptShowAllPolicies}
                                        onCancel={this.handleCancelShowAllPolicies}/>
                                </div>
                            )}

                            <div className={styles.ProfilePolicyCardListWrapper}>
                                <PolicyCardList items={this.state.isShowArchivedPolicies ? this.store.policy.archivalPolicies : this.store.policy.validPolicies} />
                            </div>
                        </Container>
                    </TopWrapper>

                    <ContentWrapper>
                        <div className={styles.ProfilePolicyInfoCardListWrapper}>
                            <Container fluid>
                            </Container>
                        </div>
                        {authFrame}
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(ProfilePage));
