import React, { Fragment } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';

const FailRegisterModal = ({
    isShow,
    handleClose,
    handleCreateUser,
}) => (
    isShow && <Modal
        title={'Уважаемый пользователь!'}
        body={<p>Нам не&nbsp;удалось найти вашу учетную запись. Это возможно из-за допущенной ошибки при вводе данных. Просьба проверить и&nbsp;повторить попытку.<br/><br/>
            Если данные введены корректно, нажмите &laquo;Создать кабинет&raquo;. Вам будет создан Личный кабинет с&nbsp;возможностью заявить о&nbsp;страховом случае.
        </p>}
        footerGridStyle
        footer={
            <Fragment>
                <Button
                    size="lg"
                    variant="primary"
                    onClick={handleClose}
                >
                    {'Повторить ввод'}
                </Button>
                <Button
                    size="lg"
                    buttonType="text"
                    variant="primary"
                    onClick={handleCreateUser}
                >
                    {'Создать кабинет'}
                </Button>
            </Fragment>
        }
        handleClose={handleClose}/>
);

export default FailRegisterModal;
