import React, { useEffect, useState } from 'react';
import InputField from 'components/InputField';
import styles from './styles.module.scss';

const AutocompleteInputField = ({
    items = [],
    value,
    mask,
    includeSearch = true,
    setValue,
    onChange,
    onBlur = null,
    children,
    Component = InputField,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);
    const [cursor, setCursor] = useState(0);
    const [suggestions, setSuggestions] = useState([]);

    const isActive = () => suggestions.length > 0 && focused;

    const handleSetValue = (index) => {
        if (suggestions[index]) {
            setValue(suggestions[index], items.indexOf(suggestions[index]));
        }
    };

    const handleBlur = () => {
        if (onBlur) (onBlur)();

        setFocused(false);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 38 && cursor > 0) {
            e.preventDefault();

            setCursor(cursor - 1);
        } else if (e.keyCode === 40 && cursor < suggestions.length - 1) {
            e.preventDefault();

            setCursor(cursor + 1);
        } else if (e.keyCode === 13 || e.keyCode === 9) {
            e.preventDefault();

            handleSetValue(cursor);
        }
    };

    useEffect(() => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        let result = [];

        if (includeSearch) {
            result = items.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 3);

            if (result.length === 1 && inputValue === result[0].toLowerCase()) {
                result = [];
            }
        } else {
            result = items.slice(0, 5);
        }

        setSuggestions(inputLength === 0 ? [] : result);
    }, [value, items, includeSearch]);

    useEffect(() => {
        setCursor(0);
    }, [suggestions]);

    return (
        <div
            className={styles.AutocompleteInputField}
            data-active={isActive()}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}>
            <Component
                mask={mask}
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                {...rest} />

            {isActive() && (
                <div className={styles.AutocompleteInputFieldDropdown}>
                    {suggestions.map((item, itemIndex) => (
                        <div
                            key={itemIndex}
                            className={styles.AutocompleteInputFieldDropdownItem}
                            data-active={cursor === itemIndex}
                            onMouseDown={() => handleSetValue(itemIndex)}>{ item }</div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AutocompleteInputField;
