import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

const api = axios.create({
    baseURL: '/api',
});

export function AUTH_ADMIN(login, pass) {
    return new Promise((res, rej) => {
        api.post('/support/login', qs.stringify({
            login, pass,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_USERS(userName, surname, patronymic, birthDate, phone, email, policyNumber) {
    return new Promise((res, rej) => {
        api.post('/support/getUsers', qs.stringify({
            userName, surname, patronymic, birthDate, phone, email, policyNumber,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function ACTIVATE_USER(userId, phone) {
    return new Promise((res, rej) => {
        api.post('/support/chooseUser', qs.stringify({
            userId, phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function SUPPORT_REGISTER_USER(phone, surname, name, patronymic, birthDate, email, documentNumber, documentSeries, policyNumber) {
    const formattedBirthDate = moment(birthDate).format('YYYY-MM-DD');

    return new Promise((res, rej) => {
        api.post('/support/registerUser', qs.stringify({
            phone, surname, name, patronymic, birthDate: formattedBirthDate, email, documentNumber, documentSeries, policyNumber,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_LOGOUT() {
    return new Promise((res, rej) => {
        api.post('/auth/logout').then(() => {
            res(true);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_SIGN_IN(phone) {
    return new Promise((res, rej) => {
        api.post('/auth/signIn', qs.stringify({
            phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_VALIDATE_SMS(phone, code) {
    return new Promise((res, rej) => {
        api.post('/auth/validateSMS', qs.stringify({
            phone, code,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_ACCEPT_CONDITIONS(code) {
    return new Promise((res, rej) => {
        api.post('/auth/acceptConditions', qs.stringify({
            code,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_REGISTRATION_FULL(surname, name, patronymic, birthDate, email, documentNumber, documentSeries, policyNumber) {
    const formattedBirthDate = moment(birthDate).format('YYYY-MM-DD');

    return new Promise((res, rej) => {
        api.post('/auth/registrationFull', qs.stringify({
            surname, name, patronymic, birthDate: formattedBirthDate, email, documentNumber, documentSeries, policyNumber,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_REGISTRATION_NOT_POLICY_HOLDER(surname, name, patronymic, birthDate, email, documentNumber, documentSeries, policyNumber) {
    const formattedBirthDate = moment(birthDate).format('YYYY-MM-DD');

    return new Promise((res, rej) => {
        api.post('/auth/registrationNotPolicyHolder', qs.stringify({
            surname, name, patronymic, birthDate: formattedBirthDate, email, documentNumber, documentSeries, policyNumber,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_REGISTRATION_CLIENT_NOT_UNIQUE(surname, name, patronymic, birthDate) {
    const formattedBirthDate = moment(birthDate).format('YYYY-MM-DD');

    return new Promise((res, rej) => {
        api.post('/auth/registrationClientNotUnique', qs.stringify({
            surname, name, patronymic, birthDate: formattedBirthDate,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_SEND_EMAIL(email) {
    return new Promise((res, rej) => {
        api.post('/auth/sendEmail', qs.stringify({ email })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function AUTH_VALIDATE_EMAIL(code) {
    return new Promise((res, rej) => {
        api.post('/auth/validateEmail', qs.stringify({
            code,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function FEEDBACK_REGISTRATION_FEEDBACK_FORM(requestTypeId, surname, name, patronymic, email, description, birthDate, documentNumber, documentSeries, policyNumber, companyCode, policyFile) {
    const formattedBirthDate = moment(birthDate).format('YYYY-MM-DD');

    const formData = new FormData();
    formData.append('requestTypeId', requestTypeId);
    formData.append('surname', surname);
    formData.append('name', name);
    formData.append('patronymic', patronymic);
    formData.append('email', email);
    formData.append('description', description);
    formData.append('birthDate', formattedBirthDate);
    formData.append('documentNumber', documentNumber);
    formData.append('documentSeries', documentSeries);
    formData.append('policyNumber', policyNumber);
    formData.append('companyCode', companyCode);
    formData.append('policyFile', policyFile);

    return new Promise((res, rej) => {
        api.post('/feedback/registrationFeedbackForm', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function FEEDBACK_REGISTRATION_FEEDBACK_FORM_SHORT(requestTypeId, email, description, companyCode) {
    return new Promise((res, rej) => {
        api.post('/feedback/registrationFeedbackFormShort', qs.stringify({
            requestTypeId, email, description, companyCode,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function INSURANCE_GET_REQUEST_TYPES() {
    return new Promise((res, rej) => {
        api.get('/insurance/getRequestTypes').then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function INSURANCE_GETCLIENTINFO() {
    return new Promise((res, rej) => {
        try {
            api.get('/insurance/getClientInfo').then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
        // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function INSURANCE_GETMANUALS() {
    return new Promise((res, rej) => {
        try {
            api.get('/statement/getManuals')
                .then((response) => {
                    res(response.data);
                })
                .catch((error) => {
                    rej(error.response);
                });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function INSURANCE_GETPOLICIES(companyCode) {
    return new Promise((res, rej) => {
        api.get('/insurance/getPolicies', {
            params: {
                companyCode,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function INSURANCE_GETPOLICY(policyId) {
    return new Promise((res, rej) => {
        api.get('/insurance/getPolicy', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DATA_CHARTS_GET_GRAPHICS_DATA(index, policyId) {
    return new Promise((res, rej) => {
        api.get('/dataCharts/getGraphicsData', {
            params: {
                index,
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function CREATE_POLICY_REPORT(policyId, email) {
    return new Promise((res, rej) => {
        api.post('/reportPDF/execute', qs.stringify({
            policyId, email,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_GET_RISK_SELECTOR_LIST(policyId) {
    return new Promise((res, rej) => {
        api.get('/statement/getRiskSelectorList', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_GET_SELECTIONS_NOT_POLICY_HOLDER(contractNumber, insuredFullName, insuredBirthDate) {
    return new Promise((res, rej) => {
        api.get('/statement/getSelectionsNotPolicyHolder', {
            params: {
                contractNumber,
                insuredFullName,
                insuredBirthDate,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_GET_CLAIMS_LIST(includeDrafts, includeSent) {
    return new Promise((res, rej) => {
        api.get('/statement/getClaimsList', {
            params: {
                includeDrafts,
                includeSent,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_GET_CLAIM_TABS(policyId, clientId, otherInsuredSurname, otherInsuredName, otherInsuredPatronymic, otherInsuredBirthday, selections) {
    return new Promise((res, rej) => {
        api.get('/statement/getClaimTabs', {
            params: {
                policyId,
                clientId,
                ...(otherInsuredSurname ? { otherInsuredSurname } : {}),
                ...(otherInsuredName ? { otherInsuredName } : {}),
                ...(otherInsuredPatronymic ? { otherInsuredPatronymic } : {}),
                ...(otherInsuredBirthday ? { otherInsuredBirthday } : {}),
                selections: JSON.stringify(selections),
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_GET_CLAIM_TABS_FOR_EDIT(claimId) {
    return new Promise((res, rej) => {
        api.get('/statement/getClaimTabsForEdit', {
            params: {
                claimId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_CREATE_STATEMENT(policyId, selections, formType, formFields) {
    return new Promise((res, rej) => {
        api.post('/statement/createStatement', qs.stringify({
            policyId,
            selections,
            formType,
            formFields,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_EDIT_STATEMENT(claimId, formType, formFields) {
    return new Promise((res, rej) => {
        api.post('/statement/editStatement', qs.stringify({
            claimId,
            formType,
            formFields,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_DELETE_DRAFT(claimId) {
    return new Promise((res, rej) => {
        api.post('/statement/deleteDraft', qs.stringify({
            claimId,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_SEND_SMS(phone) {
    return new Promise((res, rej) => {
        api.post('/statement/sendSMS', qs.stringify({
            phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_VALIDATE_SMS(claimId, code, phone) {
    return new Promise((res, rej) => {
        api.post('/statement/validateSMS', qs.stringify({
            claimId,
            code,
            phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_VALIDATE_ADMIN(claimId) {
    return new Promise((res, rej) => {
        api.post('/statement/validateAdmin', qs.stringify({
            claimId,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_ADD_STATEMENT_FILE(type, claimId, file) {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('claimId', claimId);
    formData.append('file', file);

    return new Promise((res, rej) => {
        api.post('/statement/addStatementFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_DELETE_STATEMENT_FILE(claimId, fileId) {
    return new Promise((res, rej) => {
        api.post('/statement/deleteStatementFile', qs.stringify({
            claimId,
            fileId,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_LOSS_CLAIM_DATA(claimId) {
    return new Promise((res, rej) => {
        api.get('/statement/lossClaimData', {
            params: {
                claimId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_NEW_MESSAGE(claimId, message, files) {
    const formData = new FormData();
    formData.append('claimId', claimId);
    if (message) {
        formData.append('message', message);
    }
    if (files && files.length > 0) {
        files.forEach((file) => {
            formData.append('fileTypes', file.fileType);
            formData.append('files', file.file);
        });
    }

    return new Promise((res, rej) => {
        api.post('/statement/newMessage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_ADD_EVENT_FILE(event, type, claimId, file) {
    const formData = new FormData();
    formData.append('event', event);
    formData.append('type', type);
    formData.append('claimId', claimId);
    formData.append('file', file);

    return new Promise((res, rej) => {
        api.post('/statement/addEventFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_DELETE_EVENT_FILE(lossClaimEventId, fileId) {
    return new Promise((res, rej) => {
        api.post('/statement/deleteEventFile', qs.stringify({
            lossClaimEventId,
            fileId,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function STATEMENT_ADD_EVENT(lossClaimId, lossClaimEventId, message = '') {
    return new Promise((res, rej) => {
        api.post('/statement/addEvents', qs.stringify({
            lossClaimId,
            lossClaimEventId,
            message,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function SUGGESTIONS_BANK(query = '') {
    return new Promise((res, rej) => {
        api.post('/suggestions/getBankSuggestions', qs.stringify({
            query })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function SUGGESTIONS_ADDRESS(query = '') {
    return new Promise((res, rej) => {
        api.post('/suggestions/getAddressSuggestions', qs.stringify({
            query })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DATA_CHANGE_GET_LIST(includeDrafts, includeSent) {
    return new Promise((res, rej) => {
        api.get('/datachange/getList', {
            params: {
                includeDrafts,
                includeSent,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DATA_CHANGE_GET_ITEM(dataChangeRequestId) {
    return new Promise((res, rej) => {
        api.get('/datachange/getItem', {
            params: {
                dataChangeRequestId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DATA_CHANGE_CREATE_INSUMER() {
    return new Promise((res, rej) => {
        api.post('/datachange/createInsumer')
            .then((response) => {
                res(response.data);
            })
            .catch((error) => {
                rej(error.response);
            });
    });
}

export function DATA_CHANGE_CREATE_POLICY(policyId, selections) {
    return new Promise((res, rej) => {
        api.post('/datachange/createPolicy', qs.stringify({
            policyId,
            selections: JSON.stringify(selections),
        }))
            .then((response) => {
                res(response.data);
            })
            .catch((error) => {
                rej(error.response);
            });
    });
}

export function DATA_CHANGE_CHANGE_REQUEST(requestId, fields) {
    return new Promise((res, rej) => {
        api.post('/datachange/changeRequest', qs.stringify({
            requestId,
            ...fields,
        }))
            .then((response) => {
                res(response.data);
            })
            .catch((error) => {
                rej(error.response);
            });
    });
}

export function DATA_CHANGE_SEND_CHANGE_REQUEST(requestId, code) {
    return new Promise((res, rej) => {
        api.post('/datachange/sendChangeRequest', qs.stringify({
            requestId,
            code,
        }))
            .then((response) => {
                res(response.data);
            })
            .catch((error) => {
                rej(error.response);
            });
    });
}

export function DATA_CHANGE_RISK_SELECTOR_LIST(policyId) {
    return new Promise((res, rej) => {
        api.get('/datachange/getRiskSelectorList', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DATA_CHANGE_ADD_REQUEST_FILE(type, requestId, file) {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('requestId', requestId);
    formData.append('file', file);

    return new Promise((res, rej) => {
        api.post('/datachange/addRequestFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DATA_CHANGE_DELETE_REQUEST_FILE(requestId, fileId) {
    return new Promise((res, rej) => {
        api.post('/datachange/deleteRequestFile', qs.stringify({
            requestId,
            fileId,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function MMT_GET_LOSS_CLAIM_ONLINE_DOCTOR(claimId, policyId) {
    return new Promise((res, rej) => {
        api.get('/mmt/getLossClaimOnlineDoctor', {
            params: {
                claimId,
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}
export function SEND_PAYMENT_POLICY(policyId) {
    return new Promise((res, rej) => {
        api.get('/insurance/sendPaymentPolicy', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function CLAIM_DMS_FORM(policyId, typeOfDoctorAppointment, medicalSpecialization, indicateComplaints, dateTimeClaimDMS, description) {
    const formData = new FormData();
    formData.append('policyId', policyId);
    formData.append('typeOfDoctorAppointment', typeOfDoctorAppointment);
    formData.append('medicalSpecialization', medicalSpecialization);
    formData.append('indicateComplaints', indicateComplaints);
    formData.append('dateTimeClaimDMS', dateTimeClaimDMS);
    formData.append('description', description);

    return new Promise((res, rej) => {
        api.post('/insurance/claimDMS', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function DMS_DATA_FORM(policyId) {
    const formData = new FormData();
    formData.append('policyId', policyId);

    return new Promise((res, rej) => {
        api.post('/insurance/getDataFormDMS', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function PAYMENT_LIFE_SEND_SMS(phone) {
    return new Promise((res, rej) => {
        api.post('/paymentLife/sendSMS', qs.stringify({
            phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function PAYMENT_LIFE_VALIDATE_SMS(code, actionType, phone) {
    return new Promise((res, rej) => {
        api.post('/paymentLife/validateSMS', qs.stringify({
            code,
            actionType,
            phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function PAYMENT_LIFE_ENABLE_RECURRENT_PAYMENT(policyId, validationToken) {
    return new Promise((res, rej) => {
        api.post('/paymentLife/enableRecurrentPayment', qs.stringify({
            policyId,
            validationToken,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function PAYMENT_LIFE_DISABLE_RECURRENT_PAYMENT(policyId, validationToken) {
    return new Promise((res, rej) => {
        api.post('/paymentLife/disableRecurrentPayment', qs.stringify({
            policyId,
            validationToken,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_PAYMENT_LINK(policyId, expectedPaymentDate, amountOfPayment) {
    return new Promise((res, rej) => {
        api.post('/paymentLife/getPaymentLink', qs.stringify({
            policyId,
            expectedPaymentDate,
            ...(amountOfPayment ? { amountOfPayment } : {}),
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_SOCIAL_TAX_DEDUCTION_SELECTIONS(policyId) {
    return new Promise((res, rej) => {
        api.get('/insurance/getSocialTaxDeductionSelections', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_SOCIAL_TAX_DEDUCTION_CERTIFICATE_LIST(policyId) {
    return new Promise((res, rej) => {
        api.get('/insurance/getSocialTaxDeductionCertificatesList', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}
