import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Container from 'components/Container';
import AdminLoginForm from 'components/AdminLogin';
import LoginWrapper from 'components/LoginWrapper';
import LoginCard from 'components/LoginCard';

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    }
  }

  render() {
    return (
      <LoginWrapper>
          <Container fluid>
              <LoginCard title={'Вход в личный кабинет'}>
                  <AdminLoginForm/>
              </LoginCard>
          </Container>
      </LoginWrapper>
    );
  }
}

export default withRouter(inject('store')(observer(AdminPage)));
