import React from 'react';
import Button from 'components/Button';
import MaskedInput from '../../MaskedInputField';
import styles from './styles.module.scss';

const ConfirmSMSModal = ({
    isShow,
    title,
    actionType,
    smsCode,
    onChangeSmsCode,
    handleValidateSmsCode,
    loadingValidate,
    handleResendSmsCode,
    loadingResend,
    handleClose,
}) => (
    isShow && (
        <div className={styles.ConfirmModalContainer}>
            <div
                className={styles.ConfirmModalOverlay}
                onClick={handleClose}></div>
            <div className={styles.ConfirmModal}>
                {handleClose !== undefined && (
                    <div
                        className={styles.ConfirmModalClose}
                        onClick={handleClose}
                    />
                )}

                <div className={styles.ConfirmModalHead}>
                    <div className={styles.ConfirmModalTitle}>
                        { title }
                        <div className={styles.ConfirmModalSubTitle}>{ 'Введите SMS-код для подтверждения' }</div>
                    </div>
                </div>

                <div className={styles.ConfirmModalBody}>
                    <MaskedInput
                        autoFocus={true}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                        size="lg"
                        type="text"
                        placeholder="00000"
                        name="code"
                        value={smsCode}
                        onChange={onChangeSmsCode}
                    />
                </div>
                <div className={styles.ConfirmModalFoot}>
                    <Button
                        style={{ width: '100%' }}
                        size="lg"
                        variant="primary"
                        onClick={() => handleValidateSmsCode(actionType)}
                        loading={loadingValidate}>{ 'Подтвердить' }
                    </Button>
                    <Button
                        style={{ width: '100%', height: '64px' }}
                        buttonType="text"
                        size="lg"
                        variant="primary"
                        onClick={handleResendSmsCode}
                        loading={loadingResend}>{ 'Отправить повторно' }
                    </Button>
                </div>
            </div>
        </div>
    )
);

export default ConfirmSMSModal;
