import React, { useState } from 'react';
import LoginCard from 'components/LoginCard';
import MaskedInput from 'components/MaskedInputField';
import Button from 'components/Button';

const CheckSmsCodeCard = ({
    phone,
    code,
    codeError,
    resendLoading,
    submitLoading,
    onCodeChange,
    resendTime,
    onClose,
    onResend,
    onSubmit,
}) => {
    const [time, setTime] = useState(resendTime);

    const resendTimeout = () => {
        if (time !== 0) {
            setTimeout(() => {
                setTime(time - 1);
            }, 1000);
        }

        return time;
    };

    const handleResend = (e) => {
        onResend(e);

        setTime(resendTime);
    };

    return (
        <LoginCard
            title={'Введите код'}
            onClose={onClose}
        >
            <form onSubmit={onSubmit}>
                <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                    text={
                        <React.Fragment>
                                Вам на номер <b>{ phone }</b> выслано СМС с кодом подтверждения
                        </React.Fragment>
                    }
                    error={codeError}
                    size="lg"
                    type="text"
                    placeholder="00000"
                    name="code"
                    value={code}
                    onChange={onCodeChange} />

                <Button
                    style={{ marginTop: 10 }}
                    buttonType="text"
                    variant={resendTimeout() === 0 ? 'primary' : 'secondary'}
                    size="sm"
                    onClick={handleResend}
                    disabled={resendTimeout() !== 0 || resendLoading}
                    loading={resendLoading}
                >
                    { resendTimeout() === 0 ? 'Выслать еще раз' : `Выслать код повторно можно через ${resendTimeout()} сек` }
                </Button>

                <Button
                    style={{ width: '100%', marginTop: 30 }}
                    variant="primary"
                    size="lg"
                    type="submit"
                    disabled={!code || submitLoading}
                    loading={submitLoading}>{ 'Подтвердить' }</Button>
            </form>
        </LoginCard>
    );
};

export default CheckSmsCodeCard;
