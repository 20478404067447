import React, { forwardRef } from 'react';
import QuestionLabel from 'components/QuestionLabel';
import styles from './styles.module.scss';

const FileLoader = forwardRef(({
    label,
    description,
    title,
    required = false,
    files = [],
    ...rest
}, ref) => (
    <div
        className={styles.FileLoader}
    >
        <div className={styles.FileLoaderdLabel}>{ label }{description && <QuestionLabel text={description} />}</div>
        <div className={styles.FileLoaderdWrapper}>
            {files.length !== 0 && (
                <div className={styles.FileLoaderFiles}>
                    { files }
                </div>
            )}
            <div className={styles.FileLoaderTitle}>
                { title }
                <input
                    ref={ref}
                    type="file"
                    multiple
                    {...rest} />
            </div>
        </div>
    </div>
));

export default FileLoader;
