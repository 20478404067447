import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import FadeTransition from 'components/FadeTransition';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import DateInputField from 'components/DateInputField';
import TextareaField from 'components/TextareaField';
import Button from 'components/Button';
import { CLAIM_DMS_FORM, DMS_DATA_FORM } from 'api';

import moment from 'moment';
import styles from './styles.module.scss';
import SuccessFeedbackModal from '../../components/SuccessFeedbackModal';
import SelectField from '../../components/SelectField';

class ClaimDMSPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        const policyId = (new URL(document.location)).searchParams.get('policyId');

        this.state = {
            pageLoading: true,
            title: 'Получить медицинскую помощь',
            requestTypeList: [],
            description: '',
            policyId,
            loading: false,
            typeOfDoctorAppointment: -1,
            typeOfDoctorAppointmentList: [],
            indicateComplaints: '',
            medicalSpecialization: -1,
            medicalSpecializationList: [],
            minDate: new Date(),
            maxDate: new Date(),
            submitDisabled: () => (
                this.state.typeOfDoctorAppointment === -1
                || this.state.medicalSpecialization === -1
                || !this.state.indicateComplaints
                || !this.state.dateTimeClaimDMS
            ) || this.state.loading,
            isShowSuccessModal: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        DMS_DATA_FORM(this.state.policyId).then((data) => {
            this.setState({
                ...this.state,
                typeOfDoctorAppointmentList: data.result.typeOfDoctorAppointment,
                medicalSpecializationList: data.result.medicalSpecialization,
                pageLoading: false,
                minDate: data.result.dateMin,
                maxDate: data.result.dateMax,
            });
            document.title = this.state.title;
        });
    }

    handleCloseSuccessModal() {
        window.location = '/profile';
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            loading: true,
        });
        CLAIM_DMS_FORM(this.state.policyId,
            this.state.typeOfDoctorAppointment.toString(),
            this.state.medicalSpecialization.toString(),
            this.state.indicateComplaints,
            moment(this.state.dateTimeClaimDMS).format('DD.MM.YYYY HH:mm'),
            this.state.description)
            .then(() => {
                this.setState({
                    ...this.state,
                    isShowSuccessModal: true,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });

                alert(error.data.message);
            });
    }

    render() {
        return (
            <FadeTransition loading={this.state.pageLoading}>
                <SuccessFeedbackModal
                    isShow={this.state.isShowSuccessModal}
                    handleClose={this.handleCloseSuccessModal}
                    body="Ваша заявка принята. Дату и время визита мы сообщим Вам в смс в течение ближайшего часа. "
                    description = "
                          В вопросах здоровья самое главное – как можно скорее проконсультироваться с врачом. Мы понимаем это и
                          в первую очередь будем подбирать клинику с ближайшей свободной датой приема, удобной для Вас."
                />

                <AppLayout
                    userName={this.store.profile.userNameNavbar}
                    companyCode={this.store.app.companyCode}
                    hasBackground={false}
                >
                    <div className={styles.FeedbackPageWrapper}>
                        <Wrapper>
                            <TopWrapper>
                                <Container fluid>
                                    <Heading description={ 'Чтобы подать заявку на запись к врачу заполните, пожалуйста, короткую форму:' }>{ this.state.title }</Heading>

                                    <form
                                        className={styles.FeedbackPageForm}
                                        onSubmit={this.handleSubmit}>

                                        <div style={{ width: '100%', marginTop: 30 }}>
                                            <SelectField
                                                label="Выберите из списка какой прием Вам необходим"
                                                size="lg"
                                                name="typeOfDoctorAppointment"
                                                placeholder="Выберите вариант из списка"
                                                value={this.state.typeOfDoctorAppointment}
                                                options={this.state.typeOfDoctorAppointmentList}
                                                onChange={(e) => this.setState({ ...this.state, typeOfDoctorAppointment: e.target.value })}
                                            />
                                        </div>

                                        <div style={{ width: '100%', marginTop: 30 }}>
                                            <label>Выберите медицинскую специализацию врача <br />Если нужного специалиста нет в списке, позвоните на медицинский пульт 8-800-250-97-07</label>
                                            <SelectField
                                                label=""
                                                size="lg"
                                                name="MedicalSpecialization"
                                                placeholder="Выберите ваиант из списка"
                                                value={this.state.medicalSpecialization}
                                                options={this.state.medicalSpecializationList}
                                                onChange={(e) => this.setState({ ...this.state, medicalSpecialization: e.target.value })}
                                            />
                                        </div>

                                        <TextareaField
                                            label="Укажите, что Вас беспокоит. Перечислите жалобы, которые Вы отмечаете"
                                            placeholder="Описание происшедшего"
                                            rows="5"
                                            value={this.state.indicateComplaints}
                                            onChange={(e) => this.setState({ ...this.state, indicateComplaints: e.target.value })} />

                                        <DateInputField
                                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ',', ' ', /\d/, /\d/, ':', /\d/, /\d/]}
                                            label="Выберите дату и время посещения клиники"
                                            size="lg"
                                            name="dateTimeClaimDMS"
                                            icon="calendar"
                                            format={'dd.MM.yyyy, HH:mm'}
                                            showTimeInput='true'
                                            value={this.state.dateTimeClaimDMS}
                                            placeholder="дд.мм.гггг чч:мм"
                                            onChange={(value) => this.setState({ ...this.state, dateTimeClaimDMS: value })}
                                            minDate= {moment(new Date(this.state.minDate)).toDate()} // {moment(this.state.minDate).toDate()}
                                            maxDate={moment(new Date(this.state.maxDate)).toDate()}
                                            timeIntervals = {60}
                                        />
                                        <TextareaField
                                            label="Укажите иные пожелания к организацию помощи (например, территориальное расположение клиники)"
                                            placeholder="Дополнительная информация"
                                            rows="5"
                                            value={this.state.description}
                                            onChange={(e) => this.setState({ ...this.state, description: e.target.value })} />

                                        <div>
                                            <Button
                                                variant="primary"
                                                size="lg"
                                                type="submit"
                                                disabled={this.state.submitDisabled()}
                                                loading={this.state.loading}>{ 'Отправить' }</Button>
                                        </div>
                                    </form>
                                </Container>
                            </TopWrapper>
                        </Wrapper>
                    </div>
                </AppLayout>
            </FadeTransition>
        );
    }
}

export default inject('store')(observer(ClaimDMSPage));
