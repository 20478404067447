import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';
import Modal from 'components/Modal';

const SuccessRegisterModal = ({
    isShow,
    history,
}) => {
    const close = () => {
        history.push('/profile');
    };

    return (
        isShow && <Modal
            title={ 'Спасибо за регистрацию!' }
            body={<p>Добро пожаловать в личный кабинет. Из него вы сможете управлять своими полисами.</p>}
            footer={
                <Button
                    to="/profile"
                    style={{ width: '100%' }}
                    size="lg"
                    variant="primary">{ 'Продолжить' }</Button>
            }
            handleClose={close} />
    );
};

export default withRouter(SuccessRegisterModal);
