import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import TopWrapper from '../../components/TopWrapper';
import Wrapper from '../../components/Wrapper';
import Container from '../../components/Container';

class BonusPage extends PureComponent {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
        };
    }

    render() {
        return (
            <AppLayout
                userName={this.store.profile.userNameNavbar}
                companyCode={this.store.app.companyCode}
                hasBackground={false}
            >
                <div>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <h1> Подарки от партнеров </h1>
                                <h1>Как получить</h1>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(BonusPage));
