import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ExternalRedirect extends PureComponent {
    render() {
        return (
            <Route exact path={this.props.from} component={() => {
                let to = this.props.to || (window.location.pathname + window.location.search);
                if (this.props.host) {
                    to = this.props.host + _.trimStart(to, ['/']);
                }
                window.location.replace(to);
            }} />
        );
    }
}

ExternalRedirect.propTypes = {
    from: PropTypes.string,
    to: PropTypes.string,
    host: PropTypes.string,
};

export default ExternalRedirect;
