import React from 'react';
import Container from 'components/Container';
import styles from './styles.module.scss';

const SKFooter = () => (
    <footer className={styles.Footer}>
        <Container fluid>
            <address
                itemScope
                itemType="http://schema.org/Organization"
                className={styles.FooterAddress}
            >
                <a
                    className={styles.FooterAddressPhone}
                    href="tel:88002347755"
                >
                    8 800 234-77-55
                    <img
                        className={styles.FooterAddressPhoneIcon}
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABKCAMAAAAmEEIEAAAAVFBMVEUAAACjpaqjpaqjpaqipaqjpaqjpaqipamjpaqjpaqjpaqipamipamjpaqjpaqjpaqjpaqipamipamjpaqjpaqjpaqipamipamipamjpaqjpaqipalvOVEDAAAAG3RSTlMA7fYfD7/URBaeembMK7KQcDPdXuM8qlWIgk1D6E8PAAADGElEQVRYw9VY27KjIBAUEBWvwWuU///P3ThJuArjnqqtOv2UitgMMz0NmP1a5HKqByHE8Ky37idE1SZGZaF9lv9GtXEVAhG3+aqdqEuM061EPal+lRa8F2vPzRXPDZpr+kZVDAfT/3fNqp9I3Ar5ZzU1858u4sP3RHCV78G8vMpB/R7RshTX472KaMlqCoVd4lwD5LxOTMlWGHfEBgkICyH1BoLbUnGJPEOgGyG2eL5qrK7bk01e1BHNBcj5qd8qmFSiuZBsZ2w89OicR9zr4Pn1ziNQnrOO2T1I+kob80Imwb9RGu+DqrC8YMekD5JTOqt/xduakfaQjBQ6qIEfmDQLhVw1vLlYGaPO0qH3OKai1M1a49BLBXhgQzOVy50wagWgiJZnzqzMLaX47m3Igra2Wmju2QdMkcRkr7OHHNrsgB7RoraxEVi1UwB0QVuzqTsQmYmzg7Eu8jS7enulDH665SQJ1/Xfr4HZUfGJPcO2FDOEsPqhwxAMoNl1MQffkqDiaUCGJ0N1e0A9MAcCoyHI1laGjhcmjMEnKIJk1blQWiIbqtbEIT0dIA6ZJoNo/Jz5YiPdrZz1V3vcimQjRnKHqx7MWxRbDjrTHjGHPbnAsC0gb+OQEW5CNgPbOe/UkiLkb43ZmwzCjLGpoWwgSu53mLCsdoxonY3KBmlC3TRY1EXkHOagr3zT2Ow+7C6LtbpsZLPOEbYj5DR+rt+Vi86RLHfUSWKXg9khY8YzdxM7Urta1Vtc3OlymrvdNcZvLqAMb6Nf/ANngzhZNF+6yT2gMb9XScqkS3Eme3ENeXWnhT/TyH0H9VVVQPw3wcMbhUQeoXz7nPPwJq/GSNqujL282mNUm+O5Fnp9I2bkHttyjufRmQqG49pg9OXcB9W2Ckg1/8wiGaXIQxnjmiv17aBYElcmojAZkW+XFrH4i7fjJmuVf8xmXcLPJ6BC3nMb8v3kIl2mQ1D09xsA06ZP1n2T7O9yKrY0A9eOVmdoyF7FQJ/VvQuzoFdUc42n0pnmFPFhD49y7wvN04pHl/0QrFvKUrIq+7/4A20gd/KBKbS8AAAAAElFTkSuQmCC"
                        alt="icon"
                    />
                </a>

                <br/>

                <a
                    className={styles.FooterAddressEmail}
                    href="mailto:in@in-life.ru"
                >
                    in@in-life.ru
                </a>

                {/* <div className={styles.FooterAddressLocation}>
                    125284, Россия, г. Москва,
                    <br/>
                    пр-кт Ленинградский, д. 35 стр. 1
                </div> */}
                {/* Скрытый блок с адресом - только для микроразметки */}
                {/* <div
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                    className={styles.FooterAddressLocationHidden}
                >
                    <span itemProp="postalCode">125284</span>
                    <span itemProp="addressLocality">Россия, г. Москва</span>
                    <span itemProp="streetAddress">пр-кт Ленинградский, д. 35 стр. 1</span>
                </div> */}

                {/* <div className={styles.FooterSocial}>
                     <a
                        href="https://fb.com/uralsibins"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.FooterSocialFB}
                     >
                     fb
                     </a>
                     <a
                        href="https://vk.com/uralsibins"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.FooterSocialVK}
                    >
                    vk
                    </a>
                </div> */}
            </address>

            <div className={styles.FooterCopyright}>
                <div className={styles.FooterCopyrightText}>
                    {'© ООО «Инлайф страхование». Лицензии на осуществление страхования '}
                    <a className={styles.FooterCopyrightText}
                        href="https://in-lifeins.ru/kompaniya/raskrytie-informatsii">
                        {'СИ № 0667, СЛ № 0667, ПС № 0667'}</a>
                    {' выданы Банком России без ограничения срока действия.'}
                </div>
            </div>
        </Container>
    </footer>
);

const LifeFooter = () => (
    <footer className={styles.Footer}>
        <Container fluid>
            <address
                itemScope
                itemType="http://schema.org/Organization"
                className={styles.FooterAddress}
            >
                <a
                    className={styles.FooterAddressEmail}
                    href="mailto:inslife@in-life.ru"
                >
                    inslife@in-life.ru
                </a>

                <a className={styles.FooterAddressSecondPhone} href="tel:88002349202">
                    8 800 234-92-02
                </a>

                {/* <div className={styles.FooterAddressLocation}>
                    125284, Россия, г. Москва,
                    <br/>
                    пр-кт Ленинградский, д. 35 стр. 1
                </div> */}
                {/* Скрытый блок с адресом - только для микроразметки */}
                {/* <div
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                    className={styles.FooterAddressLocationHidden}
                >
                    <span itemProp="postalCode">125284</span>
                    <span itemProp="addressLocality">Россия, г. Москва</span>
                    <span itemProp="streetAddress">пр-кт Ленинградский, д. 35 стр. 1</span>
                </div> */}

                {/* <div className={styles.FooterSocial}>
                     <a
                        href="https://fb.com/uralsibins"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.FooterSocialFB}
                     >
                     >
                     fb
                     </a>
                     <a
                        href="https://vk.com/uralsibins"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.FooterSocialVK}
                    >
                    vk
                    </a>
                </div> */}
            </address>

            <div className={styles.FooterCopyright}>
                <div className={styles.FooterCopyrightText}>
                    {'© АО «Инлайф страхование жизни». Лицензии на осуществление страхования '}
                    <a className={styles.FooterCopyrightText}
                        href="https://in-life.ru/kompaniya/raskrytie-informatsii">
                        {'CЖ №3987 и СЛ №3987'}</a>
                    {' выданы Банком России без ограничения срока действия.'}
                </div>
            </div>
        </Container>
    </footer>
);

const Footer = ({
    companyCode,
}) => (
    companyCode === 'Life'
        ? (<LifeFooter/>)
        : (<SKFooter/>)
);

export default Footer;
