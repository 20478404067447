import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../Button';
import Modal from '../Modal';
import InputField from '../InputField';
import DateInputField from '../DateInputField';
import styles from './styles.module.scss';

const AddPolicyModal = ({
    isShow,
    handleClose,
    form,
    addPolicy,
    loading,
    onFormChange,
}) => (
    isShow && <Modal
        title={ 'Добавление полиса' }
        body={<div>
            <div style={{ width: '100%', marginTop: 30 }}>
                <InputField
                    label="Фамилия застрахованного"
                    size="lg"
                    type="text"
                    placeholder="Фамилия застрахованного"
                    name="surname"
                    value={form.surname}
                    onChange={(e) => onFormChange('surname', e.target.value)}
                />
            </div>
            <div className={styles.InputBlock}>
                <InputField
                    label="Имя застрахованного"
                    size="lg"
                    type="text"
                    placeholder="Имя застрахованного"
                    name="name"
                    value={form.name}
                    onChange={(e) => onFormChange('name', e.target.value)}
                />
            </div>
            <div className={styles.InputBlock}>
                <InputField
                    label="Отчество застрахованного"
                    size="lg"
                    type="text"
                    placeholder="Отчество застрахованного"
                    name="patronymic"
                    value={form.patronymic}
                    onChange={(e) => onFormChange('patronymic', e.target.value)}
                />
            </div>
            <div className={styles.InputBlock}>
                <DateInputField
                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                    label="Дата рождения застрахованного"
                    size="lg"
                    icon="calendar"
                    minDate={new Date('1920-01-01')}
                    maxDate={new Date()}
                    format="dd.MM.yyyy"
                    placeholder="дд.мм.гггг"
                    value={form.birthDate}
                    onChange={(value) => onFormChange('birthDate', value)}
                />
            </div>
            <div className={styles.InputBlock}>
                <InputField
                    label="Серия и номер полиса"
                    size="lg"
                    type="text"
                    placeholder="Серия и номер полиса"
                    name="policyNumber"
                    value={form.policyNumber}
                    onChange={(e) => onFormChange('policyNumber', e.target.value)}
                />
            </div>
        </div>
        }
        footer={
            <Button
                onClick={() => addPolicy(form.surname, form.name, form.patronymic, form.birthDate, form.policyNumber)}
                loading={loading}
                disabled={!(form.surname && form.name && form.patronymic && form.birthDate && form.policyNumber)}
                style={{ width: '100%' }}
                size="lg"
                variant="primary">{ 'Добавить' }</Button>
        }
        handleClose={handleClose} />
);

export default withRouter(AddPolicyModal);
