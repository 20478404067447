import { observable, computed, action, decorate } from 'mobx';
import { INSURANCE_GETCLIENTINFO } from 'api';

class ProfileModule {
    constructor(props, appStore) {
        this.appStore = appStore;
    }

    user = {};

    existsAnotherCompanyPolicies = false;

    get userNameNavbar() {
        if (this.user.name && this.user.surname) {
            return `${this.user.name} ${this.user.surname.charAt(0)}.`;
        }

        return '';
    }

    getClientInfo() {
        return INSURANCE_GETCLIENTINFO()
            .then((data) => {
                const { result } = data;

                this.user = result;

                const { companies = [] } = result;
                const { companyCode } = this.appStore;

                switch (companyCode) {
                case 'Life':
                    this.existsAnotherCompanyPolicies = companies.some((company) => company.code === 'SK');
                    break;
                case 'SK':
                    this.existsAnotherCompanyPolicies = companies.some((company) => company.code === 'Life');
                    break;
                default:
                    break;
                }
            }).catch(() => {
                if (Object.keys(this.user).length !== 0) {
                    if (window.location.pathname.indexOf('/admin') !== -1 || this.user.isAdmin) {
                        window.location.replace('/admin');
                    } else {
                        window.location.replace('/login');
                    }
                    this.user = {};
                }
            });
    }
}

decorate(ProfileModule, {
    user: observable,
    existsAnotherCompanyPolicies: observable,
    userNameNavbar: computed,
    getClientInfo: action.bound,
});

export default ProfileModule;
