import React from 'react';
import styles from './styles.module.scss';
import QuestionLabel from '../QuestionLabel';

const Checkbox = ({
    label,
    style,
    required = false,
    description,
    children,
    ...rest
}) => (
    <div
        className={styles.CheckboxContainer}
        data-required={label && required}
    >
        {(label || description) && <label className={styles.CheckboxLabel}>
            { label }
            {description && <QuestionLabel text={description} />}
        </label>}

        <div
            style={{ ...style }}
            className={styles.Checkbox}>
            <label className={styles.CheckboxWrapper}>
                <input
                    type="checkbox"
                    className={styles.CheckboxInput}
                    {...rest} />
                <div className={styles.CheckboxToggler}>
                    <svg
                        className={styles.CheckboxIcon}
                        viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </svg>
                </div>
            </label>
            { children && <div className={styles.CheckboxText}>{ children }</div> }
        </div>
    </div>
);

export default Checkbox;
