import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import FadeTransition from 'components/FadeTransition';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import SelectField from 'components/SelectField';
import MaskedInputField from 'components/MaskedInputField';
import InputField from 'components/InputField';
import TextareaField from 'components/TextareaField';
import Button from 'components/Button';
import SuccessFeedbackModal from 'components/SuccessFeedbackModal';
import {
    INSURANCE_GET_REQUEST_TYPES,
    FEEDBACK_REGISTRATION_FEEDBACK_FORM_SHORT,
} from 'api';
import styles from './styles.module.scss';

class FeedbackPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            pageLoading: true,
            title: 'Обратная связь',
            requestTypeList: [],

            email: this.store.register.form.fields.email.value || '',
            description: '',

            loading: false,
            submitDisabled: () => {
                const isDisabled = (
                    !this.state.email
                    || !this.state.description
                )
                || this.state.loading;

                return isDisabled;
            },
            isShowSuccessModal: false,
        };

        this.onChangeRequestTypeId = this.onChangeRequestTypeId.bind(this);
        this.handleCloseSuccessModal = this.handleCloseSuccessModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCloseSuccessModal() {
        this.setState({
            ...this.state,
            email: '',
            description: '',
            isShowSuccessModal: false,
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            loading: true,
        });

        FEEDBACK_REGISTRATION_FEEDBACK_FORM_SHORT(this.store.feedback.requestTypeId, this.state.email, this.state.description, this.store.app.companyCode)
            .then(() => {
                this.setState({
                    ...this.state,
                    isShowSuccessModal: true,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });

                const { error: errorMessage } = error.data;
                alert(errorMessage);
            });
    }

    onChangeRequestTypeId(e) {
        const { value: requestTypeId } = e.target;

        this.store.feedback.setRequestTypeId(requestTypeId);

        if (parseInt(requestTypeId, 10) === 1) {
            this.props.history.push('/feedback/register');
        }
    }

    componentDidMount() {
        document.title = this.state.title;

        if (parseInt(this.store.feedback.requestTypeId, 10) === 1) {
            this.props.history.push('/feedback/register');
        }

        INSURANCE_GET_REQUEST_TYPES()
            .then((data) => {
                const { results } = data;
                const items = results.map((item) => ({ id: item.typeId, name: item.typeName }));

                this.setState({
                    ...this.state,
                    requestTypeList: items,
                    pageLoading: false,
                });
            });
    }

    render() {
        const { user } = this.store.profile;

        const getPhoneValue = () => {
            if (this.store.login.form.fields.phone.value) {
                return this.store.login.form.fields.phone.value;
            }

            if (user.phone) {
                return user.phone;
            }

            return '';
        };

        return (
            <FadeTransition loading={this.state.pageLoading}>
                <SuccessFeedbackModal
                    isShow={this.state.isShowSuccessModal}
                    handleClose={this.handleCloseSuccessModal} />

                <AppLayout
                    userName={this.store.profile.userNameNavbar}
                    companyCode={this.store.app.companyCode}
                    hasBackground={false}
                >
                    <div className={styles.FeedbackPageWrapper}>
                        <Wrapper>
                            <TopWrapper>
                                <Container fluid>
                                    <Heading description={ 'Заполните форму и наш менеджер свяжется с вами в течение одного рабочего дня' }>{ this.state.title }</Heading>

                                    <form
                                        className={styles.FeedbackPageForm}
                                        onSubmit={this.handleSubmit}>
                                        <SelectField
                                            label="Тип обращения"
                                            size="lg"
                                            options={this.state.requestTypeList}
                                            value={this.store.feedback.requestTypeId}
                                            onChange={this.onChangeRequestTypeId}
                                        />

                                        <MaskedInputField
                                            mask={['+', /[7-7]/, ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                            label={'Телефон'}
                                            size="lg"
                                            type="tel"
                                            placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                            name="phone"
                                            value={getPhoneValue()}
                                            disabled />

                                        <InputField
                                            label="E-mail"
                                            size="lg"
                                            type="text"
                                            placeholder="E-mail"
                                            name="email"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ ...this.state, email: e.target.value })} />

                                        <TextareaField
                                            label="Сообщение"
                                            placeholder="Сообщение"
                                            rows="5"
                                            value={this.state.description}
                                            onChange={(e) => this.setState({ ...this.state, description: e.target.value })} />

                                        <div>
                                            <Button
                                                variant="primary"
                                                size="lg"
                                                type="submit"
                                                disabled={this.state.submitDisabled()}
                                                loading={this.state.loading}>{ 'Отправить' }</Button>
                                        </div>
                                    </form>
                                </Container>
                            </TopWrapper>
                        </Wrapper>
                    </div>
                </AppLayout>
            </FadeTransition>
        );
    }
}

export default inject('store')(observer(FeedbackPage));
