import React from 'react';
import styles from './styles.module.scss';

const ModalOverlay = ({
    handleClose,
    children,
}) => (
    <div className={styles.ModalContainer}>
        <div
            className={styles.ModalOverlay}
            onClick={handleClose}
        />

        <div className={styles.ModalContent}>
            { children }
        </div>
    </div>
);

export default ModalOverlay;
