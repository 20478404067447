import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';
import Modal from 'components/Modal';

const ConfirmAgreementModal = ({
    isShow,
    handleClose,
    text,
}) => (
    isShow && <Modal
        title={ 'Подтверждение согласия' }
        body={<span dangerouslySetInnerHTML={{ __html: text }}/>}
        footer={
            <Button
                onClick={handleClose}
                style={{ width: '100%' }}
                size="lg"
                variant="primary">{ 'Продолжить' }</Button>
        }
        handleClose={handleClose} />
);

export default withRouter(ConfirmAgreementModal);
