import { observable, computed, action, decorate } from 'mobx';
import { INSURANCE_GETPOLICIES, INSURANCE_GETPOLICY } from 'api';
import moment from 'moment';

class PolicyModule {
    constructor(props, appStore) {
        this.appStore = appStore;
    }

    policy = {};

    policies = [];

    isRecurrentEnabled = false;

    isShowRecurrentPaymentModal = false;

    isShowConfirmEnableModal = false;

    isShowConfirmDisableModal = false;

    isShowErrorModal = false;

    isShowSuccessModal = false;

    message = {
        title: '',
        body: '',
    };

    smsCode = '';

    loading = {
        sendEnableSms: false,
        sendDisableSms: false,
        validateSms: false,
        resendSms: false,
    }

    isAddPolicyModal = false

    isFailAddPolicyModal = false

    isLoadingAddPolicy = false;

    errorMessage = '';

    setIsAddPolicyModal(value) {
        this.isAddPolicyModal = value;
    }

    setIsFailAddPolicyModal(value) {
        this.isFailAddPolicyModal = value;
    }

    setIsLoadingAddPolicy(value) {
        this.isLoadingAddPolicy = value;
    }

    setErrorMessage(value) {
        this.errorMessage = value;
    }

    get validPolicies() {
        return this.policies.filter((policy) => policy.status.code === 'PolicyStatus_Valid'
            || (policy.status.code === 'PolicyStatus_Invalid' && (moment().isBefore(policy.contractEndDate) || moment().isSame(policy.contractEndDate))));
    }

    get archivalPolicies() {
        return this.policies.filter((policy) => policy.status.code === 'PolicyStatus_Archival'
            || policy.status.code === 'PolicyStatus_Terminated'
            || policy.status.code === 'PolicyStatus_Completed'
            || policy.status.code === 'PolicyStatus_Annulated'
            || (policy.status.code === 'PolicyStatus_Invalid' && moment().isAfter(policy.contractEndDate)));
    }

    get validAndArchivedPolicies() {
        return this.policies.filter((policy) => policy.status.code === 'PolicyStatus_Valid'
            || policy.status.code === 'PolicyStatus_Archival');
    }

    getPolicies(likeCompany) {
        const { companyCode } = this.appStore;

        return INSURANCE_GETPOLICIES(likeCompany ? companyCode : null)
            .then((data) => {
                const { policies } = data;

                this.policies = policies;
            }).catch(() => {
            });
    }

    getPolicy(id) {
        return INSURANCE_GETPOLICY(id)
            .then((data) => {
                const { policy, companyCode } = data;
                policy.companyCode = companyCode;
                if (policy.tabs.find((tab) => tab.title === '������ ��������')) {
                    policy.tabs.find((tab) => tab.title === '������ ��������').items.find((item) => item.title === '������ ��������').years.forEach((year) => {
                        Object.keys(year).forEach((yearKey) => {
                            Object.keys(year[yearKey].rows).forEach((rowKey) => {
                                if (year[yearKey].rows[rowKey].status === '�� �������') {
                                    year[yearKey].rows[rowKey].paymentLink = '';
                                } else {
                                    year[yearKey].rows[rowKey].paymentLink = null;
                                }
                            });
                        });
                    });
                }
                this.policy = policy;
            }).catch(() => {
            });
    }

    updatePaymentLinkLife(paymentLink, yearIndex, rowIndex) {
        if (this.policy.tabs.find((tab) => tab.title === '������ ��������')) {
            this.policy.tabs.find((tab) => tab.title === '������ ��������').items.find((item) => item.title === '������ ��������').years[0][yearIndex].rows[rowIndex].paymentLink = paymentLink;
        }
    }

    clearPolicies() {
        this.policies = [];
    }

    clearPolicy() {
        this.policy = {};
    }

    setIsRecurrentEnabled(value) {
        this.isRecurrentEnabled = value;
    }

    setIsShowRecurrentPaymentModal(value) {
        this.isShowRecurrentPaymentModal = value;
    }

    setIsShowConfirmEnableModal(value) {
        this.isShowConfirmEnableModal = value;
    }

    setIsShowConfirmDisableModal(value) {
        this.isShowConfirmDisableModal = value;
    }

    setIsShowErrorModal(value) {
        this.isShowErrorModal = value;
    }

    setIsShowSuccessModal(value) {
        this.isShowSuccessModal = value;
    }

    setMessage(field, value) {
        this.message[field] = value;
    }

    setSmsCode(value) {
        this.smsCode = value;
    }

    setLoading(field, value) {
        this.loading[field] = value;
    }
}

decorate(PolicyModule, {
    policy: observable,
    policies: observable,
    isAddPolicyModal: observable,
    isFailAddPolicyModal: observable,
    isLoadingAddPolicy: observable,
    errorMessage: observable,
    setIsAddPolicyModal: action.bound,
    setIsFailAddPolicyModal: action.bound,
    setIsLoadingAddPolicy: action.bound,
    setErrorMessage: action.bound,
    validPolicies: computed,
    archivalPolicies: computed,
    validAndArchivedPolicies: computed,
    isRecurrentEnabled: observable,
    isShowRecurrentPaymentModal: observable,
    isShowConfirmEnableModal: observable,
    isShowConfirmDisableModal: observable,
    isShowErrorModal: observable,
    isShowSuccessModal: observable,
    message: observable,
    smsCode: observable,
    loading: observable,
    getPolicies: action.bound,
    getPolicy: action.bound,
    updatePaymentLinkLife: action.bound,
    clearPolicies: action.bound,
    clearPolicy: action.bound,
    setIsRecurrentEnabled: action.bound,
    setIsShowRecurrentPaymentModal: action.bound,
    setIsShowConfirmEnableModal: action.bound,
    setIsShowConfirmDisableModal: action.bound,
    setIsShowErrorModal: action.bound,
    setIsShowSuccessModal: action.bound,
    setMessage: action.bound,
    setSmsCode: action.bound,
    setLoading: action.bound,
});

export default PolicyModule;
