import React from 'react';
import DatePicker from 'react-datepicker';
import MaskedInputField from 'components/MaskedInputField';

const DateInputField = ({
    mask,
    format,
    showTimeInput = false,
    showYearPicker = false,
    showMonthDropdown = true,
    showYearDropdown = true,
    includeDates,
    startDate,
    endDate,
    selectsStart,
    selectsEnd,
    minDate,
    maxDate,
    timeIntervals,
    value,
    required,
    placeholder,
    disabled,
    onChange,
    children,
    ...rest
}) => (
    <div>
        <DatePicker
            dropdownMode="select"
            locale="ru"
            selected={value}
            dateFormat={format}
            placeholderText={placeholder}
            startDate={startDate}
            endDate={endDate}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            disabled={disabled}
            required={required}
            showTimeSelect={showTimeInput}
            showYearPicker={showYearPicker}
            timeIntervals={ timeIntervals || 5}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            includeDates={includeDates}
            customInput={
                <MaskedInputField
                    mask={mask}
                    {...rest}>
                    { children }
                </MaskedInputField>
            } />
    </div>
);

export default DateInputField;
