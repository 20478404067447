import React from 'react';
import InputMask from 'react-input-mask';
import { GET_USERS, ACTIVATE_USER } from 'api';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styles from './styles.module.scss';
import Button from '../Button';
import UserItem from '../UserItem';

class AdminUser extends React.Component {
  form = { ...this.initialForm };

  constructor(props) {
      super(props);
      this.store = props.store;
      this.state = {
          userName: '',
          userPatronymic: '',
          userBirthDate: '',
          userPhone: '',
          userSurname: '',
          userEmail: '',
          userPolicyNumber: '',
          table: [],
          userSearch: '',
      };

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChangeUserName = this.handleChangeUserName.bind(this);
      this.handleChangePatronymic = this.handleChangePatronymic.bind(this);
      this.handleChangeBirthDate = this.handleChangeBirthDate.bind(this);
      this.handleChangePhone = this.handleChangePhone.bind(this);
      this.handleChangeSurname = this.handleChangeSurname.bind(this);
      this.handleChangeEmail = this.handleChangeEmail.bind(this);
      this.handleChangePolicyNumber = this.handleChangePolicyNumber.bind(this);
  }

  handleChangeUserName(e) {
      this.setState({
          userName: e.target.value,
      });
  }

  handleChangePatronymic(e) {
      this.setState({
          userPatronymic: e.target.value,
      });
  }

  handleChangeBirthDate(e) {
      this.setState({
          userBirthDate: e.target.value,
      });
  }

  handleChangePhone(e) {
      this.setState({
          userPhone: e.target.value,
      });
  }

  handleChangeSurname(e) {
      this.setState({
          userSurname: e.target.value,
      });
  }

  handleChangeEmail(e) {
      this.setState({
          userEmail: e.target.value,
      });
  }

  handleChangePolicyNumber(e) {
      this.setState({
          userPolicyNumber: e.target.value,
      });
  }

  handleSubmit(e) {
      e.preventDefault();
      this.setState({
          error: '',
          userSearch: true,
      });
      GET_USERS(this.state.userName, this.state.userSurname, this.state.userPatronymic, this.state.userBirthDate, this.state.userPhone, this.state.userEmail, this.state.userPolicyNumber)
          .then((data) => {
              if (data.result.length <= 0) { this.setState({ error: 'По вашему запросу нет данных' }); }
              this.setState({ table: data.result });
              this.setState({
                  userSearch: false,
              });
          }).catch((error) => {
              const { message } = error.data;
              this.setState({ error: message || 'Ошибка получения списка пользователей' });
              this.setState({
                  userSearch: false,
              });
              this.store.profile.getClientInfo();
          });
  }

  useUser(userId, phone) {
      ACTIVATE_USER(userId, phone)
          .then(() => {
              window.open('/profile', '_blank');
          }).catch((error) => {
              const { message } = error.data;
              this.setState({ error: message || 'Ошибка использования пользователей' });
              this.store.profile.getClientInfo();
          });
  }

  render() {
      return (
          <div>
              <div className={styles.UpperBlock}>
                  <div className={styles.AddUserButton}>
                      <Button
                          variant="primary"
                          id="btn-submit"
                          size="lg"
                          to="/admin/register">
                          <span>{ 'Создать ЛК' }</span>
                      </Button>
                  </div>
              </div>
              <div className={styles.SearchTitle}> Поиск пользователей </div>
              <div className={styles.SearchWrapper}>
                  <div id="error" className={styles.Error}> {this.state.error}</div>
                  <form className={styles.SearchFilter} onSubmit={this.handleSubmit} id="formfilter">
                      <div className={styles.SearchFields}>
                          <div className={styles.SearchInput}>
                              <input id="formSurname" placeholder="Фамилия" type="text" name="userSurname"
                                  className={styles.InputType} onChange={this.handleChangeSurname}/><br/>
                          </div>
                          <div className={styles.SearchInput}>
                              <input id="formName" type="text" placeholder="Имя" name="userName"
                                  className={styles.InputType} onChange={this.handleChangeUserName}/><br/>
                          </div>
                          <div className={styles.SearchInput}>
                              <input id="formPatronymic" placeholder="Отчество" type="text" name="userPatronymic"
                                  className={styles.InputType} onChange={this.handleChangePatronymic}/><br/>
                          </div>
                          <div className={styles.SearchInput}>
                              <input id="formBirthDate" placeholder="Дата рождения"
                                  alt="Дата рождения" title="Дата рождения" type="text"
                                  onFocus={(e) => (e.target.type = 'date')}
                                  onBlur={(e) => !e.target.value && (e.target.type = 'text')}
                                  name="userBirthDate"
                                  className={styles.InputType} onChange={this.handleChangeBirthDate}/><br/>
                          </div>
                          <div className={styles.SearchInput}>
                              <InputMask className={styles.InputType} placeholder="Телефон" name="userPhone"
                                  onChange={this.handleChangePhone} mask='+7 999-999-99-99' maskChar=" "/>
                          </div>
                          <div className={styles.SearchInput}>
                              <input id="formEmail" placeholder="E-mail" type="email" name="userEmail"
                                  className={styles.InputType} onChange={this.handleChangeEmail}/><br/>
                          </div>
                          <div className={styles.SearchInput}>
                              <input id="formPolicyNumber" placeholder="Полис" type="text" name="userPolicyNumber"
                                  className={styles.InputType} onChange={this.handleChangePolicyNumber}/><br/>
                          </div>
                      </div>
                      <div className={styles.SearchButtonWrapper}>
                          <Button
                              variant="primary"
                              id="btn-submit"
                              size="lg"
                              type="submit"
                              className={styles.SearchButton}
                              disabled={this.state.userSearch}
                              loading={this.state.userSearch}>{ 'Найти' }</Button>
                      </div>
                  </form>
              </div>

              <div className={styles.Clear}></div>
              <div>
                  { this.state.table.length > 0
            && <div>
                <div className={styles.SearchTitle}> Результат поиска </div>
                <div className={styles.UsersSearchTable}>
                    <div className={styles.UsersSearchTableHeader}>
                        <div className={styles.UsersSearchTableHeaderSurname}>Фамилия</div>
                        <div className={styles.UsersSearchTableHeaderName}>Имя</div>
                        <div className={styles.UsersSearchTableHeaderPatronymic}>Отчество</div>
                        <div className={styles.UsersSearchTableHeaderBirthday}>ДР</div>
                        <div className={styles.UsersSearchTableHeaderPhone}>Телефон</div>
                        <div className={styles.UsersSearchTableHeaderPhone}>E-mail</div>
                        <div className={styles.UsersSearchTableHeaderPhone}>Полис</div>
                        <div className={styles.UsersSearchTableHeaderPhone}>Компания</div>
                        <div className={styles.UsersSearchTableHeaderButton}></div>
                    </div>
                    {this.state.table.map((item, index) => (
                        <div key={index} className={styles.UsersSearchTableRow}>
                            <UserItem
                                data={item}
                                useUser={this.useUser}/>
                        </div>
                    ))}
                </div>
            </div>
                  }
              </div>
          </div>
      );
  }
}

export default withRouter(inject('store')(observer(AdminUser)));
