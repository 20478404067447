import React from 'react';
import { inject, observer } from 'mobx-react';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import InputField from 'components/InputField';
import MaskedInputField from 'components/MaskedInputField';
import DateInputField from 'components/DateInputField';
import Button from 'components/Button';
import styles from './styles.module.scss';
import SuccessSupportRegister from '../../components/SuccessSupportRegister';
import FailSupportRegister from '../../components/FailSupportRegister';

class AdminRegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            title: 'Регистрация нового пользователя',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = this.state.title;
    }

    handleSubmit(e) {
        e.preventDefault();

        this.store.register.supportRegisterUser();
    }

    render() {
        return (
            <React.Fragment>
                <SuccessSupportRegister
                    isShow={this.store.register.isShowSuccessModal}
                />

                <FailSupportRegister
                    isShow={this.store.register.isShowFailModal}
                    message={this.store.register.failRegisterMessage}
                    handleClose={() => this.store.register.setIsShowFailModal(false)}/>

                <Wrapper topIndent>
                    <Container fluid>
                        <Heading>{ 'Регистрация нового пользователя' }</Heading>
                        <form
                            className={styles.RegisterForm}
                            onSubmit={this.handleSubmit}>
                            <MaskedInputField
                                mask={['+', /[7-7]/, ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                label={'Телефон'}
                                size="lg"
                                type="tel"
                                placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                name="phone"
                                value={this.store.register.form.fields.phone.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} >
                            </MaskedInputField>

                            <InputField
                                label="Фамилия"
                                size="lg"
                                type="text"
                                placeholder="Фамилия"
                                name="surname"
                                value={this.store.register.form.fields.surname.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <InputField
                                label="Имя"
                                size="lg"
                                type="text"
                                placeholder="Имя"
                                name="name"
                                value={this.store.register.form.fields.name.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <InputField
                                label="Отчество"
                                size="lg"
                                type="text"
                                placeholder="Отчество"
                                name="patronymic"
                                value={this.store.register.form.fields.patronymic.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <DateInputField
                                mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                label="Дата рождения"
                                size="lg"
                                icon="calendar"
                                value={this.store.register.form.fields.birthDate.value}
                                minDate={new Date('1920-01-01')}
                                maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                                format="dd.MM.yyyy"
                                placeholder="дд.мм.гггг"
                                onChange={(value) => this.store.register.onFieldChange('birthDate', value)} />

                            <MaskedInputField
                                mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                                label={'Паспорт'}
                                size="lg"
                                type="text"
                                placeholder="Серия"
                                name="documentSeries"
                                value={this.store.register.form.fields.documentSeries.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <MaskedInputField
                                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                size="lg"
                                type="text"
                                placeholder="Номер"
                                name="documentNumber"
                                value={this.store.register.form.fields.documentNumber.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <InputField
                                label="E-mail"
                                size="lg"
                                type="text"
                                placeholder="E-mail"
                                name="email"
                                value={this.store.register.form.fields.email.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <InputField
                                label="Серия и номер полиса (как в договоре)"
                                size="lg"
                                type="text"
                                placeholder="Серия и номер полиса (как в договоре)"
                                name="policyNumber"
                                value={this.store.register.form.fields.policyNumber.value}
                                onChange={(e) => this.store.register.onFieldChange(e.target.name, e.target.value)} />

                            <div>
                                <Button
                                    variant="primary"
                                    size="lg"
                                    type="submit"
                                    disabled={this.store.register.form.meta.fullSubmitDisabled()}
                                    loading={this.store.register.form.meta.loading}>{ 'Зарегистрировать' }</Button>
                            </div>
                        </form>
                    </Container>
                </Wrapper>
            </React.Fragment>
        );
    }
}

export default inject('store')(observer(AdminRegisterPage));
