import React, { useState } from 'react';
import cn from 'classnames';
import ToggleTabs, { ToggleTabsItem } from 'components/ToggleTabs';
import moment from 'moment/moment';
import style from './styles.module.scss';
import AccordionWrapper from '../AccordionWrapper';
import Button from '../Button';
import { GET_PAYMENT_LINK } from '../../api';

const PolicyScheduleTabs = ({ rows, className, policyId, isRecurrentEnabled, updatePaymentLink, yearIndex }) => {
    const [activeTab, setActiveTab] = useState('upcoming');
    const [links, setLinks] = useState(rows.map((el) => el.paymentLink));
    const [loadings, setLoading] = useState(Array(rows.length).fill(false));

    const getPaymentLink = (paymentDate, paymentAmount, rowIndex) => {
        GET_PAYMENT_LINK(policyId, moment(paymentDate, 'DD.MM.YYYY').format('YYYY-MM-DD'), paymentAmount).then(({ paymentLink }) => {
            setLinks(links.map((link, linkIndex) => {
                if (linkIndex === rowIndex) {
                    return paymentLink;
                }
                return link;
            }));
            setLoading(loadings.map((loading, loadingIndex) => {
                if (loadingIndex === rowIndex) {
                    return false;
                }
                return loading;
            }));
            updatePaymentLink(paymentLink, yearIndex, rowIndex);
            window.open(paymentLink, '_blank');
        }).catch(() => {
            setLoading(loadings.map((loading, loadingIndex) => {
                if (loadingIndex === rowIndex) {
                    return false;
                }
                return loading;
            }));
        });
    };

    const getPaymentAmount = (payAmount) => {
        if (isRecurrentEnabled) {
            return 0;
        }
        const payAmountNumber = payAmount.replace(/\s/g, '').replace('₽', '').replace('$', '');
        if (payAmount.includes(',')) {
            return payAmountNumber.replace(',', '.');
        }
        return `${payAmountNumber}.00`;
    };

    const loadLink = (paymentDate, payAmount, rowIndex) => {
        if (!links[rowIndex]) {
            setLoading(loadings.map((el, index) => {
                if (index === rowIndex) {
                    return true;
                }
                return el;
            }));
            const paymentAmount = getPaymentAmount(payAmount);
            getPaymentLink(paymentDate, paymentAmount, rowIndex);
        }
    };

    const getHeaderAccord = ({ canPayPeriod, status, expectedPaymentDate, expectedPaymentDateWithGracePeriod, totalAmount, payAmount, type }, rowIndex) => (
        <div className={style.HeaderAccordWrapper} data-highlighted={type === 'current_payment'}>
            <div className={style.HeaderAccordColumn}>
                <div className={style.HeaderAccordTitle}>{expectedPaymentDate}</div>
                <div className={style.HeaderAccordSubTitle}>{expectedPaymentDateWithGracePeriod}</div>
            </div>
            <div className={style.HeaderAccordColumn}>
                <div className={style.HeaderAccordSubTitle}>Общая сумма</div>
                <div className={style.HeaderAccordTitle}>{totalAmount}</div>
            </div>
            {type === 'past_payment' && (<div className={cn(style.HeaderAccord, style.HeaderAccordLeft)}>
                {status === 'оплачен' && <div className={style.HeaderAccordStatus}>Успешно оплачено</div> }
                {status === 'в обработке' && <div className={cn(style.HeaderAccordStatus, style.HeaderAccordStatusWait)}>В обработке</div> }
            </div>)}
            {(type === 'future_payment' || type === 'current_payment')
                && (<div className={cn(style.HeaderAccord, style.HeaderAccordLeft)}>
                    <div className={cn(style.HeaderAccordColumn)}>
                        <div className={style.HeaderAccordSubTitle}>Сумма к оплате</div>
                        <div className={style.HeaderAccordTitle}>{payAmount}</div>
                    </div>
                    {canPayPeriod
                        ? <Button
                            style={{ width: 150 }}
                            size="sm"
                            type="submit"
                            onClick={() => loadLink(expectedPaymentDate, payAmount, rowIndex)}
                            loading={loadings[rowIndex]}
                            href={links[rowIndex]}
                            variant="primary">
                            Оплатить
                        </Button>
                        : <Button
                            style={{ width: 150 }}
                            size="sm"
                            variant="disabled">
                            Оплатить
                        </Button>
                    }
                </div>)}
        </div>
    );
    const getPaymentData = (paymentDetails) => paymentDetails !== null
            && (<div className={style.BodyAccordWrapper}>
                <div className={style.BodyAccordHead}>
                    <div className={style.BodyAccordHeadData}>Дата поступления платежа</div>
                    <div className={style.BodyAccordHeadSum}>Оплачено</div>
                </div>
                <div className={style.BodyAccordItems}>
                    {paymentDetails.map((item, itemIndex) => (
                        <div key={itemIndex} className={style.BodyAccordItem}>
                            <div className={style.BodyAccordHeadData}>{item.paymentDate}</div>
                            <div>{item.paidSumInContractCurrency}</div>
                        </div>
                    ))}
                </div>
            </div>);

    const getCurrentPayment = () => {
        const rowIndex = rows.findIndex((row) => row.type === 'current_payment');
        return (
            rows.filter((row) => row.type === 'current_payment')
                .map((item, itemIndex) => (<div key={itemIndex} className={style.PolicySheduleCurrent}>
                    <p>
                        Ближайший платеж
                    </p>
                    <AccordionWrapper isPaymentInfo={item.paymentDetails !== null} active={true} toggle={getHeaderAccord(item, rowIndex)}>
                        <div>{getPaymentData(item.paymentDetails)}</div>
                    </AccordionWrapper></div>))
        );
    };

    return (
        <div>
            {rows.some((row) => row.type === 'current_payment')
                ? <>
                    {getCurrentPayment()}
                    <div className={style.ToggleTabsWrapper}>
                        <ToggleTabs>
                            <ToggleTabsItem
                                active={activeTab === 'history'}
                                onClick={() => setActiveTab('history')}>
                                <div className={activeTab !== 'history' ? style.NotActiveTab : ''}>{'История платежей'}</div>
                            </ToggleTabsItem>
                            <ToggleTabsItem
                                active={activeTab === 'upcoming'}
                                onClick={() => setActiveTab('upcoming')}>
                                <div
                                    className={activeTab !== 'upcoming' ? style.NotActiveTab : ''}>{'Предстоящие платежи'}</div>
                            </ToggleTabsItem>
                        </ToggleTabs>
                    </div>
                    <div className={style.PolicySheduleItems}>
                        {activeTab === 'history' && rows.map((row, rowIndex) => (
                            (
                                row.type === 'past_payment'
                            ) && (<AccordionWrapper key={rowIndex} isPaymentInfo={row.paymentDetails !== null} active={true} toggle={getHeaderAccord(row, rowIndex)}>
                                <div>{getPaymentData(row.paymentDetails)}</div>
                            </AccordionWrapper>)
                        ))}
                        {activeTab === 'upcoming' && rows.map((row, rowIndex) => (
                            (
                                row.type === 'future_payment'
                            ) && (<AccordionWrapper key={rowIndex} isPaymentInfo={row.paymentDetails !== null} active={true} toggle={getHeaderAccord(row, rowIndex)}/>)
                        ))}
                    </div>
                </>
                : <div className={style.PolicySheduleItems}>
                    {rows.map((row, rowIndex) => ((row.type === 'past_payment' || row.type === 'future_payment')
                            && (<AccordionWrapper key={rowIndex} isPaymentInfo={row.paymentDetails !== null} active={true} toggle={getHeaderAccord(row, rowIndex)}>
                                {row.paymentDetails && <div>{getPaymentData(row.paymentDetails)}</div>}
                            </AccordionWrapper>)
                    ))}
                </div>
            }
        </div>
    );
};
export default PolicyScheduleTabs;
