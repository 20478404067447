import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import QuestionLabel from '../QuestionLabel';

const TextareaField = forwardRef(({
    label,
    required = false,
    description,
    ...rest
}, ref) => (
    <div
        className={styles.TextareaField}
        data-required={required}
    >
        <label className={styles.TextareaFieldLabel}>
            { label }
            {description && <QuestionLabel text={description} />}
        </label>
        <div className={styles.TextareaFieldWrapper}>
            <textarea
                ref={ref}
                className={styles.TextareaFieldControl}
                {...rest} />
        </div>
    </div>
));

export default TextareaField;
