import React from 'react';
import styles from './styles.module.scss';

const MenuBurger = (props) => (
    <div
        className={styles.MenuBurger}
        {...props}>
        <div className={styles.MenuBurgerItem} />
        <div className={styles.MenuBurgerItem} />
    </div>
);

export default MenuBurger;
