import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const PolicyCard = ({ data }) => {
    const transformLink = (url) => {
        const indexOfUpload = url.indexOf('upload');
        return url.substring(indexOfUpload)
    }

    const cardBackgroundImageStyle = {
        backgroundImage: `url(/${transformLink(data.previewUrlRetina || data.previewUrl)})`,
    };

    const cardIconBackgroundImageStyle = {
        backgroundImage: `url(/${transformLink(data.iconUrlRetina || data.iconUrl)})`,
    };

    const policyDetailsUrl = `/profile/policy/${data.policyId}`;

    return (
        <Link to={policyDetailsUrl} className={styles.PolicyCard}>
            <div className={styles.PolicyCardWrapper}>
                <div
                    className={styles.PolicyCardBackground}
                    style={{ ...cardBackgroundImageStyle }} />

                <div className={styles.PolicyCardContent}>
                    <div
                        className={styles.PolicyCardIcon}
                        style={{ ...cardIconBackgroundImageStyle }} />

                    <div className={styles.PolicyCardNumber}>{ data.contractNumber }</div>

                    <div className={styles.PolicyCardTitle}>{ data.insuranceProgram }</div>

                    <div className={styles.PolicyCardBottomWrapper}>
                        <div className={styles.PolicyCardDate}>
                            срок действия<br />до <span>{ moment(data.contractEndDate).format('DD.MM.YYYY') }</span>
                        </div>

                        <span className={styles.PolicyCardButton} />
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default PolicyCard;
